import { get } from "@wayhome-uk/utils";

export type TPropertyTenureType = "leasehold" | "freehold" | "share-of-freehold" | "unknown" | null;
interface IListing {
    originalAddress: string | null;
    propertyTenureType: TPropertyTenureType;
}

export const getListing: (listingId: string, token: string) => Promise<IListing> = async (listingId, token) => {
    const { body, ok, status } = await get(
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/listings/${listingId}`,
        token,
    );

    if (!ok) {
        throw new Error(JSON.stringify({ error: "failed to get listing", status: status }));
    }

    return body as IListing;
};
