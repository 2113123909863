import React from "react";

import { SystemNotification, SystemNotificationProvider } from "@wayhome-uk/components";
import { GlobalStyles, Theme } from "@wayhome-uk/design-system";
import { AuthProvider } from "@wayhome-uk/utils";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "routes";
import { ThemeProvider } from "styled-components";

import { ErrorBoundary } from "components/error-boundary/error-boundary";

function App() {
    return (
        <ThemeProvider theme={Theme}>
            <GlobalStyles />
            <AuthProvider>
                <SystemNotificationProvider>
                    <ErrorBoundary>
                        <Router>
                            <Routes />
                        </Router>
                    </ErrorBoundary>
                    <SystemNotification />
                </SystemNotificationProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
