import React from "react";

import { InputDropdown, ShortLeadLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";

import { CurrencyField } from "components/form/currency-field";
import { ErrorMessage, FormButton, Label } from "components/form/form-atoms";
import { FormWrapper } from "components/form/form-wrapper";
import { TextAreaField } from "components/form/textarea-field";
import { IPaymentHistoryData } from "types";

import { paymentRequestValidationSchema } from "./payment-request-validation-schema";

export interface IPaymentRequestFormProps extends IBaseComponent {
    initialValues: IPaymentHistoryData;
    onSubmit?: (values: IPaymentHistoryData) => void;
}

export const PaymentRequestForm: React.FC<IPaymentRequestFormProps> = ({ className, initialValues, onSubmit }) => {
    const handleSubmitForm = (values: IPaymentHistoryData) => {
        if (onSubmit) {
            onSubmit(values);
        }
    };

    return (
        <>
            <Heading>Partnership One Off Payment</Heading>
            <VerticalSpacing size={32} />
            <FormWrapper>
                <Formik
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={handleSubmitForm}
                    validationSchema={paymentRequestValidationSchema}
                >
                    {({ errors, handleSubmit, setFieldValue, touched }) => (
                        <form
                            className={className}
                            data-testid="payment-request-form"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                        >
                            <CurrencyField id="amount" name="amount" label="Payment Amount" />
                            <VerticalSpacing size={16} />
                            <Label htmlFor="paymentReference">Payment Reference</Label>
                            <VerticalSpacing size={8} />
                            <InputDropdown
                                id="paymentReference"
                                name="paymentReference"
                                options={[
                                    { value: "", display: "---------", disabled: true, selected: true },
                                    { value: "purchase_costs", display: "Purchase Costs" },
                                    { value: "maintenance_costs", display: "Maintenance Costs" },
                                    { value: "other", display: "Other" },
                                ]}
                                onChange={(value: string) => value}
                                setField={setFieldValue}
                                hasError={errors.paymentReference}
                            />
                            {touched.paymentReference && errors.paymentReference && (
                                <ErrorMessage>
                                    <span>{errors.paymentReference}</span>
                                </ErrorMessage>
                            )}
                            <VerticalSpacing size={16} />
                            <TextAreaField
                                id="name"
                                name="name"
                                label="Payment Summary"
                                explanation="Max. 40 Characters, this appears on the customer's invoice."
                                rowNumber="2"
                            />
                            <VerticalSpacing size={16} />
                            <TextAreaField
                                id="description"
                                name="description"
                                label="Payment Customer Description"
                                explanation="Max. 150 Characters, this appears on the customer's payment summary page."
                                rowNumber="4"
                            />
                            <VerticalSpacing size={32} />
                            <FormButton data-testid="submit-btn" type="submit">
                                Send for Approval
                            </FormButton>
                        </form>
                    )}
                </Formik>
            </FormWrapper>
        </>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;
