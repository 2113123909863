import { IPartnership } from "types";

export const isPartnershipIsReadyForActivation = (partnership: IPartnership): boolean => {
    if (partnership.completionDate === null) {
        return false;
    }

    if (partnership.initialRentPcm === null) {
        return false;
    }

    if (partnership.rentDirectDebitMandateId === null) {
        return false;
    }

    return true;
};
