import { get, patch, penceToPounds, poundsToPence } from "@wayhome-uk/utils";

import { IPartialPurchaseSchedule, IPurchaseSchedule } from "types";

import { diffChanges } from "../shared-functions/diff-changes";

export interface IPurchaseScheduleFromApi {
    // read & write fields
    id: string;
    timestamp: string | null;
    property_purchase_price: number | null;
    customer_contribution: number | null;
    stamp_duty_land_tax: number | null;
    land_registration_fee: number | null;
    conveyancers_fee: number | null;
    pre_completion_searches_cost: number | null;
    property_searches_cost: number | null;
    title_insurance_cost: number | null;
    telegraphic_transfer_fee: number | null;
    property_survey_cost: number | null;
    eicr_inspection_fee: number | null;
    landlord_compliance_work_cost: number | null;
    gas_safety_inspection_fee: number | null;
    landlord_advisory_work_cost: number | null;
    landlord_licence_check_fee: number | null;
    completion_day_inspection_fee: number | null;
    llp_creation_cost: number | null;
    wayhome_customer_deposit_contribution: number | null;
    wayhome_stamp_duty_contribution: number | null;
    total_day_one_service_charge: number | null;
    total_day_one_ground_rent: number | null;

    // read only fields
    customer_property_contribution: number | null;
    customer_costs_contribution: number | null;
    customer_conveyancer_costs_contribution: number | null;
    customer_llp_costs_contribution: number | null;
    customer_completion_statement_subtotal: number | null;
    fund_contribution: number | null;
    fund_property_contribution: number | null;
    fund_costs_contribution: number | null;
    fund_conveyancer_costs_contribution: number | null;
    fund_llp_costs_contribution: number | null;
    fund_completion_statement_subtotal: number | null;
    nominee_contribution: number | null;
    nominee_property_contribution: number | null;
    nominee_costs_contribution: number | null;
    nominee_conveyancer_costs_contribution: number | null;
    nominee_llp_costs_contribution: number | null;
    nominee_completion_statement_subtotal: number | null;
    total_fees: number | null;
    customer_ownership_percentage: string | null;
    fund_ownership_percentage: string | null;
    nominee_ownership_percentage: string | null;
    fund_income_percentage: string | null;
    nominee_income_percentage: string | null;
    total_formation_costs: number | null;
    llp_purchase_costs_subtotal: number | null;
    customer_llp_purchase_costs_subtotal: number | null;
    fund_llp_purchase_costs_subtotal: number | null;
    nominee_llp_purchase_costs_subtotal: number | null;
    completion_statement_subtotal: number | null;
    customer_financial_incentive: number | null;
    fund_financial_incentive: number | null;
    nominee_financial_incentive: number | null;
    total_financial_incentive: number | null;
    customer_day_one_service_charge: number | null;
    fund_day_one_service_charge: number | null;
    nominee_day_one_service_charge: number | null;
    customer_day_one_ground_rent: number | null;
    fund_day_one_ground_rent: number | null;
    nominee_day_one_ground_rent: number | null;
    customer_completion_statement_subtotal_including_day_one_charges: number | null;
    fund_completion_statement_subtotal_including_day_one_charges: number | null;
    nominee_completion_statement_subtotal_including_day_one_charges: number | null;
    completion_statement_subtotal_including_day_one_charges: number | null;
}

export interface IPurchaseScheduleForApi {
    // read & write fields
    id?: string | null;
    timestamp?: string | null;
    property_purchase_price?: number | null;
    customer_contribution?: number | null;
    stamp_duty_land_tax?: number | null;
    land_registration_fee?: number | null;
    conveyancers_fee?: number | null;
    pre_completion_searches_cost?: number | null;
    property_searches_cost?: number | null;
    title_insurance_cost?: number | null;
    telegraphic_transfer_fee?: number | null;
    property_survey_cost?: number | null;
    eicr_inspection_fee?: number | null;
    landlord_compliance_work_cost?: number | null;
    gas_safety_inspection_fee?: number | null;
    landlord_advisory_work_cost?: number | null;
    landlord_licence_check_fee?: number | null;
    completion_day_inspection_fee?: number | null;
    llp_creation_cost?: number | null;
    wayhome_customer_deposit_contribution?: number | null;
    wayhome_stamp_duty_contribution?: number | null;
    total_day_one_service_charge?: number | null;
    total_day_one_ground_rent?: number | null;

    // read only fields
    customer_property_contribution?: number | null;
    customer_costs_contribution?: number | null;
    customer_conveyancer_costs_contribution?: number | null;
    customer_llp_costs_contribution?: number | null;
    fund_contribution?: number | null;
    fund_property_contribution?: number | null;
    fund_costs_contribution?: number | null;
    fund_conveyancer_costs_contribution?: number | null;
    fund_llp_costs_contribution?: number | null;
    nominee_contribution?: number | null;
    nominee_property_contribution?: number | null;
    nominee_costs_contribution?: number | null;
    nominee_conveyancer_costs_contribution?: number | null;
    nominee_llp_costs_contribution?: number | null;
    total_fees?: number | null;
    customer_ownership_percentage?: string | null;
    fund_ownership_percentage?: string | null;
    nominee_ownership_percentage?: string | null;
}

export const mapPurchaseScheduleFromApi = (fromApi: IPurchaseScheduleFromApi): IPurchaseSchedule => ({
    id: fromApi.id,
    // correct format for the timestamp required
    timestamp: fromApi.timestamp,
    propertyPurchasePrice:
        typeof fromApi.property_purchase_price === "number" ? penceToPounds(fromApi.property_purchase_price) : null,
    customerContribution:
        typeof fromApi.customer_contribution === "number" ? penceToPounds(fromApi.customer_contribution) : null,
    stampDutyLandTax:
        typeof fromApi.stamp_duty_land_tax === "number" ? penceToPounds(fromApi.stamp_duty_land_tax) : null,
    landRegistrationFee:
        typeof fromApi.land_registration_fee === "number" ? penceToPounds(fromApi.land_registration_fee) : null,
    conveyancersFee: typeof fromApi.conveyancers_fee === "number" ? penceToPounds(fromApi.conveyancers_fee) : null,
    preCompletionSearchesCost:
        typeof fromApi.pre_completion_searches_cost === "number"
            ? penceToPounds(fromApi.pre_completion_searches_cost)
            : null,
    propertySearchesCost:
        typeof fromApi.property_searches_cost === "number" ? penceToPounds(fromApi.property_searches_cost) : null,
    titleInsuranceCost:
        typeof fromApi.title_insurance_cost === "number" ? penceToPounds(fromApi.title_insurance_cost) : null,
    telegraphicTransferFee:
        typeof fromApi.telegraphic_transfer_fee === "number" ? penceToPounds(fromApi.telegraphic_transfer_fee) : null,
    propertySurveyCost:
        typeof fromApi.property_survey_cost === "number" ? penceToPounds(fromApi.property_survey_cost) : null,
    eicrInspectionFee:
        typeof fromApi.eicr_inspection_fee === "number" ? penceToPounds(fromApi.eicr_inspection_fee) : null,
    landlordComplianceWorkCost:
        typeof fromApi.landlord_compliance_work_cost === "number"
            ? penceToPounds(fromApi.landlord_compliance_work_cost)
            : null,
    gasSafetyInspectionFee:
        typeof fromApi.gas_safety_inspection_fee === "number" ? penceToPounds(fromApi.gas_safety_inspection_fee) : null,
    landlordAdvisoryWorkCost:
        typeof fromApi.landlord_advisory_work_cost === "number"
            ? penceToPounds(fromApi.landlord_advisory_work_cost)
            : null,
    landlordLicenceCheckFee:
        typeof fromApi.landlord_licence_check_fee === "number"
            ? penceToPounds(fromApi.landlord_licence_check_fee)
            : null,
    completionDayInspectionFee:
        typeof fromApi.completion_day_inspection_fee === "number"
            ? penceToPounds(fromApi.completion_day_inspection_fee)
            : null,
    llpCreationCost: typeof fromApi.llp_creation_cost === "number" ? penceToPounds(fromApi.llp_creation_cost) : null,

    // read only fields
    customerPropertyContribution:
        typeof fromApi.customer_property_contribution === "number"
            ? penceToPounds(fromApi.customer_property_contribution)
            : null,
    customerCostsContribution:
        typeof fromApi.customer_costs_contribution === "number"
            ? penceToPounds(fromApi.customer_costs_contribution)
            : null,
    customerConveyancerCostsContribution:
        typeof fromApi.customer_conveyancer_costs_contribution === "number"
            ? penceToPounds(fromApi.customer_conveyancer_costs_contribution)
            : null,
    customerLlpCostsContribution:
        typeof fromApi.customer_llp_costs_contribution === "number"
            ? penceToPounds(fromApi.customer_llp_costs_contribution)
            : null,
    customerCompletionStatementSubtotal:
        typeof fromApi.customer_completion_statement_subtotal === "number"
            ? penceToPounds(fromApi.customer_completion_statement_subtotal)
            : null,
    fundContribution: typeof fromApi.fund_contribution === "number" ? penceToPounds(fromApi.fund_contribution) : null,
    fundPropertyContribution:
        typeof fromApi.fund_property_contribution === "number"
            ? penceToPounds(fromApi.fund_property_contribution)
            : null,
    fundCostsContribution:
        typeof fromApi.fund_costs_contribution === "number" ? penceToPounds(fromApi.fund_costs_contribution) : null,
    fundConveyancerCostsContribution:
        typeof fromApi.fund_conveyancer_costs_contribution === "number"
            ? penceToPounds(fromApi.fund_conveyancer_costs_contribution)
            : null,
    fundLlpCostsContribution:
        typeof fromApi.fund_llp_costs_contribution === "number"
            ? penceToPounds(fromApi.fund_llp_costs_contribution)
            : null,
    fundCompletionStatementSubtotal:
        typeof fromApi.fund_completion_statement_subtotal === "number"
            ? penceToPounds(fromApi.fund_completion_statement_subtotal)
            : null,
    nomineeContribution:
        typeof fromApi.nominee_contribution === "number" ? penceToPounds(fromApi.nominee_contribution) : null,
    nomineePropertyContribution:
        typeof fromApi.nominee_property_contribution === "number"
            ? penceToPounds(fromApi.nominee_property_contribution)
            : null,
    nomineeCostsContribution:
        typeof fromApi.nominee_costs_contribution === "number"
            ? penceToPounds(fromApi.nominee_costs_contribution)
            : null,
    nomineeConveyancerCostsContribution:
        typeof fromApi.nominee_conveyancer_costs_contribution === "number"
            ? penceToPounds(fromApi.nominee_conveyancer_costs_contribution)
            : null,
    nomineeLlpCostsContribution:
        typeof fromApi.nominee_llp_costs_contribution === "number"
            ? penceToPounds(fromApi.nominee_llp_costs_contribution)
            : null,
    nomineeCompletionStatementSubtotal:
        typeof fromApi.nominee_completion_statement_subtotal === "number"
            ? penceToPounds(fromApi.nominee_completion_statement_subtotal)
            : null,
    totalFees: typeof fromApi.total_fees === "number" ? penceToPounds(fromApi.total_fees) : null,
    customerOwnershipPercentage: fromApi.customer_ownership_percentage,
    fundOwnershipPercentage: fromApi.fund_ownership_percentage,
    nomineeOwnershipPercentage: fromApi.nominee_ownership_percentage,
    fundIncomePercentage: fromApi.fund_income_percentage,
    nomineeIncomePercentage: fromApi.nominee_income_percentage,
    totalFormationCosts:
        typeof fromApi.total_formation_costs === "number" ? penceToPounds(fromApi.total_formation_costs) : null,
    customerLlpPurchaseCostsSubtotal:
        typeof fromApi.customer_llp_purchase_costs_subtotal === "number"
            ? penceToPounds(fromApi.customer_llp_purchase_costs_subtotal)
            : null,
    fundLlpPurchaseCostsSubtotal:
        typeof fromApi.fund_llp_purchase_costs_subtotal === "number"
            ? penceToPounds(fromApi.fund_llp_purchase_costs_subtotal)
            : null,
    nomineeLlpPurchaseCostsSubtotal:
        typeof fromApi.nominee_llp_purchase_costs_subtotal === "number"
            ? penceToPounds(fromApi.nominee_llp_purchase_costs_subtotal)
            : null,
    llpPurchaseCostsSubtotal:
        typeof fromApi.llp_purchase_costs_subtotal === "number"
            ? penceToPounds(fromApi.llp_purchase_costs_subtotal)
            : null,
    completionStatementSubtotal:
        typeof fromApi.completion_statement_subtotal === "number"
            ? penceToPounds(fromApi.completion_statement_subtotal)
            : null,
    wayhomeCustomerDepositContribution:
        typeof fromApi.wayhome_customer_deposit_contribution === "number"
            ? penceToPounds(fromApi.wayhome_customer_deposit_contribution)
            : null,
    wayhomeStampDutyContribution:
        typeof fromApi.wayhome_stamp_duty_contribution === "number"
            ? penceToPounds(fromApi.wayhome_stamp_duty_contribution)
            : null,
    customerFinancialIncentive:
        typeof fromApi.customer_financial_incentive === "number"
            ? penceToPounds(fromApi.customer_financial_incentive)
            : null,
    fundFinancialIncentive:
        typeof fromApi.fund_financial_incentive === "number" ? penceToPounds(fromApi.fund_financial_incentive) : null,
    nomineeFinancialIncentive:
        typeof fromApi.nominee_financial_incentive === "number"
            ? penceToPounds(fromApi.nominee_financial_incentive)
            : null,
    totalFinancialIncentive:
        typeof fromApi.total_financial_incentive === "number" ? penceToPounds(fromApi.total_financial_incentive) : null,
    totalDayOneServiceCharge:
        typeof fromApi.total_day_one_service_charge === "number"
            ? penceToPounds(fromApi.total_day_one_service_charge)
            : null,
    totalDayOneGroundRent:
        typeof fromApi.total_day_one_ground_rent === "number" ? penceToPounds(fromApi.total_day_one_ground_rent) : null,
    customerDayOneServiceCharge:
        typeof fromApi.customer_day_one_service_charge === "number"
            ? penceToPounds(fromApi.customer_day_one_service_charge)
            : null,
    fundDayOneServiceCharge:
        typeof fromApi.fund_day_one_service_charge === "number"
            ? penceToPounds(fromApi.fund_day_one_service_charge)
            : null,
    nomineeDayOneServiceCharge:
        typeof fromApi.nominee_day_one_service_charge === "number"
            ? penceToPounds(fromApi.nominee_day_one_service_charge)
            : null,
    customerDayOneGroundRent:
        typeof fromApi.customer_day_one_ground_rent === "number"
            ? penceToPounds(fromApi.customer_day_one_ground_rent)
            : null,
    fundDayOneGroundRent:
        typeof fromApi.fund_day_one_ground_rent === "number" ? penceToPounds(fromApi.fund_day_one_ground_rent) : null,
    nomineeDayOneGroundRent:
        typeof fromApi.nominee_day_one_ground_rent === "number"
            ? penceToPounds(fromApi.nominee_day_one_ground_rent)
            : null,
    customerCompletionStatementSubtotalIncludingDayOneCharges:
        typeof fromApi.customer_completion_statement_subtotal_including_day_one_charges === "number"
            ? penceToPounds(fromApi.customer_completion_statement_subtotal_including_day_one_charges)
            : null,
    fundCompletionStatementSubtotalIncludingDayOneCharges:
        typeof fromApi.fund_completion_statement_subtotal_including_day_one_charges === "number"
            ? penceToPounds(fromApi.fund_completion_statement_subtotal_including_day_one_charges)
            : null,
    nomineeCompletionStatementSubtotalIncludingDayOneCharges:
        typeof fromApi.nominee_completion_statement_subtotal_including_day_one_charges === "number"
            ? penceToPounds(fromApi.nominee_completion_statement_subtotal_including_day_one_charges)
            : null,
    completionStatementSubtotalIncludingDayOneCharges:
        typeof fromApi.completion_statement_subtotal_including_day_one_charges === "number"
            ? penceToPounds(fromApi.completion_statement_subtotal_including_day_one_charges)
            : null,
});

export const mapPurchaseScheduleForApi = (purchaseSchedule: IPartialPurchaseSchedule): IPurchaseScheduleForApi => {
    const forApi: IPurchaseScheduleForApi = {};

    // read & write fields
    forApi.id = purchaseSchedule.id;
    forApi.timestamp = purchaseSchedule.timestamp;

    forApi.property_purchase_price =
        purchaseSchedule.propertyPurchasePrice === undefined || purchaseSchedule.propertyPurchasePrice === null
            ? purchaseSchedule.propertyPurchasePrice
            : poundsToPence(purchaseSchedule.propertyPurchasePrice);

    forApi.customer_contribution =
        purchaseSchedule.customerContribution === undefined || purchaseSchedule.customerContribution === null
            ? purchaseSchedule.customerContribution
            : poundsToPence(purchaseSchedule.customerContribution);

    forApi.stamp_duty_land_tax =
        purchaseSchedule.stampDutyLandTax === undefined || purchaseSchedule.stampDutyLandTax === null
            ? purchaseSchedule.stampDutyLandTax
            : poundsToPence(purchaseSchedule.stampDutyLandTax);

    forApi.land_registration_fee =
        purchaseSchedule.landRegistrationFee === undefined || purchaseSchedule.landRegistrationFee === null
            ? purchaseSchedule.landRegistrationFee
            : poundsToPence(purchaseSchedule.landRegistrationFee);

    forApi.conveyancers_fee =
        purchaseSchedule.conveyancersFee === undefined || purchaseSchedule.conveyancersFee === null
            ? purchaseSchedule.conveyancersFee
            : poundsToPence(purchaseSchedule.conveyancersFee);

    forApi.pre_completion_searches_cost =
        purchaseSchedule.preCompletionSearchesCost === undefined || purchaseSchedule.preCompletionSearchesCost === null
            ? purchaseSchedule.preCompletionSearchesCost
            : poundsToPence(purchaseSchedule.preCompletionSearchesCost);

    forApi.property_searches_cost =
        purchaseSchedule.propertySearchesCost === undefined || purchaseSchedule.propertySearchesCost === null
            ? purchaseSchedule.propertySearchesCost
            : poundsToPence(purchaseSchedule.propertySearchesCost);

    forApi.title_insurance_cost =
        purchaseSchedule.titleInsuranceCost === undefined || purchaseSchedule.titleInsuranceCost === null
            ? purchaseSchedule.titleInsuranceCost
            : poundsToPence(purchaseSchedule.titleInsuranceCost);

    forApi.telegraphic_transfer_fee =
        purchaseSchedule.telegraphicTransferFee === undefined || purchaseSchedule.telegraphicTransferFee === null
            ? purchaseSchedule.telegraphicTransferFee
            : poundsToPence(purchaseSchedule.telegraphicTransferFee);

    forApi.property_survey_cost =
        purchaseSchedule.propertySurveyCost === undefined || purchaseSchedule.propertySurveyCost === null
            ? purchaseSchedule.propertySurveyCost
            : poundsToPence(purchaseSchedule.propertySurveyCost);

    forApi.eicr_inspection_fee =
        purchaseSchedule.eicrInspectionFee === undefined || purchaseSchedule.eicrInspectionFee === null
            ? purchaseSchedule.eicrInspectionFee
            : poundsToPence(purchaseSchedule.eicrInspectionFee);

    forApi.landlord_compliance_work_cost =
        purchaseSchedule.landlordComplianceWorkCost === undefined ||
        purchaseSchedule.landlordComplianceWorkCost === null
            ? purchaseSchedule.landlordComplianceWorkCost
            : poundsToPence(purchaseSchedule.landlordComplianceWorkCost);

    forApi.gas_safety_inspection_fee =
        purchaseSchedule.gasSafetyInspectionFee === undefined || purchaseSchedule.gasSafetyInspectionFee === null
            ? purchaseSchedule.gasSafetyInspectionFee
            : poundsToPence(purchaseSchedule.gasSafetyInspectionFee);

    forApi.landlord_advisory_work_cost =
        purchaseSchedule.landlordAdvisoryWorkCost === undefined || purchaseSchedule.landlordAdvisoryWorkCost == null
            ? purchaseSchedule.landlordAdvisoryWorkCost
            : poundsToPence(purchaseSchedule.landlordAdvisoryWorkCost);

    forApi.landlord_licence_check_fee =
        purchaseSchedule.landlordLicenceCheckFee === undefined || purchaseSchedule.landlordLicenceCheckFee === null
            ? purchaseSchedule.landlordLicenceCheckFee
            : poundsToPence(purchaseSchedule.landlordLicenceCheckFee);

    forApi.completion_day_inspection_fee =
        purchaseSchedule.completionDayInspectionFee === undefined ||
        purchaseSchedule.completionDayInspectionFee === null
            ? purchaseSchedule.completionDayInspectionFee
            : poundsToPence(purchaseSchedule.completionDayInspectionFee);

    forApi.llp_creation_cost =
        purchaseSchedule.llpCreationCost === undefined || purchaseSchedule.llpCreationCost === null
            ? purchaseSchedule.llpCreationCost
            : poundsToPence(purchaseSchedule.llpCreationCost);

    forApi.wayhome_customer_deposit_contribution =
        purchaseSchedule.wayhomeCustomerDepositContribution === undefined ||
        purchaseSchedule.wayhomeCustomerDepositContribution === null
            ? purchaseSchedule.wayhomeCustomerDepositContribution
            : poundsToPence(purchaseSchedule.wayhomeCustomerDepositContribution);

    forApi.wayhome_stamp_duty_contribution =
        purchaseSchedule.wayhomeStampDutyContribution === undefined ||
        purchaseSchedule.wayhomeStampDutyContribution === null
            ? purchaseSchedule.wayhomeStampDutyContribution
            : poundsToPence(purchaseSchedule.wayhomeStampDutyContribution);

    forApi.total_day_one_service_charge =
        purchaseSchedule.totalDayOneServiceCharge === undefined || purchaseSchedule.totalDayOneServiceCharge === null
            ? purchaseSchedule.totalDayOneServiceCharge
            : poundsToPence(purchaseSchedule.totalDayOneServiceCharge);

    forApi.total_day_one_ground_rent =
        purchaseSchedule.totalDayOneGroundRent === undefined || purchaseSchedule.totalDayOneGroundRent === null
            ? purchaseSchedule.totalDayOneGroundRent
            : poundsToPence(purchaseSchedule.totalDayOneGroundRent);

    return Object.keys(forApi).reduce((accumulator, key) => {
        if (forApi[key] !== undefined) accumulator[key] = forApi[key];
        return accumulator;
    }, {});
};

export const diffPurchaseScheduleForUpdate = (
    original: IPurchaseSchedule,
    updated: IPartialPurchaseSchedule,
): IPartialPurchaseSchedule => {
    return diffChanges(original, updated);
};

export const getPurchaseSchedule = async (id: string, authToken?: string | null): Promise<IPurchaseSchedule> => {
    const { body, ok, status } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/purchase-schedule`,
        authToken,
    );

    if (ok) {
        return mapPurchaseScheduleFromApi(body as IPurchaseScheduleFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get purchase schedule", status: status }));
    }
};

export const patchPurchaseSchedule = async (
    id: string,
    data: IPartialPurchaseSchedule,
    authToken?: string | null,
): Promise<IPurchaseSchedule> => {
    const forApi = mapPurchaseScheduleForApi(data);

    const { body, ok, status } = await patch(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/purchase-schedule`,
        forApi,
        authToken,
    );

    if (ok) {
        return mapPurchaseScheduleFromApi(body as IPurchaseScheduleFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to patch purchase schedule", status: status }));
    }
};
