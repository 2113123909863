import React, { useCallback, useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { Header4LabelCss, LoadingIndicator, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { PageLayout } from "components/page-layout";
import { IPartnershipData, PartnershipList } from "pages/all-partnerships/partnership-list";
import { getAllPartnerships } from "utils/partnership-api/partnerships-api";

interface IProps extends IBaseComponent {
    initLoading?: boolean;
}

export const AllPartnershipsPage: React.FC<IProps> = ({ className, initLoading = true }) => {
    const { token } = useAuth();

    const [allPartnerships, setAllPartnerships] = useState<IPartnershipData[]>([]);
    const [loading, setLoading] = useState<boolean>(initLoading);

    const getAllPartnershipsData = useCallback(async () => {
        if (token) {
            try {
                const allPartnershipDataResponse = await getAllPartnerships(token);
                const sortablePartnerships = allPartnershipDataResponse.filter((x) => x.name.match(/\d+/));
                const unsortablePartnerships = allPartnershipDataResponse.filter((x) => !x.name.match(/\d+/));

                const sortedPartnershipsList = sortablePartnerships.sort((a, b) => {
                    const matchDigitsA = a.name.match(/\d+/);
                    const matchDigitsB = b.name.match(/\d+/);

                    if (matchDigitsA && matchDigitsB) {
                        return parseInt(matchDigitsB[0]) - parseInt(matchDigitsA[0]);
                    }

                    return -1;
                });

                setAllPartnerships([...sortedPartnershipsList, ...unsortablePartnerships]);
            } catch (error) {
                captureException(error);
            } finally {
                setLoading(false);
            }
        }
    }, [token]);

    useEffect(() => {
        (async () => {
            await getAllPartnershipsData();
        })();
    }, [getAllPartnershipsData]);

    return (
        <PageLayout className={className}>
            <Heading>All Partnerships</Heading>
            <VerticalSpacing size={32} />
            {loading ? <LoadingIndicator /> : <PartnershipList partnershipData={allPartnerships} />}
        </PageLayout>
    );
};

const Heading = styled.h1`
    ${Header4LabelCss};
    font-family: ${(props) => props.theme.fontFamilySerif};
    color: ${({ theme }) => theme.neutral900};
    font-weight: 700;
`;
