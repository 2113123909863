import React from "react";

import { IPurchaseSchedule } from "types";
import { formatNumberAsCurrencyWithSymbol } from "utils/formatters/currency";

import { SubHeading, TableContainer, TableData, TableHeading } from "./table";

export const ScheduleOne: React.FC<IPurchaseSchedule> = ({
    customerContribution,
    customerOwnershipPercentage,
    fundContribution,
    fundIncomePercentage,
    fundOwnershipPercentage,
    nomineeContribution,
    nomineeIncomePercentage,
    nomineeOwnershipPercentage,
    totalFormationCosts,
}) => {
    return (
        <>
            <SubHeading>Schedule 1</SubHeading>
            <TableContainer>
                <thead>
                    <tr>
                        <TableHeading percentWidth={21}></TableHeading>
                        <TableHeading percentWidth={27} alignRight>
                            Value of share
                        </TableHeading>
                        <TableHeading percentWidth={27} alignRight>
                            Pro-rata share
                        </TableHeading>
                        <TableHeading alignRight>Pro-rata income share</TableHeading>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <TableData>Residential member</TableData>
                        <TableData alignRight>
                            {typeof customerContribution === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerContribution)
                                : ""}
                        </TableData>
                        <TableData alignRight>{customerOwnershipPercentage}%</TableData>
                        <TableData alignRight>0.00%</TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Funding member</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundContribution === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundContribution)
                                : ""}
                        </TableData>
                        <TableData alignRight isEvenRow>
                            {fundOwnershipPercentage}%
                        </TableData>
                        <TableData alignRight isEvenRow>
                            {fundIncomePercentage}%
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Wayhome member</TableData>
                        <TableData alignRight>
                            {typeof nomineeContribution === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeContribution)
                                : ""}
                        </TableData>
                        <TableData alignRight>{nomineeOwnershipPercentage}%</TableData>
                        <TableData alignRight>{nomineeIncomePercentage}%</TableData>
                    </tr>
                    <tr>
                        <TableData isTotalRow>Total</TableData>
                        <TableData alignRight isTotalRow>
                            {typeof totalFormationCosts === "number"
                                ? formatNumberAsCurrencyWithSymbol(totalFormationCosts)
                                : ""}
                        </TableData>
                        <TableData alignRight isTotalRow>
                            100.00%
                        </TableData>
                        <TableData alignRight isTotalRow>
                            100.00%
                        </TableData>
                    </tr>
                </tbody>
            </TableContainer>
        </>
    );
};
