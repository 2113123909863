import React, { useCallback, useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { FormWrapper } from "components/form/form-wrapper";
import { IPartialPartnership, IPartialPurchaseSchedule, IPartnership, IPurchaseSchedule } from "types";
import {
    TPropertyTenureType,
    diffPurchaseScheduleForUpdate,
    getListing,
    getMatch,
    getPurchaseSchedule,
    patchPurchaseSchedule,
} from "utils";

import { AssuredShortholdTenancyForm } from "./assured-shorthold-tenancy-form";
import { DocumentDownload } from "./document-download";
import { PartnershipAgreementForm } from "./partnership-agreement-form";
import { IPurchasingCostsFormValues, PurchasingCostsForm } from "./purchasing-costs-form";
import { RentAndDateForm } from "./rent-and-date-form";
import { Schedules } from "./schedules";

export interface IFinancialsTabProps extends IBaseComponent {
    partnership: IPartnership;
    savePartnership: (data: IPartialPartnership) => Promise<void>;
}

export const FinancialsTab: React.FC<IFinancialsTabProps> = ({ className, partnership, savePartnership }) => {
    const { token } = useAuth();
    const [purchaseSchedule, setPurchaseSchedule] = useState<IPurchaseSchedule>();
    const [propertyTenureType, setPropertyTenureType] = useState<TPropertyTenureType>(null);
    const [hasUnsavedEdits, setHasUnsavedEdits] = useState(false);
    const { addAutoDismissNotification } = useSystemNotification();

    const getAndSetPurchaseSchedule = useCallback(async () => {
        try {
            const purchaseSchedule = await getPurchaseSchedule(partnership.id, token);
            setPurchaseSchedule(purchaseSchedule);
        } catch (error) {
            captureException(error);
        }
    }, [partnership, token]);

    const getPropertyDetails = useCallback(async () => {
        if (!partnership.matchId || !token) {
            return;
        }

        try {
            const { listingId } = await getMatch(partnership.matchId, token);
            const { propertyTenureType } = await getListing(listingId, token);
            setPropertyTenureType(propertyTenureType);
        } catch (error) {
            captureException(error);
        }
    }, [partnership, token]);

    useEffect(() => {
        (async () => {
            await getAndSetPurchaseSchedule();
        })();
    }, [getAndSetPurchaseSchedule]);

    useEffect(() => {
        (async () => {
            await getPropertyDetails();
        })();
    }, [getPropertyDetails]);

    const savePurchaseSchedule = async (values: IPurchasingCostsFormValues) => {
        const data: IPartialPurchaseSchedule = {
            propertyPurchasePrice: values.propertyPurchasePrice,
            customerContribution: values.customerContribution,
            stampDutyLandTax: values.stampDutyLandTax,
            landRegistrationFee: values.landRegistrationFee,
            conveyancersFee: values.conveyancersFee,
            preCompletionSearchesCost: values.preCompletionSearchesCost,
            propertySearchesCost: values.propertySearchesCost,
            titleInsuranceCost: values.titleInsuranceCost,
            telegraphicTransferFee: values.telegraphicTransferFee,
            propertySurveyCost: values.propertySurveyCost,
            eicrInspectionFee: values.eicrInspectionFee,
            landlordComplianceWorkCost: values.landlordComplianceWorkCost,
            gasSafetyInspectionFee: values.gasSafetyInspectionFee,
            landlordAdvisoryWorkCost: values.landlordAdvisoryWorkCost,
            landlordLicenceCheckFee: values.landlordLicenceCheckFee,
            completionDayInspectionFee: values.completionDayInspectionFee,
            llpCreationCost: values.llpCreationCost,
            wayhomeCustomerDepositContribution: values.wayhomeCustomerDepositContribution,
            wayhomeStampDutyContribution: values.wayhomeStampDutyContribution,
            totalDayOneServiceCharge: values.totalDayOneServiceCharge,
            totalDayOneGroundRent: values.totalDayOneGroundRent,
        };

        if (typeof purchaseSchedule !== "undefined") {
            try {
                const diff = diffPurchaseScheduleForUpdate(purchaseSchedule, data);

                const result = await patchPurchaseSchedule(partnership.id, diff, token);
                setPurchaseSchedule(result);
                setHasUnsavedEdits(false);

                addAutoDismissNotification({
                    id: "save-purchase-schedule",
                    type: "success",
                    message: "Purchase schedule changes have been saved",
                });
            } catch (error) {
                captureException(error);
                addAutoDismissNotification({
                    id: "save-purchase-schedule",
                    type: "error",
                    message: "Saving failed: API error",
                });
            }
        }
    };

    return (
        <div className={className}>
            <FormWrapper>
                <RentAndDateForm
                    initialValues={{
                        initialRentPcm: partnership.initialRentPcm,
                        completionDate: partnership.completionDate,
                        exchangeDate: partnership.exchangeDate,
                        homeInsuranceCosts: partnership.homeInsuranceCosts || 299,
                        reinstatementValue: partnership.reinstatementValue,
                    }}
                    onSubmit={async (values) => {
                        await savePartnership(values);
                        setHasUnsavedEdits(false);
                    }}
                    setHasUnsavedEdits={setHasUnsavedEdits}
                />
            </FormWrapper>

            <VerticalSpacing size={24} />
            <Separator />
            <VerticalSpacing size={24} />

            {typeof purchaseSchedule !== "undefined" ? (
                <>
                    <FormWrapper>
                        <PurchasingCostsForm
                            initialValues={{
                                propertyPurchasePrice: purchaseSchedule?.propertyPurchasePrice,
                                customerContribution: purchaseSchedule?.customerContribution,
                                stampDutyLandTax: purchaseSchedule?.stampDutyLandTax,
                                landRegistrationFee: purchaseSchedule?.landRegistrationFee,
                                conveyancersFee: purchaseSchedule?.conveyancersFee,
                                preCompletionSearchesCost: purchaseSchedule?.preCompletionSearchesCost,
                                propertySearchesCost: purchaseSchedule?.propertySearchesCost,
                                titleInsuranceCost: purchaseSchedule?.titleInsuranceCost,
                                telegraphicTransferFee: purchaseSchedule?.telegraphicTransferFee,
                                propertySurveyCost: purchaseSchedule?.propertySurveyCost,
                                eicrInspectionFee: purchaseSchedule?.eicrInspectionFee,
                                landlordComplianceWorkCost: purchaseSchedule?.landlordComplianceWorkCost,
                                gasSafetyInspectionFee: purchaseSchedule?.gasSafetyInspectionFee,
                                landlordAdvisoryWorkCost: purchaseSchedule?.landlordAdvisoryWorkCost,
                                landlordLicenceCheckFee: purchaseSchedule?.landlordLicenceCheckFee,
                                completionDayInspectionFee: purchaseSchedule?.completionDayInspectionFee,
                                llpCreationCost: purchaseSchedule?.llpCreationCost,
                                wayhomeCustomerDepositContribution:
                                    purchaseSchedule?.wayhomeCustomerDepositContribution,
                                wayhomeStampDutyContribution: purchaseSchedule?.wayhomeStampDutyContribution,
                                propertyTenureType,
                                totalDayOneServiceCharge: purchaseSchedule?.totalDayOneServiceCharge,
                                totalDayOneGroundRent: purchaseSchedule?.totalDayOneGroundRent,
                            }}
                            onSubmit={async (values) => await savePurchaseSchedule(values)}
                            setHasUnsavedEdits={setHasUnsavedEdits}
                            disabled={partnership.status === "active"}
                        />
                    </FormWrapper>

                    <VerticalSpacing size={24} />
                    <Separator />
                    <VerticalSpacing size={24} />

                    <FormWrapper>
                        <PartnershipAgreementForm
                            initialValues={{
                                dateOfIncorporation: partnership.dateOfIncorporation,
                                purchasePropertyAddress: partnership.purchasePropertyAddress,
                                earlyBuyoutYear: partnership.earlyBuyoutYear,
                            }}
                            onSubmit={async (values) => {
                                await savePartnership(values);
                                setHasUnsavedEdits(false);
                            }}
                            setHasUnsavedEdits={setHasUnsavedEdits}
                        />
                    </FormWrapper>

                    <VerticalSpacing size={24} />
                    <Separator />
                    <VerticalSpacing size={24} />

                    <FormWrapper>
                        <AssuredShortholdTenancyForm
                            initialValues={{
                                occupants: partnership.occupants,
                                attachment3Content: partnership.attachment3Content,
                            }}
                            onSubmit={async (values) => {
                                await savePartnership(values);
                                setHasUnsavedEdits(false);
                            }}
                            setHasUnsavedEdits={setHasUnsavedEdits}
                            prorataRent={partnership.rentObligation}
                        />
                    </FormWrapper>
                </>
            ) : null}

            <VerticalSpacing size={24} />
            <Separator />
            <VerticalSpacing size={24} />

            <Schedules
                purchaseSchedule={purchaseSchedule}
                hasUnsavedEdits={hasUnsavedEdits}
                propertyTenureType={propertyTenureType}
            />

            <VerticalSpacing size={24} />
            <Separator />
            <VerticalSpacing size={24} />
            {purchaseSchedule ? (
                <DocumentDownload
                    propertyTenureType={propertyTenureType}
                    partnership={partnership}
                    purchaseSchedule={purchaseSchedule}
                />
            ) : null}
        </div>
    );
};

const Separator = styled.hr`
    background: ${({ theme }) => theme.neutral75};
    height: 1px;
    border: none;
`;
