import React from "react";

import { ShortParagraphCss } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { toTitleCase } from "@wayhome-uk/utils";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getRoutePathWithParamsAndQueryStrings } from "config/route-paths";

import { routes } from "../../../../../config/routes";
import { ITask } from "../../../../../types";

export interface ITaskTableProps extends IBaseComponent {
    tasks: ITask[];
}

export const TasksTable: React.FC<ITaskTableProps> = ({ className, tasks }) => (
    <TableWrapper>
        <table data-testid="tasks-table" className={className}>
            <thead>
                <StyledTableRow>
                    <StyledTableHeader>Task</StyledTableHeader>
                    <StyledTableHeader>Status</StyledTableHeader>
                    <StyledTableHeader>Details</StyledTableHeader>
                </StyledTableRow>
            </thead>
            <tbody data-testid="tasks-table-content">
                {tasks.map((task) => (
                    <StyledTableRow key={task.id} data-testid={`task-${task.id}`}>
                        <StickyTableHeader>{task.displayName}</StickyTableHeader>
                        <StyledTableData value={toTitleCase(task.status)}>{toTitleCase(task.status)}</StyledTableData>
                        <StyledTableData value={"View"}>
                            <StyledLink
                                data-testid={`${task.name}-view-btn`}
                                to={getRoutePathWithParamsAndQueryStrings(routes.taskDetail.path, {
                                    partnershipID: task.partnershipId,
                                    taskID: task.id,
                                })}
                            >
                                View
                            </StyledLink>
                        </StyledTableData>
                    </StyledTableRow>
                ))}
            </tbody>
        </table>
    </TableWrapper>
);

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid ${({ theme }) => theme.neutral75};
    border-radius: ${({ theme }) => theme.radius1};

    margin-top: ${({ theme }) => theme.spacing12};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-top: ${({ theme }) => theme.spacing24};
    }
`;

const StyledTableRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.neutral75};
`;

const StyledTableHeader = styled.th`
    ${ShortParagraphCss};
    padding: ${({ theme }) => `${theme.spacing8} ${theme.spacing12}`};
    background-color: #f2f3f8;
    font-weight: 700;
    text-align: right;
    white-space: normal;
    vertical-align: middle;

    :first-child {
        text-align: left;
    }
`;

const StyledTableData = styled.td<{ value: string }>`
    ${ShortParagraphCss};
    padding: ${({ theme }) => `${theme.spacing8} ${theme.spacing12}`};
    color: ${({ theme, value }) => (value === "Completed" ? "#2B7657" : theme.neutral800)};
    font-weight: 400;
    text-align: right;
    vertical-align: middle;
    white-space: normal;

    :first-child {
        text-align: left;
    }
`;

const StickyTableHeader = styled(StyledTableHeader)`
    position: sticky;
    left: 0;
    background-color: #d3d8e7;

    :first-child {
        width: 700px;
    }
`;

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.neutral800};
`;
