import React from "react";

import { IPurchaseSchedule } from "types";
import { formatNumberAsCurrencyWithSymbol } from "utils/formatters/currency";

import { SubHeading, TableContainer, TableData, TableHeading } from "./table";

export const ApportionmentTable: React.FC<IPurchaseSchedule> = ({
    fundCompletionStatementSubtotal,
    nomineeCompletionStatementSubtotal,
    customerCompletionStatementSubtotal,
    completionStatementSubtotal,
    customerDayOneServiceCharge,
    fundDayOneServiceCharge,
    nomineeDayOneServiceCharge,
    totalDayOneServiceCharge,
    customerDayOneGroundRent,
    fundDayOneGroundRent,
    nomineeDayOneGroundRent,
    totalDayOneGroundRent,
    customerCompletionStatementSubtotalIncludingDayOneCharges,
    fundCompletionStatementSubtotalIncludingDayOneCharges,
    nomineeCompletionStatementSubtotalIncludingDayOneCharges,
    completionStatementSubtotalIncludingDayOneCharges,
}) => {
    return (
        <>
            <SubHeading>Apportionment of Service Charge and Ground Rent</SubHeading>
            <TableContainer>
                <thead>
                    <tr>
                        <TableHeading percentWidth={21}></TableHeading>
                        <TableHeading percentWidth={21} alignRight>
                            Completion statement subtotal
                        </TableHeading>
                        <TableHeading percentWidth={21} alignRight>
                            Apportionment of Service charge
                        </TableHeading>
                        <TableHeading percentWidth={21} alignRight>
                            Apportionment of Ground Rent
                        </TableHeading>
                        <TableHeading alignRight>Total amount payable</TableHeading>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <TableData>Residential member</TableData>
                        <TableData alignRight>
                            {typeof customerCompletionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerCompletionStatementSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof customerDayOneServiceCharge === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerDayOneServiceCharge)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof customerDayOneGroundRent === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerDayOneGroundRent)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof customerCompletionStatementSubtotalIncludingDayOneCharges === "number"
                                ? formatNumberAsCurrencyWithSymbol(
                                      customerCompletionStatementSubtotalIncludingDayOneCharges,
                                  )
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Funding member</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundCompletionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundCompletionStatementSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundDayOneServiceCharge === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundDayOneServiceCharge)
                                : ""}
                        </TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundDayOneGroundRent === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundDayOneGroundRent)
                                : ""}
                        </TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundCompletionStatementSubtotalIncludingDayOneCharges === "number"
                                ? formatNumberAsCurrencyWithSymbol(
                                      fundCompletionStatementSubtotalIncludingDayOneCharges,
                                  )
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Wayhome member</TableData>
                        <TableData alignRight>
                            {typeof nomineeCompletionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeCompletionStatementSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof nomineeDayOneServiceCharge === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeDayOneServiceCharge)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof nomineeDayOneGroundRent === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeDayOneGroundRent)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof nomineeCompletionStatementSubtotalIncludingDayOneCharges === "number"
                                ? formatNumberAsCurrencyWithSymbol(
                                      nomineeCompletionStatementSubtotalIncludingDayOneCharges,
                                  )
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isTotalRow>Total</TableData>
                        <TableData alignRight isTotalRow>
                            {typeof completionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(completionStatementSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight isTotalRow>
                            {typeof totalDayOneServiceCharge === "number"
                                ? formatNumberAsCurrencyWithSymbol(totalDayOneServiceCharge)
                                : ""}
                        </TableData>
                        <TableData alignRight isTotalRow>
                            {typeof totalDayOneGroundRent === "number"
                                ? formatNumberAsCurrencyWithSymbol(totalDayOneGroundRent)
                                : ""}
                        </TableData>
                        <TableData alignRight isTotalRow>
                            {typeof completionStatementSubtotalIncludingDayOneCharges === "number"
                                ? formatNumberAsCurrencyWithSymbol(completionStatementSubtotalIncludingDayOneCharges)
                                : ""}
                        </TableData>
                    </tr>
                </tbody>
            </TableContainer>
        </>
    );
};
