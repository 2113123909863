import React from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { FormButton } from "components/form/form-atoms";
import { TextField } from "components/form/text-field";

import { IconChecked } from "../../../../../components/media/icons/icon-checked";
import { buyerDetailsFormValidationSchema } from "./buyer-details-form-validation-schema";

export interface IBuyerDetailsFormValues {
    applicantId: string | null;
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: string | null;
    email: string | null;
    addressLineOne: string | null;
    addressLineTwo: string | null;
    postcode: string | null;
    town: string | null;
    customerUniqueTaxReference: string | null;
    submittedUTRApplication: boolean | null;
    submittedUTRApplicationDate: string | null;
    phone: string | null;
    nationalInsuranceNumber: string | null;
}

export interface IBuyerDetailsFormProps extends IBaseComponent {
    buyerNumber: number;
    initialValues: IBuyerDetailsFormValues;
    onSubmit: (values: IBuyerDetailsFormValues) => Promise<void>;
    disabled?: boolean;
}

export const BuyerDetailsForm: React.FC<IBuyerDetailsFormProps> = ({
    className,
    buyerNumber,
    initialValues,
    onSubmit,
    disabled = false,
}) => {
    const handleSubmitForm = async (values: IBuyerDetailsFormValues) => {
        await onSubmit(values);
    };

    const nationalInsuranceNumberIsReadOnly = Boolean(initialValues.nationalInsuranceNumber) && disabled;

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={buyerDetailsFormValidationSchema}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <form
                    className={className}
                    data-testid="new-partnership-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                >
                    <Heading>Buyer {buyerNumber} Details</Heading>
                    <VerticalSpacing size={32} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Applicant ID" id="applicant-id" name="applicantId" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="First Name" id="first-name" name="firstName" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Last Name" id="last-name" name="lastName" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Date of Birth" id="date-of-birth" name="dateOfBirth" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Email" id="email" name="email" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Phone" id="phone" name="phone" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField
                            label="Address Line 1"
                            id="address-line-one"
                            name="addressLineOne"
                            disabled={disabled}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField
                            label="Address Line 2"
                            id="address-line-two"
                            name="addressLineTwo"
                            disabled={disabled}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Postcode" id="postcode" name="postcode" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField label="Town" id="town" name="town" disabled={disabled} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={nationalInsuranceNumberIsReadOnly}>
                        <TextField
                            label="National Insurance Number"
                            id="national-insurance-number"
                            name="nationalInsuranceNumber"
                            disabled={nationalInsuranceNumberIsReadOnly}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <TextField
                        label="Customer Unique Tax Reference"
                        id="customer-unique-tax-reference"
                        name="customerUniqueTaxReference"
                    />
                    <VerticalSpacing size={32} />

                    <CheckboxWrapper>
                        <StyledIconChecked submittedUTRApplication={values.submittedUTRApplication} />
                        <HiddenCheckbox
                            type="checkbox"
                            name={"solicitor.id"}
                            onChange={() => {
                                setFieldValue("submittedUTRApplication", !values.submittedUTRApplication);
                            }}
                            checked={Boolean(values.submittedUTRApplication)}
                        />
                    </CheckboxWrapper>
                    <Text>Submitted UTR Application</Text>
                    <VerticalSpacing size={32} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField
                            label="Submitted UTR Application Date"
                            id="submitted-utr-application-date"
                            name="submittedUTRApplicationDate"
                            disabled={disabled}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={32} />

                    <FormButton type="submit">Save</FormButton>
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const Text = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-left: 32px;
    cursor: pointer;
`;

const StyledIconChecked = styled(IconChecked)<{ submittedUTRApplication?: boolean | null }>`
    cursor: pointer;
    visibility: ${({ submittedUTRApplication }) => (submittedUTRApplication ? "visible" : "hidden")};
    position: absolute;
    left: -1px;
    top: -5px;

    svg {
        height: 25px;
        width: 25px;
    }
`;

const CheckboxWrapper = styled.span`
    position: relative;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: ${({ theme }) => theme.radius1};
    border: ${({ theme }) => `2px solid ${theme.primary200}`};
    left: -5px;
    top: -8px;
    cursor: pointer;
`;
