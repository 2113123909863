import React from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Link, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";

import { BlockedInfo } from "components/blocked-info";
import { IPartnership, IPaymentHistoryData } from "types";

export interface IPaymentsTableProps extends IBaseComponent {
    partnership: IPartnership;
    paymentHistoryData: IPaymentHistoryData[];
}

export const PaymentsTable: React.FC<IPaymentsTableProps> = ({ className, paymentHistoryData, partnership }) => {
    const { url } = useRouteMatch();

    const isEven = (index: number) => index % 2 === 0;

    const hasCustomerPartner1Email = Boolean(partnership.customerPartnerOne?.email);

    return (
        <>
            <HeadingWrapper>
                <Heading>Partnership One Off Payment</Heading>
                {hasCustomerPartner1Email ? (
                    <StyledLink to={`${url}/new-payment`}>Create New Payment Request</StyledLink>
                ) : null}
            </HeadingWrapper>
            <VerticalSpacing size={32} />
            {hasCustomerPartner1Email ? (
                <TableContainer className={className}>
                    <thead>
                        <tr>
                            <TableHeading>Date</TableHeading>
                            <TableHeading>Payment Reference</TableHeading>
                            <TableHeading alignRight={true}>Amount</TableHeading>
                            <TableHeading>Status</TableHeading>
                            <TableHeading>Action</TableHeading>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistoryData &&
                            paymentHistoryData.map((data: IPaymentHistoryData, index: number) => {
                                const isEvenRow = isEven(index);
                                return (
                                    <tr key={data.id}>
                                        <TableData isEvenRow={isEvenRow}>{data.createdAt}</TableData>
                                        <TableData isEvenRow={isEvenRow}>{data.paymentReference}</TableData>
                                        <TableData isEvenRow={isEvenRow} alignRight={true}>
                                            {data.amount &&
                                                data.amount.toLocaleString("en-UK", {
                                                    style: "currency",
                                                    currency: "GBP",
                                                })}
                                        </TableData>
                                        <TableData isEvenRow={isEvenRow} status={data.status}>
                                            {data.status}
                                        </TableData>
                                        <TableData isEvenRow={isEvenRow}>
                                            <TableLink to={`${url}/${data.id}`}>View details</TableLink>
                                        </TableData>
                                    </tr>
                                );
                            })}
                    </tbody>
                </TableContainer>
            ) : (
                <BlockedInfo warning="Customer partner 1 must have an email before one off payments can be created." />
            )}
        </>
    );
};

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const StyledLink = styled(Link)`
    ${ShortParagraphLabelCss};
    border-radius: ${({ theme }) => theme.radius1};
    display: inline-block;
    color: ${({ theme }) => theme.neutral0};
    background: ${({ theme }) => theme.primary500};
    border: 0 none;
    font-weight: 700;
    cursor: pointer;
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing40}`};
`;

const TableContainer = styled.table`
    width: 100%;
`;

const TableHeading = styled.th<{ alignRight?: boolean }>`
    ${ShortParagraphLabelCss};
    font-weight: 700;
    color: ${({ theme }) => theme.neutral800};
    background: ${({ theme }) => theme.neutral100};
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
    border-right: 1px solid ${({ theme }) => theme.neutral200};

    :last-child {
        border-right: none;
    }
`;

const TableData = styled.td<{ isEvenRow?: boolean; alignRight?: boolean; status?: string }>`
    ${ShortParagraphLabelCss};
    font-weight: 700;
    text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
    background: ${({ theme, isEvenRow }) => (isEvenRow ? theme.neutral25 : theme.neutral0)};
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    border-right: 1px solid ${({ theme }) => theme.neutral200};

    :first-letter {
        text-transform: capitalize;
    }

    :last-child {
        border-right: none;
    }

    ${({ status }) => {
        switch (status && status.toLowerCase()) {
            case "declined":
            case "failed":
                return css`
                    color: ${({ theme }) => theme.danger500};
                `;
            case "awaiting approval":
            case "awaiting payment":
                return css`
                    color: ${({ theme }) => theme.action600};
                `;
            default:
                return css`
                    color: ${({ theme }) => theme.neutral800};
                `;
        }
    }};
`;

const TableLink = styled(Link)`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.primary500};
`;
