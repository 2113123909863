import React from "react";

import { InputDropdown, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";

import { FormButton, Label } from "components/form/form-atoms";
import { FormWrapper } from "components/form/form-wrapper";

import { IPartialTask } from "../../../../../types";

export interface TaskUpdateForm extends IBaseComponent {
    initialValues: IPartialTask;
    onSubmit: (taskId: string, values: IPartialTask) => void;
    taskId: string;
}

interface Option {
    value: string;
    display: string;
    selected?: boolean | undefined | null;
}

const TASK_UPDATE_OPTIONS: Option[] = [
    { value: "pending", display: "Pending" },
    { value: "completed", display: "Completed" },
];

export const TaskUpdateForm: React.FC<TaskUpdateForm> = ({ className, initialValues, onSubmit, taskId }) => {
    const handleSubmitForm = (values: IPartialTask) => {
        onSubmit(taskId, { status: values.status });
    };

    return (
        <FormWrapper>
            <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmitForm}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <form
                        className={className}
                        data-testid="task-update-form"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Label htmlFor="task-status">Status:</Label>
                        <VerticalSpacing size={8} />
                        <InputDropdown
                            id="task-status"
                            name="status"
                            options={TASK_UPDATE_OPTIONS.map((option) => {
                                if (initialValues.status === option.value) {
                                    option.selected = true;
                                }
                                return option;
                            })}
                            onChange={(value: string) => value}
                            setField={setFieldValue}
                        />
                        <VerticalSpacing size={32} />
                        <FormButton data-testid="submit-btn" type="submit">
                            Save
                        </FormButton>
                    </form>
                )}
            </Formik>
        </FormWrapper>
    );
};
