import React, { useState } from "react";

import { captureException } from "@sentry/browser";
import { LoginCard } from "@wayhome-uk/components";
import { useAuth } from "@wayhome-uk/utils";
import { Redirect } from "react-router-dom";

import { routes } from "config/routes";

export const Login: React.FC = () => {
    const { isLoggedIn, signIn } = useAuth();
    const [status, setStatus] = useState<number | undefined>(undefined);

    const handleSubmit = async (values: { email: string; password: string }) => {
        const status = await signIn(values);
        setStatus(status);

        if (status === 500) {
            captureException("Living admin log in failed: status 500");
        }
    };

    return isLoggedIn ? (
        <Redirect to={routes.allPartnerships.path} />
    ) : (
        <LoginCard title="Wayhome Partnerships | Sign in" onSubmit={handleSubmit} status={status} />
    );
};
