import React from "react";

import { Grid, GridItem } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";

export const FormWrapper: React.FC<IBaseComponent> = ({ className, children }) => (
    <div className={className}>
        <Grid>
            <GridItem item={true} sm={12} md={8} lg={4}>
                {children}
            </GridItem>
        </Grid>
    </div>
);
