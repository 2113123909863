import { get, post } from "@wayhome-uk/utils";

import { IDirectDebit } from "types";

export interface IDirectDebitApi {
    status?: string | null;
    sort_code: string | null;
    name: string | null;
    account_number: string | null;
    phone: string | null;
    address_line_1: string | null;
    address_line_2?: string | null;
    post_code: string | null;
    post_town: string | null;
}

export const mapDirectDebitMandateFromApi = (fromApi: IDirectDebitApi): IDirectDebit => ({
    status: fromApi.status,
    sortCode: fromApi.sort_code,
    name: fromApi.name,
    accountNumber: fromApi.account_number,
    phoneNumber: fromApi.phone,
    addressLineOne: fromApi.address_line_1,
    addressLineTwo: fromApi.address_line_2,
    postcode: fromApi.post_code,
    town: fromApi.post_town,
});

export const mapDirectDebitMandateForApi = (forApi: IDirectDebit): IDirectDebitApi => ({
    sort_code: forApi.sortCode,
    name: forApi.name,
    account_number: forApi.accountNumber,
    phone: forApi.phoneNumber,
    address_line_1: forApi.addressLineOne,
    address_line_2: forApi.addressLineTwo,
    post_code: forApi.postcode,
    post_town: forApi.town,
});

export const postDirectDebitMandate = async (
    id: string,
    data: IDirectDebit,
    authToken?: string | null,
): Promise<IDirectDebit> => {
    const forApi = mapDirectDebitMandateForApi(data);

    const { ok, body, status } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/rent-direct-debit-mandate`,
        forApi,
        authToken,
    );

    if (ok) {
        return mapDirectDebitMandateFromApi(body as IDirectDebitApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to post direct debit mandate", status: status }));
    }
};

export const getDirectDebitMandate = async (id: string, authToken?: string | null): Promise<IDirectDebit> => {
    const { body, ok, status } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/rent-direct-debit-mandate`,
        authToken,
    );
    if (ok) {
        return mapDirectDebitMandateFromApi(body as IDirectDebitApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get direct debit mandate", status: status }));
    }
};
