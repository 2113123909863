import { ShortParagraphLabelCss } from "@wayhome-uk/design-system";
import styled, { css } from "styled-components";

export const SubHeading = styled.h3`
    ${ShortParagraphLabelCss};
    font-size: 20px;
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-bottom: ${({ theme }) => theme.spacing8};
`;

export const TableContainer = styled.table`
    width: 100%;
`;

export const TableHeading = styled.th<{ alignRight?: boolean; percentWidth?: number }>`
    ${ShortParagraphLabelCss};
    font-weight: 700;
    color: ${({ theme }) => theme.neutral800};
    background: ${({ theme }) => theme.neutral100};
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
    border-right: 1px solid ${({ theme }) => theme.neutral200};
    width: ${({ percentWidth }) => (percentWidth ? `${percentWidth}%` : "auto")};

    :last-child {
        border-right: none;
    }
`;

export const TableData = styled.td<{
    isEvenRow?: boolean;
    alignRight?: boolean;
    status?: string;
    isTotalRow?: boolean;
    percentWidth?: number;
}>`
    ${ShortParagraphLabelCss};
    font-weight: 700;
    text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};

    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    border-right: 1px solid ${({ theme }) => theme.neutral200};
    width: ${({ percentWidth }) => (percentWidth ? `${percentWidth}%` : "auto")};

    :first-letter {
        text-transform: capitalize;
    }

    :last-child {
        border-right: none;
    }

    ${({ status }) => {
        switch (status && status.toLowerCase()) {
            case "declined":
            case "failed":
                return css`
                    color: ${({ theme }) => theme.danger500};
                `;
            case "awaiting approval":
            case "awaiting payment":
                return css`
                    color: ${({ theme }) => theme.action600};
                `;
            default:
                return css`
                    color: ${({ theme }) => theme.neutral800};
                `;
        }
    }};

    ${({ isEvenRow, isTotalRow, theme }) => {
        if (isEvenRow) {
            return css`
                background: ${theme.neutral25};
            `;
        }

        if (isTotalRow) {
            return css`
                background: ${theme.neutral800};
                color: ${theme.neutral100};
            `;
        }

        return css`
            background: ${theme.neutral0};
        `;
    }};
`;
