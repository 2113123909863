import React, { Fragment } from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";

import { FormButton } from "components/form/form-atoms";
import { IconChecked } from "components/media/icons/icon-checked";

import { ISolicitor } from "../../../../../types";

export interface IDetailsFormValues {
    solicitorId: string | null;
}

export interface IDetailsFormProps extends IBaseComponent {
    initialValues: IDetailsFormValues;
    solicitors: ISolicitor[];

    onSubmit: (values: IDetailsFormValues) => Promise<void>;
}

export const SolicitorsForm: React.FC<IDetailsFormProps> = ({ className, initialValues, solicitors, onSubmit }) => {
    const handleSubmitForm = async (values: IDetailsFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit, setFieldValue, values }) => (
                <form
                    className={className}
                    data-testid="solicitors-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                >
                    <Heading>Solicitor</Heading>
                    <VerticalSpacing size={32} />

                    <SolicitorsContainer solicitor={values.solicitorId}>
                        {solicitors.map((solicitor) => (
                            <Fragment key={solicitor.id}>
                                <CheckboxWrapper key={solicitor.id} data-testid={solicitor.id}>
                                    <StyledIconChecked
                                        solicitor={values.solicitorId}
                                        solicitorId={solicitor.id}
                                        dataTestId={`${solicitor.id}-icon-checked`}
                                    />
                                    <HiddenCheckbox
                                        id={solicitor.id}
                                        type="checkbox"
                                        name={solicitor.id}
                                        onChange={() => {
                                            setFieldValue("solicitorId", solicitor.id);
                                        }}
                                        checked={Boolean(values.solicitorId)}
                                    />
                                </CheckboxWrapper>
                                <SolicitorsText htmlFor={solicitor.id}>{solicitor.companyName}</SolicitorsText>
                                <VerticalSpacing size={32} />
                            </Fragment>
                        ))}
                    </SolicitorsContainer>

                    <VerticalSpacing size={4} />
                    <FormButton type="submit">Save</FormButton>
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const SolicitorsContainer = styled.div<{ solicitor?: string | null }>`
    display: inline-block;
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    padding-left: 0;
    position: relative;
`;

const StyledIconChecked = styled(IconChecked)<{ solicitor?: string | null; solicitorId?: string | null }>`
    cursor: pointer;
    visibility: ${({ solicitor, solicitorId }) => (solicitor === solicitorId ? "visible" : "hidden")};
    position: absolute;
    left: -1px;
    top: -5px;

    svg {
        height: 25px;
        width: 25px;
    }
`;

const CheckboxWrapper = styled.span`
    position: relative;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: ${({ theme }) => theme.radius1};
    border: ${({ theme }) => `2px solid ${theme.primary200}`};
    left: -5px;
    top: -8px;
    cursor: pointer;
`;

const SolicitorsText = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-left: 32px;
    cursor: pointer;
`;
