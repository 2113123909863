import { post } from "@wayhome-uk/utils";

export const getImpersonationToken = async (request: { token: string; applicationId: string; password: string }) => {
    const response = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/impersonate`,
        {
            application_id: request.applicationId,
            password: request.password,
        },
        request.token,
    );
    return response;
};
