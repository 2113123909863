import React from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { ShortLeadLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { object, string } from "yup";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { ErrorMessage, FormButton, Label } from "components/form/form-atoms";
import { TextField } from "components/form/text-field";

const partnershipAgreementFormValidationSchema = object().shape({
    dateOfIncorporation: string().required("Please pick the incorporation date").nullable(),
    purchasePropertyAddress: string().required("Please provide a purchase property address").nullable(),
    earlyBuyoutYear: string()
        .required("Please provide an early buyout year")
        .min(4, "Please provide a 4 character year i.e 2035")
        .max(4, "Please provide a 4 character year i.e 2035")
        .nullable(),
});

export interface IPartnershipAgreementFormValues {
    dateOfIncorporation: Date | null;
    purchasePropertyAddress: string | null;
    earlyBuyoutYear: number | null;
}

export interface IPartnershipAgreementFormProps extends IBaseComponent {
    initialValues: IPartnershipAgreementFormValues;
    onSubmit: (values: IPartnershipAgreementFormValues) => Promise<void>;
    disabled?: boolean;
    setHasUnsavedEdits: (value: boolean) => void;
}

export const PartnershipAgreementForm: React.FC<IPartnershipAgreementFormProps> = ({
    className,
    initialValues,
    onSubmit,
    disabled,
    setHasUnsavedEdits,
}) => {
    const { addAutoDismissNotification } = useSystemNotification();

    const handleSubmitForm = async (values: IPartnershipAgreementFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validate={async (values) => {
                const isValid = await partnershipAgreementFormValidationSchema.isValid(values);
                if (!isValid) {
                    addAutoDismissNotification({
                        id: "partnership-agreement-validation-error",
                        type: "error",
                        message: "Please fill all the fields",
                    });
                }
            }}
            validationSchema={partnershipAgreementFormValidationSchema}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit, values, setFieldValue, dirty, touched, errors }) => (
                <form
                    className={className}
                    data-testid="partnership-agreement-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                    onChange={() => {
                        setHasUnsavedEdits(dirty);
                    }}
                >
                    <Heading>Partnership Agreement</Heading>
                    <VerticalSpacing size={32} />
                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <Label htmlFor="incorporation-date">Date of Incorporation</Label>
                        <VerticalSpacing size={8} />
                        <StyledDatePicker
                            id="incorporation-date"
                            name="dateOfIncorporation"
                            placeholderText="Click to select a date"
                            dateFormat="MMMM d, yyyy"
                            selected={values.dateOfIncorporation}
                            onChange={(date) => {
                                setFieldValue("dateOfIncorporation", date);
                                setHasUnsavedEdits(date !== values.dateOfIncorporation);
                            }}
                            disabled={disabled}
                        />
                        {touched.dateOfIncorporation && errors.dateOfIncorporation && (
                            <ErrorMessage>
                                <span>{errors.dateOfIncorporation}</span>
                            </ErrorMessage>
                        )}
                    </ConditionalReadOnlyField>

                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField
                            id="purchase-property-address"
                            name="purchasePropertyAddress"
                            label="Purchase Property Address"
                            disabled={disabled}
                        />
                    </ConditionalReadOnlyField>

                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField
                            id="early-buyout-year"
                            name="earlyBuyoutYear"
                            label="Early Buyout Year"
                            disabled={disabled}
                            inputType="number"
                        />
                    </ConditionalReadOnlyField>

                    <VerticalSpacing size={32} />

                    <FormButton type="submit">Save</FormButton>
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const StyledDatePicker = styled(DatePicker)`
    z-index: auto;
    width: 464px;
    font-weight: 700;
    color: ${({ theme }) => theme.primary500};
    border: 1px solid ${({ theme }) => theme.neutral200};
    padding: ${({ theme }) => theme.spacing16};
    border-radius: ${({ theme }) => theme.radius1};
`;
