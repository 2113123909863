export const purchasingCostsFormFields = [
    { id: "property-purchase-price", name: "propertyPurchasePrice", label: "Property Value" },
    { id: "customer-contribution", name: "customerContribution", label: "Customer Deposit Amount" },
    {
        id: "wayhome-customer-deposit-contribution",
        name: "wayhomeCustomerDepositContribution",
        label: "Wayhome Customer Deposit Contribution",
    },
    { id: "stamp-duty-land-tax", name: "stampDutyLandTax", label: "Stamp Duty Land Tax Fee" },
    {
        id: "wayhome-stamp-duty-contribution",
        name: "wayhomeStampDutyContribution",
        label: "Wayhome Stamp Duty Contribution",
    },
    { id: "land-registration-fee", name: "landRegistrationFee", label: "Land Registration Fee" },
    { id: "conveyancers-fee", name: "conveyancersFee", label: "Solicitor Fee" },
    {
        id: "pre-completion-searches-cost",
        name: "preCompletionSearchesCost",
        label: "Pre-Completion Transfer Fees",
    },
    { id: "property-searches-cost", name: "propertySearchesCost", label: "Property Searches Fee" },
    { id: "title-insurance-cost", name: "titleInsuranceCost", label: "Title Insurance Fee" },
    { id: "telegraphic-transfer-fee", name: "telegraphicTransferFee", label: "TT Fees" },
    { id: "property-survey-cost", name: "propertySurveyCost", label: "Property Survey Fee" },
    { id: "eicr-inspection-fee", name: "eicrInspectionFee", label: "EICR + Inspection Fee" },
    { id: "gas-safety-inspection-fee", name: "gasSafetyInspectionFee", label: "Gas Safety Inspection Fee" },
    { id: "landlord-compliance-work-cost", name: "landlordComplianceWorkCost", label: "Landlord Compliance Work Cost" },
    {
        id: "landlord-advisory-work-cost",
        name: "landlordAdvisoryWorkCost",
        label: "Landlord Advisory Work Cost",
    },
    { id: "landlord-licence-check-fee", name: "landlordLicenceCheckFee", label: "Landlord Licence Check Fee" },
    {
        id: "completion-day-inspection-fee",
        name: "completionDayInspectionFee",
        label: "Completion Day Inspection Fee",
    },
];
