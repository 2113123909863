import { number, object, string } from "yup";

export const paymentRequestValidationSchema = () => {
    return object().shape({
        amount: number().nullable().required("Required field"),
        paymentReference: string().nullable().required("Required field"),
        name: string()
            .nullable()
            .max(40, "Payment Summary should not be longer than 40 characters")
            .required("Required field"),
        description: string()
            .nullable()
            .max(150, "Payment Customer Description should not be longer than 150 characters")
            .required("Required field"),
    });
};
