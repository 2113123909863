import { penceToPounds } from "@wayhome-uk/utils";
import { get, post } from "@wayhome-uk/utils";

import { IActivationPlan, IPartnership } from "types";
import { IPartnershipFromApi, mapPartnershipFromApi } from "utils/partnership-api/partnerships-api";

export interface IActivationPlanApi {
    total_value: number;
    property_value: number;
    total_fees: number;
    customer_ownership_percentage: string;
    customer_property: number;
    customer_fees: number;
    fund_property: number;
    fund_fees: number;
    nominee_property: number;
    nominee_fees: number;
    initial_rent_pcm: number;
    rent_obligation: number;
    first_rent_payment_amount: number;
    first_rent_payment_date: string;
}

export const mapActivationPlanFromApi = (fromApi: IActivationPlanApi): IActivationPlan => ({
    totalValue: penceToPounds(fromApi.total_value),
    propertyValue: penceToPounds(fromApi.property_value),
    totalFees: penceToPounds(fromApi.total_fees),
    customerOwnershipPercentage: fromApi.customer_ownership_percentage,
    customerProperty: penceToPounds(fromApi.customer_property),
    customerFees: penceToPounds(fromApi.customer_fees),
    fundProperty: penceToPounds(fromApi.fund_property),
    fundFees: penceToPounds(fromApi.fund_fees),
    nomineeProperty: penceToPounds(fromApi.nominee_property),
    nomineeFees: penceToPounds(fromApi.nominee_fees),
    initialRentPcm: penceToPounds(fromApi.initial_rent_pcm),
    rentObligation: penceToPounds(fromApi.rent_obligation),
    firstRentPaymentAmount: penceToPounds(fromApi.first_rent_payment_amount),
    firstRentPaymentDate: new Date(fromApi.first_rent_payment_date),
});

export const getActivationPlan = async (partnershipId: string, authToken?: string | null): Promise<IActivationPlan> => {
    const { body, ok, status } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${partnershipId}/activation-plan`,
        authToken,
    );

    if (ok) {
        return mapActivationPlanFromApi(body as IActivationPlanApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get activation plan", status: status }));
    }
};

export const postSendForApproval = async (partnershipId: string, authToken?: string | null): Promise<IPartnership> => {
    const { ok, body, status } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${partnershipId}/send-for-approval`,
        {},
        authToken,
    );

    if (ok) {
        return mapPartnershipFromApi(body as IPartnershipFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to post send-for-approval", status: status }));
    }
};

export const postActivate = async (
    partnershipId: string,
    password: string,
    authToken?: string | null,
): Promise<IPartnership> => {
    const { ok, status, body } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${partnershipId}/activate`,
        { password },
        authToken,
    );

    if (status === 401) {
        throw new Error("Unauthorized/Incorrect password");
    } else if (ok) {
        return mapPartnershipFromApi(body as IPartnershipFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to post to /activate", status: status }));
    }
};
