import React from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { PasswordInput } from "@wayhome-uk/components";
import { Button, Grid, GridItem, ShortLeadLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import { isFieldValid } from "@wayhome-uk/utils";
import { Formik, FormikValues } from "formik";
import styled from "styled-components";
import { object, string } from "yup";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { ErrorMessage, Label } from "components/form/form-atoms";
import { IPartnership } from "types";
import { getMatch } from "utils";
import { getImpersonationToken } from "utils/customer-view-api";

export const passwordSchema = object({
    password: string().required("Please fill in the password field").min(6),
});

export const IMPERSONATION_EMAIL = "wayhome-become-token@wayhome.co.uk";

export interface IProps extends IBaseComponent {
    partnership: IPartnership;
}

export const CustomerView = ({ partnership }: IProps) => {
    const { token } = useAuth();
    const { addAutoDismissNotification, removeNotification } = useSystemNotification();

    const handleImpersonateSubmit = async ({ password }: FormikValues, resetForm: () => void) => {
        if (!token) {
            return;
        }
        try {
            const tempUuid = Date.now().toString();
            addAutoDismissNotification({
                id: tempUuid,
                message: "Requesting Impersonation Details...",
                type: "warning",
            });

            const { applicationId } = await getMatch(partnership.matchId as string, token);
            const { ok, body: jsonWebToken } = await getImpersonationToken({
                token,
                applicationId,
                password: password,
            });

            removeNotification(tempUuid);

            if (!ok) {
                addAutoDismissNotification({
                    id: Date.now().toString(),
                    message: "Error requesting impersonation details!",
                    type: "error",
                });
                return;
            }

            addAutoDismissNotification({
                id: Date.now().toString(),
                message: "Redirecting to New tab...",
                type: "success",
            });

            const url = `${process.env.REACT_APP_GHO_LIVING}?noTrack&email=${IMPERSONATION_EMAIL}&password=${jsonWebToken}`;
            window.open(url);
        } catch (error) {
            addAutoDismissNotification({
                id: Date.now().toString(),
                message: "Something went wrong during impersonation!",
                type: "error",
            });
        } finally {
            resetForm();
        }
    };

    return (
        <>
            <Formik
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={(values, { resetForm }) => handleImpersonateSubmit(values, resetForm)}
                initialValues={{ password: "" }}
                validationSchema={passwordSchema}
            >
                {({ handleSubmit, touched, errors, values, handleChange, isSubmitting }) => (
                    <form
                        onSubmit={isSubmitting ? () => {} : handleSubmit}
                        data-testid="impersonate-password-form"
                        autoComplete="off"
                        noValidate
                    >
                        <CustomerViewsGridContainer>
                            <GridItem colSpan={{ sm: 1, md: 8 }}>
                                <Heading>Impersonate User</Heading>
                                <VerticalSpacing size={32} />

                                <WarningText>You need the impersonation password to access this</WarningText>

                                <ConditionalReadOnlyField isReadOnly={true}>
                                    <Label htmlFor="impersonate-password">Password</Label>
                                    <VerticalSpacing size={12} />
                                    <PasswordInput
                                        id="impersonate-password"
                                        data-testid="impersonate-password"
                                        name="password"
                                        onChange={handleChange}
                                        size="small"
                                        value={values.password}
                                        isValid={isFieldValid(errors.password, touched.password)}
                                    />
                                    {!!errors.password && (
                                        <ErrorTextContainer size="small">
                                            <span data-testid="impersonate-password-error">{errors.password}</span>
                                        </ErrorTextContainer>
                                    )}
                                    <VerticalSpacing size={32} />
                                    <ImpersonateButton type="submit" variant="secondary" disabled={isSubmitting}>
                                        {isSubmitting ? "Impersonating User..." : "Impersonate User"}
                                    </ImpersonateButton>
                                </ConditionalReadOnlyField>
                            </GridItem>
                        </CustomerViewsGridContainer>
                    </form>
                )}
            </Formik>
        </>
    );
};

const ErrorTextContainer = styled(ErrorMessage)`
    ::first-letter {
        text-transform: uppercase;
    }
`;

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const CustomerViewsGridContainer = styled(Grid)`
    padding-bottom: 16px;
    max-width: 700px;
    width: 100%;
`;

const ImpersonateButton = styled(Button)`
    display: inline-block;
    font-size: 16px;
    background-color: ${({ theme }) => theme.primary50};
    border: 1px solid ${({ theme }) => theme.primary500};
    color: ${({ theme }) => theme.primary500};
    transition: ease 0.2s background-color;
    padding: 12px 16px;

    :hover {
        background-color: ${({ theme }) => theme.primary500};
        color: ${({ theme }) => theme.primary50};
        border: 1px solid ${({ theme }) => theme.primary50};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const WarningText = styled.div`
    position: relative;
    padding: 12px;
    padding-left: 20px;
    font-weight: bold;
    margin-bottom: 30px;

    ::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 0.4rem;
        background-color: ${({ theme }) => theme.red800};
        border-radius: 2rem;
    }
`;
