import React, { useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { BackLink, ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import { find, kebabCase } from "lodash";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { IPartialTask, IPartnership, ITask, TTaskStatus } from "types";
import { IDocument, getDocumentsForMatch, queryObjectToString } from "utils/document-api/document-api";

import { TaskUpdateForm } from "./task-update-form";

export interface TaskDetailProps extends IBaseComponent {
    tasks: ITask[];
    partnership: IPartnership;
    getAndSetTasks: () => void;
    updateTask: (taskId: string, values: IPartialTask, getAndSetTasks: () => void) => void;
}

export const TaskDetailPage: React.FC<TaskDetailProps> = ({ tasks, partnership, getAndSetTasks, updateTask }) => {
    const { token } = useAuth();
    const { partnershipID, taskID } = useParams<{ partnershipID: string; taskID: string }>();
    const [documents, setDocuments] = useState<IDocument[] | null>(null);
    const task = tasks.filter((task) => task.id === taskID)[0];

    useEffect(() => {
        if (partnership.matchId) {
            (async () => {
                try {
                    const documents = await getDocumentsForMatch(
                        queryObjectToString({
                            match_id: partnership.matchId,
                            include_entities: "true",
                        }),
                        token,
                    );
                    setDocuments(documents);
                } catch (error) {
                    captureException(error);
                }
            })();
        }
    }, [token, partnership]);

    let documentButton = null;
    let taskText = <TaskText>Customer didn't complete the task yet</TaskText>;

    if (
        task &&
        task.status !== "completed" &&
        task.name === "partner_1_utr_number" &&
        partnership.customerPartnerOne?.submittedUTRApplication &&
        partnership.customerPartnerOne.submittedUTRApplicationDate
    ) {
        const UTRDate = new Date(partnership.customerPartnerOne.submittedUTRApplicationDate);
        taskText = (
            <TaskText>Customer confirmed their application is already with HMRC on: {UTRDate.toDateString()}</TaskText>
        );
    }

    if (
        task &&
        task.status !== "completed" &&
        task.name === "partner_2_utr_number" &&
        partnership.customerPartnerTwo?.submittedUTRApplication &&
        partnership.customerPartnerTwo.submittedUTRApplicationDate
    ) {
        const UTRDate = new Date(partnership.customerPartnerTwo.submittedUTRApplicationDate);
        taskText = (
            <TaskText>Customer confirmed their application is already with HMRC on: {UTRDate.toDateString()}</TaskText>
        );
    }

    if (task && task.completedAt && task.status === "completed") {
        const dateObj = new Date(Date.parse(task.completedAt));
        const parsedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        const parsedTime = `${dateObj.toTimeString().substr(0, 5)}`;
        taskText = <TaskText>Task completed on: {`${parsedDate} ${parsedTime}`}</TaskText>;

        if (task.taskType === "document_upload" && documents) {
            const document = find(documents, (document) => document.documentType === kebabCase(task.name));

            if (document) {
                documentButton = (
                    <>
                        <VerticalSpacing size={16} />
                        <StyledButton onClick={() => window.open(document.bucketUrl)}>View Documents</StyledButton>
                    </>
                );
            }
        }
    }

    return (
        <>
            <BackLink>
                <a href={`/partnerships/${partnershipID}/customer-tasks`}>Back to all tasks</a>
            </BackLink>
            <VerticalSpacing size={16} />
            <Heading>{task.displayName}</Heading>
            <VerticalSpacing size={24} />

            {taskText}
            {documentButton}

            <VerticalSpacing size={32} />
            <TaskUpdateForm
                initialValues={{ status: task.status as TTaskStatus }}
                taskId={task.id}
                onSubmit={async (taskId, values) => {
                    updateTask(taskId, values, getAndSetTasks);
                }}
            />
        </>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const TaskText = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 400;
`;

const StyledButton = styled.button`
    cursor: pointer;
    display: inline-block;
    border-radius: ${({ theme }) => theme.radius1};
    padding: ${({ theme }) => `${theme.spacing8} ${theme.spacing16}`};
    font-size: ${({ theme }) => theme.type16};
    font-weight: 700;
    line-height: 1.6;

    color: ${({ theme }) => theme.primary500};
    background: ${({ theme }) => theme.primary50};
    border: 1px solid ${({ theme }) => theme.primary500};
    margin-top: ${({ theme }) => `${theme.spacing16}`};

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-top: 0;
        font-size: ${({ theme }) => theme.type18};
    }
`;
