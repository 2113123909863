import React from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components";

import { FormWrapper } from "components/form/form-wrapper";
import { TasksForm } from "pages/partnership/partnership-tabs/tasks/tasks-form";
import { IPartialTask, IPartnership, ITask, TTaskType } from "types";

import { TasksTable } from "./tasks-table";

export interface ITasksTabProps extends IBaseComponent {
    partnership: IPartnership;
    tasks: ITask[];
    getAndSetTasks: () => void;
    saveTask: (values: IPartialTask[]) => void;
}

export const generateConfiguredTasks = (partnership: IPartnership) => {
    const configured_tasks = [
        {
            name: "fire_alarm_confirmation",
            displayName: "Confirm Installation and Test of Fire Alarm",
            taskType: "confirmation" as TTaskType,
        },
    ];
    if (partnership.customerPartnerOne) {
        configured_tasks.push({
            name: "partner_1_proof_of_id",
            displayName: `Provide Partner 1's Proof of ID`,
            taskType: "document_upload" as TTaskType,
        });
        configured_tasks.push({
            name: "partner_1_proof_of_address",
            displayName: `Provide Partner 1's Proof of Address`,
            taskType: "document_upload" as TTaskType,
        });
        configured_tasks.push({
            name: "partner_1_utr_number",
            displayName: `Provide Partner 1's UTR Number`,
            taskType: "provide_data" as TTaskType,
        });
    }
    if (partnership.customerPartnerTwo) {
        configured_tasks.push({
            name: "partner_2_proof_of_id",
            displayName: `Provide Partner 2's Proof of ID`,
            taskType: "document_upload" as TTaskType,
        });
        configured_tasks.push({
            name: "partner_2_proof_of_address",
            displayName: `Provide Partner 2's Proof of Address`,
            taskType: "document_upload" as TTaskType,
        });
        configured_tasks.push({
            name: "partner_2_utr_number",
            displayName: `Provide Partner 2's UTR Number`,
            taskType: "provide_data" as TTaskType,
        });
    }
    return configured_tasks;
};

export const TasksTab: React.FC<ITasksTabProps> = ({ className, partnership, tasks, getAndSetTasks, saveTask }) => (
    <div className={className}>
        <Heading>Customer Tasks</Heading>
        <VerticalSpacing size={32} />

        {tasks.length > 0 ? (
            <TasksTable tasks={tasks} />
        ) : (
            <MissingTasksText>There's no tasks for this customer yet</MissingTasksText>
        )}

        <VerticalSpacing size={24} />
        <Separator />
        <VerticalSpacing size={24} />

        <FormWrapper>
            <TasksForm
                initialValues={{ selectedTasks: tasks ? tasks : [] }}
                configuredTasks={generateConfiguredTasks(partnership)}
                onSubmit={async (values) => {
                    await saveTask(values.selectedTasks);
                    await getAndSetTasks();
                }}
            />
        </FormWrapper>
    </div>
);

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const MissingTasksText = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 500;
`;

const Separator = styled.div`
    background: ${({ theme }) => theme.neutral75};
    height: 1px;
`;
