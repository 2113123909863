import { get, poundsToPence } from "@wayhome-uk/utils";

import { IPartnership, IPurchaseSchedule } from "types";
import { TPropertyTenureType } from "utils/listing-api";
import { ICustomerPartnerForApi, mapCustomerPartnerForApi } from "utils/partnership-api/partnerships-api";
import { IPurchaseScheduleForApi } from "utils/purchase-schedule-api/purchase-schedule-api";

export type TDocumentType =
    | "assured_shorthold_tenancy_agreement"
    | "power_of_attorney"
    | "partnership_agreement"
    | "digital_property_pack"
    | "magenta_building_insurance"
    | "arag_emergency_home_insurance"
    | "annual_accounts"
    | "llp_tax_returns";

interface IContext {
    document_type?: "Assured Shorthold Tenancy Agreement" | "Partnership Agreement";
    name: string | null;
    date_of_incorporation?: string | null;
    company_registration_number?: string | null;
    purchase_property_address?: string | null;
    early_buyout_year?: number | null;
    market_rent?: number | null;
    pro_rata_rent?: number | null;
    occupants?: string[] | null;
    attachment3_content?: string | null;
    property_tenure_type?: string | null;
    customer_partner_1?: ICustomerPartnerForApi | null;
    customer_partner_2?: ICustomerPartnerForApi | null;
    purchase_schedule?: IPurchaseScheduleForApi;
}

export interface IFormattedDataForDocuments {
    document_type: TDocumentType | null;
    redline: boolean;
    context: IContext;
}

interface IPurchaseScheduleForDocumentApi {
    property_purchase_price?: number | null;
    customer_contribution?: number | null;
    stamp_duty_land_tax?: number | null;
    land_registration_fee?: number | null;
    conveyancers_fee?: number | null;
    pre_completion_searches_cost?: number | null;
    property_searches_cost?: number | null;
    title_insurance_cost?: number | null;
    telegraphic_transfer_fee?: number | null;
    property_survey_cost?: number | null;
    eicr_inspection_fee?: number | null;
    landlord_compliance_work_cost?: number | null;
    gas_safety_inspection_fee?: number | null;
    landlord_advisory_work_cost?: number | null;
    landlord_licence_check_fee?: number | null;
    completion_day_inspection_fee?: number | null;
    fund_contribution?: number | null;
    nominee_contribution?: number | null;
    customer_ownership_percentage?: string | null;
    fund_income_percentage?: string | null;
    fund_ownership_percentage?: string | null;
    nominee_ownership_percentage?: string | null;
    nominee_income_percentage?: string | null;
    total_formation_costs?: number | null;
    customer_completion_statement_subtotal?: number | null;
    customer_llp_purchase_costs_subtotal?: number | null;
    fund_completion_statement_subtotal?: number | null;
    fund_llp_purchase_costs_subtotal?: number | null;
    nominee_completion_statement_subtotal?: number | null;
    nominee_llp_purchase_costs_subtotal?: number | null;
    completion_statement_subtotal?: number | null;
    llp_purchase_costs_subtotal?: number | null;
    customer_financial_incentive?: number | null;
    fund_financial_incentive?: number | null;
    nominee_financial_incentive?: number | null;
    total_financial_incentive?: number | null;
    total_day_one_service_charge?: number | null;
    total_day_one_ground_rent?: number | null;
    customer_day_one_service_charge?: number | null;
    fund_day_one_service_charge?: number | null;
    nominee_day_one_service_charge?: number | null;
    customer_day_one_ground_rent?: number | null;
    fund_day_one_ground_rent?: number | null;
    nominee_day_one_ground_rent?: number | null;
    customer_completion_statement_subtotal_including_day_one_charges?: number | null;
    fund_completion_statement_subtotal_including_day_one_charges?: number | null;
    nominee_completion_statement_subtotal_including_day_one_charges?: number | null;
    completion_statement_subtotal_including_day_one_charges?: number | null;
}

export type TEntityType = "application" | "listing" | "match" | "shared_resource";

export type TPackType = "IC1" | "IC2" | "IC2Legal" | "IC2Internal";

export interface IPackDocument {
    id?: number;
    name: TDocumentType;
    displayName: string;
    hasError?: boolean;
    entityType: TEntityType;
}

export interface IDocumentUploadStatus {
    hasError?: boolean;
    isLoading?: boolean;
}

export interface IDocumentUpload extends IDocument, IDocumentUploadStatus {}

interface IGetIdForEntityType {
    entityType: TEntityType;
    matchId: string;
    applicationId: string;
    listingId: string;
}

export interface IDocumentFromApi {
    bucket_url: string;
    document_status: string;
    document_type: string;
    file_format: string | null;
    file_size_bytes: number;
    uploaded: string;
    id: string;
    entity_id: string | null;
    entity_type: TEntityType;
}

export interface IDocument {
    bucketUrl: string;
    documentStatus: string;
    documentType: string;
    fileFormat: string | null;
    fileSizeBytes: number;
    uploaded: string;
    id: string;
    entityId: string | null;
    entityType: TEntityType;
}

export const mapPurchaseScheduleForDocumentApi = (
    purchaseSchedule: IPurchaseSchedule,
    propertyTenureType: TPropertyTenureType,
): IPurchaseScheduleForDocumentApi => {
    const forApi: IPurchaseScheduleForDocumentApi = {};
    forApi.property_purchase_price =
        purchaseSchedule.propertyPurchasePrice === undefined || purchaseSchedule.propertyPurchasePrice === null
            ? purchaseSchedule.propertyPurchasePrice
            : poundsToPence(purchaseSchedule.propertyPurchasePrice);

    forApi.customer_contribution =
        purchaseSchedule.customerContribution === undefined || purchaseSchedule.customerContribution === null
            ? purchaseSchedule.customerContribution
            : poundsToPence(purchaseSchedule.customerContribution);

    forApi.stamp_duty_land_tax =
        purchaseSchedule.stampDutyLandTax === undefined || purchaseSchedule.stampDutyLandTax === null
            ? purchaseSchedule.stampDutyLandTax
            : poundsToPence(purchaseSchedule.stampDutyLandTax);

    forApi.land_registration_fee =
        purchaseSchedule.landRegistrationFee === undefined || purchaseSchedule.landRegistrationFee === null
            ? purchaseSchedule.landRegistrationFee
            : poundsToPence(purchaseSchedule.landRegistrationFee);

    forApi.conveyancers_fee =
        purchaseSchedule.conveyancersFee === undefined || purchaseSchedule.conveyancersFee === null
            ? purchaseSchedule.conveyancersFee
            : poundsToPence(purchaseSchedule.conveyancersFee);

    forApi.pre_completion_searches_cost =
        purchaseSchedule.preCompletionSearchesCost === undefined || purchaseSchedule.preCompletionSearchesCost === null
            ? purchaseSchedule.preCompletionSearchesCost
            : poundsToPence(purchaseSchedule.preCompletionSearchesCost);

    forApi.property_searches_cost =
        purchaseSchedule.propertySearchesCost === undefined || purchaseSchedule.propertySearchesCost === null
            ? purchaseSchedule.propertySearchesCost
            : poundsToPence(purchaseSchedule.propertySearchesCost);

    forApi.title_insurance_cost =
        purchaseSchedule.titleInsuranceCost === undefined || purchaseSchedule.titleInsuranceCost === null
            ? purchaseSchedule.titleInsuranceCost
            : poundsToPence(purchaseSchedule.titleInsuranceCost);

    forApi.telegraphic_transfer_fee =
        purchaseSchedule.telegraphicTransferFee === undefined || purchaseSchedule.telegraphicTransferFee === null
            ? purchaseSchedule.telegraphicTransferFee
            : poundsToPence(purchaseSchedule.telegraphicTransferFee);

    forApi.property_survey_cost =
        purchaseSchedule.propertySurveyCost === undefined || purchaseSchedule.propertySurveyCost === null
            ? purchaseSchedule.propertySurveyCost
            : poundsToPence(purchaseSchedule.propertySurveyCost);

    forApi.eicr_inspection_fee =
        purchaseSchedule.eicrInspectionFee === undefined || purchaseSchedule.eicrInspectionFee === null
            ? purchaseSchedule.eicrInspectionFee
            : poundsToPence(purchaseSchedule.eicrInspectionFee);

    forApi.landlord_compliance_work_cost =
        purchaseSchedule.landlordComplianceWorkCost === undefined ||
        purchaseSchedule.landlordComplianceWorkCost === null
            ? purchaseSchedule.landlordComplianceWorkCost
            : poundsToPence(purchaseSchedule.landlordComplianceWorkCost);

    forApi.gas_safety_inspection_fee =
        purchaseSchedule.gasSafetyInspectionFee === undefined || purchaseSchedule.gasSafetyInspectionFee === null
            ? purchaseSchedule.gasSafetyInspectionFee
            : poundsToPence(purchaseSchedule.gasSafetyInspectionFee);

    forApi.landlord_advisory_work_cost =
        purchaseSchedule.landlordAdvisoryWorkCost === undefined || purchaseSchedule.landlordAdvisoryWorkCost == null
            ? purchaseSchedule.landlordAdvisoryWorkCost
            : poundsToPence(purchaseSchedule.landlordAdvisoryWorkCost);

    forApi.landlord_licence_check_fee =
        purchaseSchedule.landlordLicenceCheckFee === undefined || purchaseSchedule.landlordLicenceCheckFee === null
            ? purchaseSchedule.landlordLicenceCheckFee
            : poundsToPence(purchaseSchedule.landlordLicenceCheckFee);

    forApi.completion_day_inspection_fee =
        purchaseSchedule.completionDayInspectionFee === undefined ||
        purchaseSchedule.completionDayInspectionFee === null
            ? purchaseSchedule.completionDayInspectionFee
            : poundsToPence(purchaseSchedule.completionDayInspectionFee);

    forApi.customer_ownership_percentage = purchaseSchedule.customerOwnershipPercentage;

    forApi.fund_contribution =
        purchaseSchedule.fundContribution === undefined || purchaseSchedule.fundContribution === null
            ? purchaseSchedule.fundContribution
            : poundsToPence(purchaseSchedule.fundContribution);

    forApi.fund_ownership_percentage = purchaseSchedule.fundOwnershipPercentage;

    forApi.fund_income_percentage = purchaseSchedule.fundIncomePercentage;

    forApi.nominee_contribution =
        purchaseSchedule.nomineeContribution === undefined || purchaseSchedule.nomineeContribution === null
            ? purchaseSchedule.nomineeContribution
            : poundsToPence(purchaseSchedule.nomineeContribution);

    forApi.nominee_income_percentage = purchaseSchedule.nomineeIncomePercentage;

    forApi.total_formation_costs =
        purchaseSchedule.totalFormationCosts === undefined || purchaseSchedule.totalFormationCosts === null
            ? purchaseSchedule.totalFormationCosts
            : poundsToPence(purchaseSchedule.totalFormationCosts);

    forApi.customer_completion_statement_subtotal =
        purchaseSchedule.customerCompletionStatementSubtotal === undefined ||
        purchaseSchedule.customerCompletionStatementSubtotal === null
            ? purchaseSchedule.customerCompletionStatementSubtotal
            : poundsToPence(purchaseSchedule.customerCompletionStatementSubtotal);

    forApi.customer_llp_purchase_costs_subtotal =
        purchaseSchedule.customerLlpPurchaseCostsSubtotal === undefined ||
        purchaseSchedule.customerLlpPurchaseCostsSubtotal === null
            ? purchaseSchedule.customerLlpPurchaseCostsSubtotal
            : poundsToPence(purchaseSchedule.customerLlpPurchaseCostsSubtotal);

    forApi.fund_completion_statement_subtotal =
        purchaseSchedule.fundCompletionStatementSubtotal === undefined ||
        purchaseSchedule.fundCompletionStatementSubtotal === null
            ? purchaseSchedule.fundCompletionStatementSubtotal
            : poundsToPence(purchaseSchedule.fundCompletionStatementSubtotal);

    forApi.fund_llp_purchase_costs_subtotal =
        purchaseSchedule.fundLlpPurchaseCostsSubtotal === undefined ||
        purchaseSchedule.fundLlpPurchaseCostsSubtotal === null
            ? purchaseSchedule.fundLlpPurchaseCostsSubtotal
            : poundsToPence(purchaseSchedule.fundLlpPurchaseCostsSubtotal);

    forApi.nominee_completion_statement_subtotal =
        purchaseSchedule.nomineeCompletionStatementSubtotal === undefined ||
        purchaseSchedule.nomineeCompletionStatementSubtotal === null
            ? purchaseSchedule.nomineeCompletionStatementSubtotal
            : poundsToPence(purchaseSchedule.nomineeCompletionStatementSubtotal);

    forApi.nominee_llp_purchase_costs_subtotal =
        purchaseSchedule.nomineeLlpPurchaseCostsSubtotal === undefined ||
        purchaseSchedule.nomineeLlpPurchaseCostsSubtotal === null
            ? purchaseSchedule.nomineeLlpPurchaseCostsSubtotal
            : poundsToPence(purchaseSchedule.nomineeLlpPurchaseCostsSubtotal);

    forApi.completion_statement_subtotal =
        purchaseSchedule.completionStatementSubtotal === undefined ||
        purchaseSchedule.completionStatementSubtotal === null
            ? purchaseSchedule.completionStatementSubtotal
            : poundsToPence(purchaseSchedule.completionStatementSubtotal);

    forApi.llp_purchase_costs_subtotal =
        purchaseSchedule.llpPurchaseCostsSubtotal === undefined || purchaseSchedule.llpPurchaseCostsSubtotal === null
            ? purchaseSchedule.llpPurchaseCostsSubtotal
            : poundsToPence(purchaseSchedule.llpPurchaseCostsSubtotal);

    forApi.customer_financial_incentive =
        purchaseSchedule.customerFinancialIncentive === undefined ||
        purchaseSchedule.customerFinancialIncentive === null
            ? purchaseSchedule.customerFinancialIncentive
            : poundsToPence(purchaseSchedule.customerFinancialIncentive);

    forApi.fund_financial_incentive =
        purchaseSchedule.fundFinancialIncentive === undefined || purchaseSchedule.fundFinancialIncentive === null
            ? purchaseSchedule.fundFinancialIncentive
            : poundsToPence(purchaseSchedule.fundFinancialIncentive);

    forApi.nominee_financial_incentive =
        purchaseSchedule.nomineeFinancialIncentive === undefined || purchaseSchedule.nomineeFinancialIncentive === null
            ? purchaseSchedule.nomineeFinancialIncentive
            : poundsToPence(purchaseSchedule.nomineeFinancialIncentive);

    forApi.total_financial_incentive =
        purchaseSchedule.totalFinancialIncentive === undefined || purchaseSchedule.totalFinancialIncentive === null
            ? purchaseSchedule.totalFinancialIncentive
            : poundsToPence(purchaseSchedule.totalFinancialIncentive);

    if (propertyTenureType && propertyTenureType !== "freehold" && propertyTenureType !== "unknown") {
        forApi.total_day_one_service_charge =
            purchaseSchedule.totalDayOneServiceCharge === undefined ||
            purchaseSchedule.totalDayOneServiceCharge === null
                ? purchaseSchedule.totalDayOneServiceCharge
                : poundsToPence(purchaseSchedule.totalDayOneServiceCharge);

        forApi.total_day_one_ground_rent =
            purchaseSchedule.totalDayOneGroundRent === undefined || purchaseSchedule.totalDayOneGroundRent === null
                ? purchaseSchedule.totalDayOneGroundRent
                : poundsToPence(purchaseSchedule.totalDayOneGroundRent);

        forApi.customer_day_one_service_charge =
            purchaseSchedule.customerDayOneServiceCharge === undefined ||
            purchaseSchedule.customerDayOneServiceCharge === null
                ? purchaseSchedule.customerDayOneServiceCharge
                : poundsToPence(purchaseSchedule.customerDayOneServiceCharge);

        forApi.fund_day_one_service_charge =
            purchaseSchedule.fundDayOneServiceCharge === undefined || purchaseSchedule.fundDayOneServiceCharge === null
                ? purchaseSchedule.fundDayOneServiceCharge
                : poundsToPence(purchaseSchedule.fundDayOneServiceCharge);

        forApi.nominee_day_one_service_charge =
            purchaseSchedule.nomineeDayOneServiceCharge === undefined ||
            purchaseSchedule.nomineeDayOneServiceCharge === null
                ? purchaseSchedule.nomineeDayOneServiceCharge
                : poundsToPence(purchaseSchedule.nomineeDayOneServiceCharge);

        forApi.customer_day_one_ground_rent =
            purchaseSchedule.customerDayOneGroundRent === undefined ||
            purchaseSchedule.customerDayOneGroundRent === null
                ? purchaseSchedule.customerDayOneGroundRent
                : poundsToPence(purchaseSchedule.customerDayOneGroundRent);

        forApi.fund_day_one_ground_rent =
            purchaseSchedule.fundDayOneGroundRent === undefined || purchaseSchedule.fundDayOneGroundRent === null
                ? purchaseSchedule.fundDayOneGroundRent
                : poundsToPence(purchaseSchedule.fundDayOneGroundRent);

        forApi.nominee_day_one_ground_rent =
            purchaseSchedule.nomineeDayOneGroundRent === undefined || purchaseSchedule.nomineeDayOneGroundRent === null
                ? purchaseSchedule.nomineeDayOneGroundRent
                : poundsToPence(purchaseSchedule.nomineeDayOneGroundRent);
    }

    forApi.customer_completion_statement_subtotal_including_day_one_charges =
        purchaseSchedule.customerCompletionStatementSubtotalIncludingDayOneCharges === undefined ||
        purchaseSchedule.customerCompletionStatementSubtotalIncludingDayOneCharges === null
            ? purchaseSchedule.customerCompletionStatementSubtotalIncludingDayOneCharges
            : poundsToPence(purchaseSchedule.customerCompletionStatementSubtotalIncludingDayOneCharges);

    forApi.fund_completion_statement_subtotal_including_day_one_charges =
        purchaseSchedule.fundCompletionStatementSubtotalIncludingDayOneCharges === undefined ||
        purchaseSchedule.fundCompletionStatementSubtotalIncludingDayOneCharges === null
            ? purchaseSchedule.fundCompletionStatementSubtotalIncludingDayOneCharges
            : poundsToPence(purchaseSchedule.fundCompletionStatementSubtotalIncludingDayOneCharges);

    forApi.nominee_completion_statement_subtotal_including_day_one_charges =
        purchaseSchedule.nomineeCompletionStatementSubtotalIncludingDayOneCharges === undefined ||
        purchaseSchedule.nomineeCompletionStatementSubtotalIncludingDayOneCharges === null
            ? purchaseSchedule.nomineeCompletionStatementSubtotalIncludingDayOneCharges
            : poundsToPence(purchaseSchedule.nomineeCompletionStatementSubtotalIncludingDayOneCharges);

    forApi.completion_statement_subtotal_including_day_one_charges =
        purchaseSchedule.completionStatementSubtotalIncludingDayOneCharges === undefined ||
        purchaseSchedule.completionStatementSubtotalIncludingDayOneCharges === null
            ? purchaseSchedule.completionStatementSubtotalIncludingDayOneCharges
            : poundsToPence(purchaseSchedule.completionStatementSubtotalIncludingDayOneCharges);

    return Object.keys(forApi).reduce((accumulator, key) => {
        if (forApi[key] !== undefined) accumulator[key] = forApi[key];
        return accumulator;
    }, {});
};

export const formatPayload = (
    documentType: TDocumentType,
    redline: boolean,
    partnership: IPartnership,
    purchaseSchedule: IPurchaseSchedule,
    propertyTenureType: TPropertyTenureType,
): IFormattedDataForDocuments | null => {
    switch (documentType) {
        case "power_of_attorney":
            return {
                document_type: documentType,
                redline: redline,
                context: {
                    name: partnership.name,
                    purchase_property_address: partnership.purchasePropertyAddress,
                    customer_partner_1: partnership.customerPartnerOne
                        ? mapCustomerPartnerForApi(partnership.customerPartnerOne)
                        : null,
                    customer_partner_2: partnership.customerPartnerTwo
                        ? mapCustomerPartnerForApi(partnership.customerPartnerTwo)
                        : null,
                },
            };
        case "assured_shorthold_tenancy_agreement":
            return {
                document_type: documentType,
                redline: redline,
                context: {
                    document_type: "Assured Shorthold Tenancy Agreement",
                    name: partnership.name,
                    market_rent: poundsToPence(partnership.initialRentPcm),
                    pro_rata_rent: poundsToPence(partnership.rentObligation),
                    occupants: partnership.occupants?.split(",").map((occupant) => occupant.trim()),
                    attachment3_content: partnership.attachment3Content?.replace(/(?:\r\n|\r|\n)/g, "<br>") || "",
                    purchase_property_address: partnership.purchasePropertyAddress,
                    property_tenure_type: propertyTenureType,
                    customer_partner_1: partnership.customerPartnerOne
                        ? mapCustomerPartnerForApi(partnership.customerPartnerOne)
                        : null,
                    customer_partner_2: partnership.customerPartnerTwo
                        ? mapCustomerPartnerForApi(partnership.customerPartnerTwo)
                        : null,
                },
            };
        case "partnership_agreement":
            return {
                document_type: documentType,
                redline: redline,
                context: {
                    document_type: "Partnership Agreement",
                    name: partnership.name,
                    company_registration_number: partnership.companyRegistrationNumber,
                    early_buyout_year: partnership.earlyBuyoutYear,
                    purchase_property_address: partnership.purchasePropertyAddress,
                    date_of_incorporation: partnership?.dateOfIncorporation?.toISOString().split("T")[0],
                    customer_partner_1: partnership.customerPartnerOne
                        ? mapCustomerPartnerForApi(partnership.customerPartnerOne)
                        : null,
                    customer_partner_2: partnership.customerPartnerTwo
                        ? mapCustomerPartnerForApi(partnership.customerPartnerTwo)
                        : null,
                    purchase_schedule: mapPurchaseScheduleForDocumentApi(purchaseSchedule, propertyTenureType),
                },
            };
        default:
            return null;
    }
};

export const mapDocumentFromApi = (fromApi: IDocumentFromApi[]): IDocument[] =>
    fromApi.map((document) => ({
        id: document.id,
        bucketUrl: document.bucket_url,
        documentType: document.document_type,
        documentStatus: document.document_status,
        uploaded: document.uploaded,
        entityId: document.entity_id,
        entityType: document.entity_type,
        fileFormat: document.file_format,
        fileSizeBytes: document.file_size_bytes,
    }));

export const getDocumentsForMatch = async (
    queryParams: string,
    authToken?: string | null,
): Promise<IDocument[] | null> => {
    const { ok, status, body } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/documents-collection/documents?${queryParams}`,
        authToken,
    );

    if (ok && body.length === 0) {
        return null;
    } else if (ok) {
        return mapDocumentFromApi(body as IDocumentFromApi[]);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get documents", status: status }));
    }
};

export interface IQueryParams {
    document_id?: string | null | undefined;
    application_id?: string | null | undefined;
    match_id?: string | null | undefined;
    entity_type?: string | null | undefined;
    document_type?: string | null | undefined;
    entity_id?: string | null | undefined;
    include_entities?: "true" | "false" | null | undefined;
}

export const queryObjectToString = (queryParams: IQueryParams) => {
    for (const q in queryParams) {
        if (!queryParams[q]) {
            delete queryParams[q];
        }
    }

    return Object.entries(queryParams).reduce(
        (acc, [key, value], index) => (index === 0 ? `${key}=${value}` : `${acc}&${key}=${value}`),
        "",
    );
};

export const getIdForEntityType = ({ entityType, matchId, applicationId, listingId }: IGetIdForEntityType) => {
    if (entityType === "application") {
        return applicationId;
    }

    if (entityType === "listing") {
        return listingId;
    }

    return matchId;
};
