import { get, penceToPounds, post, poundsToPence } from "@wayhome-uk/utils";
import { format } from "date-fns";

import { IPaymentHistoryData } from "types";

export interface IPaymentHistoryDataApi {
    id: string;
    partnership_id: string;
    created_at: string;
    payment_reference: string;
    amount: number;
    status: string;
    name: string;
    description: string;
}

export const mapPaymentHistoryDataFromApi = (fromApi: IPaymentHistoryDataApi): IPaymentHistoryData => ({
    id: fromApi.id,
    partnershipId: fromApi.partnership_id,
    createdAt: fromApi.created_at ? format(new Date(fromApi.created_at), "dd/MM/yyyy") : undefined,
    paymentReference: fromApi.payment_reference,
    amount: penceToPounds(fromApi.amount),
    status: fromApi.status?.replace("-", " "),
    name: fromApi.name,
    description: fromApi.description,
});

export const mapPaymentHistoryDataForApi = (forApi: IPaymentHistoryData): Partial<IPaymentHistoryDataApi> => ({
    partnership_id: forApi.partnershipId,
    created_at: forApi.createdAt,
    payment_reference: forApi.paymentReference,
    amount: poundsToPence(forApi.amount),
    status: forApi.status,
    name: forApi.name,
    description: forApi.description,
});

export const getPaymentHistoryData = async (id: string, authToken?: string | null): Promise<IPaymentHistoryData[]> => {
    const { body, ok, status } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/adhoc-payments?partnership_id=${id}`,
        authToken,
    );

    if (ok) {
        const payments = body.results || [];
        return payments.map((payment: IPaymentHistoryDataApi) => mapPaymentHistoryDataFromApi(payment));
    } else {
        throw new Error(JSON.stringify({ error: "failed to get payment history", status: status }));
    }
};

export const getPaymentOverview = async (id: string, authToken?: string | null): Promise<IPaymentHistoryData> => {
    const { body, ok, status } = await get(`${process.env.REACT_APP_API_ENDPOINT}/v0/adhoc-payments/${id}`, authToken);
    if (ok) {
        return mapPaymentHistoryDataFromApi(body as IPaymentHistoryDataApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get payment overview", status: status }));
    }
};

export const postPaymentRequest = async (
    data: IPaymentHistoryData,
    authToken?: string | null,
): Promise<IPaymentHistoryData | undefined> => {
    const forApi = mapPaymentHistoryDataForApi(data);

    const { ok, body, status } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/adhoc-payments`,
        forApi,
        authToken,
    );

    if (ok) {
        return mapPaymentHistoryDataFromApi(body as IPaymentHistoryDataApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to post to /adhoc-payments", status: status }));
    }
};

export const postPaymentApproval = async (
    id: string,
    password: string,
    authToken?: string | null,
): Promise<IPaymentHistoryData> => {
    const { status, ok, body } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/adhoc-payments/${id}/dispatch-demand`,
        { password },
        authToken,
    );

    if (status === 401) {
        throw new Error("Unauthorized/Incorrect password");
    }

    if (ok) {
        return mapPaymentHistoryDataFromApi(body as IPaymentHistoryDataApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to post payment approval", status: status }));
    }
};
