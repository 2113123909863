import React from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { ShortLeadLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { date, object, string } from "yup";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { CurrencyField } from "components/form/currency-field";
import { ErrorMessage, FormButton, Label } from "components/form/form-atoms";

const rentAndDateFormValidationSchema = object().shape({
    completionDate: date().required("Please pick the completion date").nullable(),
    initialRentPcm: string().required("Please enter rent per month").nullable(),
});

export interface IRentAndDateFormValues {
    initialRentPcm: number | null;
    completionDate: Date | null;
    exchangeDate: Date | null;
    homeInsuranceCosts: number | null;
    reinstatementValue: number | null;
}

export interface IRentAndDateFormProps extends IBaseComponent {
    initialValues: IRentAndDateFormValues;
    onSubmit: (values: IRentAndDateFormValues) => Promise<void>;
    disabled?: boolean;
    setHasUnsavedEdits: (value: boolean) => void;
}

export const RentAndDateForm: React.FC<IRentAndDateFormProps> = ({
    className,
    initialValues,
    onSubmit,
    disabled,
    setHasUnsavedEdits,
}) => {
    const { addAutoDismissNotification } = useSystemNotification();

    const handleSubmitForm = async (values: IRentAndDateFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validate={async (values) => {
                const isValid = await rentAndDateFormValidationSchema.isValid(values);
                if (!isValid) {
                    addAutoDismissNotification({
                        id: "purchase-schedule-validation-error",
                        type: "error",
                        message: "Please fill all the fields",
                    });
                }
            }}
            validationSchema={rentAndDateFormValidationSchema}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit, values, setFieldValue, dirty, touched, errors }) => (
                <StyledForm
                    className={className}
                    data-testid="rent-and-date-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                    onChange={() => {
                        setHasUnsavedEdits(dirty);
                    }}
                >
                    <Heading>Initial Rent &#38; Completion Date</Heading>
                    <VerticalSpacing size={32} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <Label htmlFor="exchange-date">Exchange Date</Label>
                        <VerticalSpacing size={8} />
                        <StyledDatePicker
                            id="exchange-date"
                            name="exchangeDate"
                            placeholderText="Click to select a date"
                            dateFormat="MMMM d, yyyy"
                            selected={values.exchangeDate}
                            onChange={(date: Date) => {
                                // since we only select date, and not time, by default it
                                // sets time in the selected date to midnight local time.
                                // this causes issues when converted to ISO string in the
                                // mapper, that's called before the PATCH.
                                const day = date.getDate();
                                const month = date.getMonth();
                                const year = date.getFullYear();

                                // set to midday to avoid weird daylight savings errors
                                const exchangeDate = new Date(year, month, day, 12, 0, 0);
                                setFieldValue("exchangeDate", exchangeDate);
                                setHasUnsavedEdits(date !== values.exchangeDate);
                            }}
                            disabled={disabled}
                        />
                        {touched.exchangeDate && errors.exchangeDate && (
                            <ErrorMessage>
                                <span>{errors.exchangeDate}</span>
                            </ErrorMessage>
                        )}
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <Label htmlFor="completion-date">Completion Date</Label>
                        <VerticalSpacing size={8} />
                        <StyledDatePicker
                            id="completion-date"
                            name="completionDate"
                            placeholderText="Click to select a date"
                            dateFormat="MMMM d, yyyy"
                            selected={values.completionDate}
                            onChange={(date: Date) => {
                                const day = date.getDate();
                                const month = date.getMonth();
                                const year = date.getFullYear();

                                // set to midday to avoid weird daylight savings errors
                                const completionDate = new Date(year, month, day, 12, 0, 0);
                                setFieldValue("completionDate", completionDate);
                                setHasUnsavedEdits(date !== values.completionDate);
                            }}
                            disabled={disabled}
                        />
                        {touched.completionDate && errors.completionDate && (
                            <ErrorMessage>
                                <span>{errors.completionDate}</span>
                            </ErrorMessage>
                        )}
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <CurrencyField id="initial-rent-pcm" name="initialRentPcm" label="Rent Per Month" />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <CurrencyField
                            id="home-insurance-costs"
                            name="homeInsuranceCosts"
                            label="Home Insurance Costs"
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <CurrencyField id="reinstatement-value" name="reinstatementValue" label="Reinstatement Value" />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />
                    <FormButton type="submit">Save</FormButton>
                </StyledForm>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const StyledDatePicker = styled(DatePicker)<{ hasError?: boolean }>`
    z-index: auto;
    width: 464px;
    font-weight: 700;
    color: ${({ theme }) => theme.primary500};
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.danger500 : theme.neutral200)};
    padding: ${({ theme }) => theme.spacing16};
    border-radius: ${({ theme }) => theme.radius1};
`;

const StyledForm = styled.form`
    .react-datepicker-popper {
        z-index: 2;
    }
`;
