import styled, { css } from "styled-components";

import { TextField } from "components/form/text-field";

const InputStyles = css`
    input {
        border: none;
        padding: ${({ theme }) => theme.spacing4} 0;
        pointer-events: none;
        background: none;
        color: ${({ theme }) => theme.primary500};
    }
`;

export const ConditionalReadOnlyField = styled.div<{ isReadOnly?: boolean }>`
    ${({ isReadOnly }) =>
        isReadOnly &&
        css`
            ${TextField} {
                ${InputStyles};
            }
        `}
`;
