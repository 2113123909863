import React, { useRef } from "react";

import styled from "styled-components";

interface IUploadInputProps {
    documentName: string;
    onChangeUpload: (e: React.ChangeEvent<HTMLInputElement>, documentName: string) => void;
    inputLabel: string;
    loading: boolean;
    multiple?: boolean;
}

export const UploadInput = ({
    documentName,
    onChangeUpload,
    inputLabel,
    loading,
    multiple = false,
}: IUploadInputProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleUploadLabelPress = (event: React.KeyboardEvent) => {
        if ((event.code === "Space" || event.code === "Enter") && inputRef.current && !loading) {
            inputRef.current.click();
        }
    };
    return (
        <>
            <StyledSecondaryLabel
                htmlFor={!loading ? `upload-${documentName}-file` : ""}
                tabIndex={0}
                aria-label={`upload ${documentName} file`}
                onKeyDown={(event) => handleUploadLabelPress(event)}
                className={loading ? "loading" : ""}
            >
                <HiddenInput
                    type="file"
                    multiple={multiple}
                    id={`upload-${documentName}-file`}
                    onClick={(event) => ((event.target as HTMLInputElement).value = "")}
                    onChange={(event) => onChangeUpload(event, documentName)}
                    ref={inputRef}
                    accept="application/pdf"
                    data-testid={`file-upload-input-${documentName}`}
                />
                {loading ? "Uploading..." : inputLabel}
            </StyledSecondaryLabel>
        </>
    );
};

const StyledSecondaryLabel = styled.label<{ className: string }>`
    position: relative;
    display: inline-block;
    border: none;
    color: ${({ theme }) => theme.neutral0};
    background-color: ${({ theme }) => theme.primary500};
    border-radius: ${({ theme }) => theme.spacing4};
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0;
    padding: ${({ theme }) => theme.spacing8} ${({ theme }) => theme.spacing16};
    text-align: center;
    cursor: ${({ className }) => (className === "loading" ? "not-allowed" : "pointer")};

    :focus {
        color: ${({ theme }) => theme.primary500};
        background-color: ${({ theme }) => theme.primary25};
        border: 1px solid ${({ theme }) => theme.primary500};
        outline: none;
    }
    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: 1.2rem;
    }
`;

const HiddenInput = styled.input`
    display: none;
`;
