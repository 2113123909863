import React, { useCallback, useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import {
    Button,
    HorizontalSpacing,
    LongParagraphCss,
    ShortLeadLabelCss,
    ShortParagraphCss,
    ShortParagraphLabelCss,
    VerticalSpacing,
} from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import { Formik } from "formik";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";
import { object, string } from "yup";

import { Label } from "components/form/form-atoms";
import { FormWrapper } from "components/form/form-wrapper";
import { PasswordField } from "components/form/password-field";
import { IPaymentHistoryData } from "types";
import { getPaymentOverview, postPaymentApproval } from "utils/ad-hoc-payments-api/ad-hoc-payments-api";

const passwordValidationSchema = () =>
    object().shape({
        password: string().nullable().required("Please fill in the field"),
    });

export interface IPaymentRequestReviewProps extends IBaseComponent {
    updatePaymentHistoryData: () => void;
}

export const PaymentRequestReview: React.FC<IPaymentRequestReviewProps> = ({ className, updatePaymentHistoryData }) => {
    const { token } = useAuth();
    const { paymentID } = useParams<{ paymentID: string }>();
    const { url } = useRouteMatch();
    const history = useHistory();

    const [paymentData, setPaymentData] = useState<IPaymentHistoryData>();
    const [passwordError, setPasswordError] = useState<string | null>();

    const { addAutoDismissNotification } = useSystemNotification();

    const getAndSetPaymentData = useCallback(async () => {
        if (token && paymentID) {
            try {
                const paymentOverviewData = await getPaymentOverview(paymentID, token);
                setPaymentData(paymentOverviewData);
            } catch (error) {
                captureException(error);
            }
        }
    }, [paymentID, token]);

    useEffect(() => {
        (async () => {
            await getAndSetPaymentData();
        })();
    }, [getAndSetPaymentData]);

    const handleApproval = async (password: string) => {
        let shouldApprovePayment = false;

        try {
            setPasswordError(null);
            if (paymentID) {
                await postPaymentApproval(paymentID, password, token);
            }
            shouldApprovePayment = true;
        } catch (error) {
            captureException(error);
            setPasswordError("Invalid password");
        }

        if (shouldApprovePayment) {
            addAutoDismissNotification({
                id: "approved",
                type: "success",
                message: "Payment was approved",
            });

            history.push(`${url.replace(paymentID, "")}`);
        } else {
            addAutoDismissNotification({
                id: "approved",
                type: "error",
                message: "Action failed: API error",
            });
        }
    };

    const paymentDetails = [
        {
            label: "Payment Amount",
            text:
                paymentData?.amount &&
                paymentData.amount.toLocaleString("en-UK", { style: "currency", currency: "GBP" }),
        },
        { label: "Payment Reference", text: paymentData?.paymentReference },
        { label: "Payment Summary", text: paymentData?.name },
        { label: "Payment Customer Description", text: paymentData?.description },
    ];

    return (
        <div className={className}>
            <FlexWrapper>
                <Heading>Partnership One Off Payment</Heading>
                <HorizontalSpacing size={16} />
                <Status status={paymentData?.status}>{paymentData?.status}</Status>
            </FlexWrapper>
            <VerticalSpacing size={32} />
            {paymentDetails.map((detail, index) => (
                <div key={index}>
                    <Label>{detail.label}</Label>
                    <VerticalSpacing size={8} />
                    <Text>{detail.text}</Text>
                    <VerticalSpacing size={16} />
                </div>
            ))}

            {paymentData?.status === "awaiting approval" ? (
                <Formik
                    initialValues={{ password: "" }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={passwordValidationSchema}
                    onSubmit={({ password }) => handleApproval(password)}
                >
                    {({ handleSubmit }) => (
                        <form
                            className={className}
                            data-testid="approval-form"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                            noValidate={true}
                        >
                            <FormWrapper>
                                <VerticalSpacing size={32} />
                                <Heading>Approval</Heading>
                                <VerticalSpacing size={24} />
                                <Paragraph>Enter the finance password in order to proceed</Paragraph>
                                <VerticalSpacing size={16} />
                                <PasswordField label="Password" id="password" name="password" />
                                <VerticalSpacing size={24} />
                                {passwordError && (
                                    <>
                                        <ErrorText>{passwordError}</ErrorText>
                                        <VerticalSpacing size={24} />
                                    </>
                                )}
                            </FormWrapper>
                            <Button type="submit">Approve Payment Request</Button>
                        </form>
                    )}
                </Formik>
            ) : null}
        </div>
    );
};

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const Status = styled.div<{ status?: string }>`
    ${ShortParagraphLabelCss};
    padding: ${({ theme }) => theme.spacing8};
    border-radius: ${({ theme }) => theme.radius1};
    font-weight: 700;

    :first-letter {
        text-transform: capitalize;
    }

    ${({ status }) => {
        switch (status && status.toLowerCase()) {
            case "declined":
            case "failed":
                return css`
                    background: ${({ theme }) => theme.danger500};
                    color: ${({ theme }) => theme.neutral0};
                `;
            case "awaiting approval":
            case "awaiting payment":
                return css`
                    background: ${({ theme }) => theme.action500};
                    color: ${({ theme }) => theme.action900};
                `;
            default:
                return css`
                    background: ${({ theme }) => theme.neutral800};
                    color: ${({ theme }) => theme.neutral0};
                `;
        }
    }};
`;

const Text = styled.p`
    ${LongParagraphCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: 700;
`;

const Paragraph = styled.p`
    ${ShortParagraphCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const ErrorText = styled.p`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.red800};
    font-weight: 700;
`;
