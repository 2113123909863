import React from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { ShortLeadLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";
import { object, string } from "yup";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { FormButton, Label } from "components/form/form-atoms";
import { TextField } from "components/form/text-field";
import { TextAreaField } from "components/form/textarea-field";

const assuredShortholdTenancyFormValidationSchema = object().shape({
    occupants: string().nullable(),
    attachment3Content: string().nullable(),
});

export interface IAssuredShortholdTenancyFormValues {
    occupants: string | null;
    attachment3Content: string | null;
}

export interface IAssuredShortholdTenancyFormProps extends IBaseComponent {
    initialValues: IAssuredShortholdTenancyFormValues;
    onSubmit: (values: IAssuredShortholdTenancyFormValues) => Promise<void>;
    disabled?: boolean;
    setHasUnsavedEdits: (value: boolean) => void;
    prorataRent?: number | null;
}

export const AssuredShortholdTenancyForm: React.FC<IAssuredShortholdTenancyFormProps> = ({
    className,
    initialValues,
    onSubmit,
    disabled,
    setHasUnsavedEdits,
    prorataRent,
}) => {
    const { addAutoDismissNotification } = useSystemNotification();

    const handleSubmitForm = async (values: IAssuredShortholdTenancyFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validate={async (values) => {
                const isValid = await assuredShortholdTenancyFormValidationSchema.isValid(values);
                if (!isValid) {
                    addAutoDismissNotification({
                        id: "assured-shorthold-tenancy-agreement-validation-error",
                        type: "error",
                        message: "Please fill all the fields",
                    });
                }
            }}
            validationSchema={assuredShortholdTenancyFormValidationSchema}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit, dirty }) => (
                <form
                    className={className}
                    data-testid="assured-shorthold-tenancy-agreement-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                    onChange={() => {
                        setHasUnsavedEdits(dirty);
                    }}
                >
                    <Heading>Assured Shorthold Tenancy Agreement</Heading>
                    <VerticalSpacing size={32} />

                    <VerticalSpacing size={16} />

                    <ProrataContainer>
                        <Label>Pro-rata rent</Label>
                        {prorataRent ? (
                            <ProrataText>£{prorataRent}</ProrataText>
                        ) : (
                            <NoProrataText>Unavailable</NoProrataText>
                        )}
                    </ProrataContainer>

                    <VerticalSpacing size={24} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextField id="occupants" name="occupants" label="Occupants" disabled={disabled} />
                    </ConditionalReadOnlyField>

                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disabled}>
                        <TextAreaField
                            id="attachment-3-content"
                            name="attachment3Content"
                            label="Attachment 3 Wording"
                            disabled={disabled}
                        />
                    </ConditionalReadOnlyField>

                    <VerticalSpacing size={32} />

                    <FormButton type="submit">Save</FormButton>
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const ProrataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProrataText = styled.p`
    font-weight: 800;
`;

const NoProrataText = styled.p`
    color: ${({ theme }) => theme.neutral600};
    font-weight: 800;
`;
