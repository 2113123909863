import React, { useState } from "react";

import styled from "styled-components";

import { UploadInput } from "components/upload-input";
import { generateRandomString } from "utils";
import { IDocumentUpload, IPackDocument } from "utils/document-api/document-api";

import { CardItem } from "./card-item";

type RemoveFunc = (uploadedDoc: IDocumentUpload, cb: (loading: boolean) => void) => void;

interface IDocumentUploadCardProps {
    document: IPackDocument;
    onChangeUpload: (
        e: React.ChangeEvent<HTMLInputElement>,
        document: IPackDocument,
        setLoading: (val: boolean) => void,
    ) => void;
    onClickRemove: RemoveFunc;
    uploadedDocs?: IDocumentUpload[] | null;
}

export const DocumentUploadCard = ({
    onChangeUpload,
    onClickRemove,
    uploadedDocs,
    document,
}: IDocumentUploadCardProps) => {
    const [uploading, setUpLoading] = useState(false);

    const handleLoading = (loading: boolean) => {
        setUpLoading(loading);
    };

    const canUploadMore = ["annual_accounts", "llp_tax_returns"].some((docName) => document.name.includes(docName));

    return (
        <Container>
            <Heading>{document.displayName}</Heading>

            {!!uploadedDocs?.length &&
                uploadedDocs.map((uploadedDoc) => {
                    return (
                        <CardItem
                            key={uploadedDoc.id || `${generateRandomString()}`}
                            uploadedDoc={uploadedDoc}
                            onClickRemove={onClickRemove}
                        />
                    );
                })}

            {(!uploadedDocs?.length || canUploadMore) && (
                <UploadInput
                    onChangeUpload={(e) => onChangeUpload(e, document, handleLoading)}
                    documentName={document.name}
                    inputLabel="Upload"
                    loading={uploading}
                    multiple={canUploadMore}
                />
            )}
        </Container>
    );
};

const Heading = styled.h5`
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0;
    margin: 0 0 ${({ theme }) => theme.spacing16} 0;
`;

const Container = styled.div`
    background-color: ${({ theme }) => theme.neutral0};
    padding: ${({ theme }) => theme.spacing24};
`;
