import React from "react";

import { WayhomeLogoInNavbar, WayhomeLogoNavbar } from "@wayhome-uk/components";
import { PageWidth } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { routes } from "config/routes";

const LogoLink = {
    title: routes.allPartnerships.title,
    url: routes.allPartnerships.path,
};

const Menu = [
    {
        title: routes.allPartnerships.title,
        url: routes.allPartnerships.path,
    },
    {
        title: routes.newPartnership.title,
        url: routes.newPartnership.path,
    },
];

const SignOutButton = { title: "Logout", url: routes.login.path };

export const PageLayout: React.FC<IBaseComponent> = ({ className, children }) => {
    const { isLoggedIn, signOut } = useAuth();

    return (
        <Wrapper className={className} data-testid="page-layout">
            <StickyHeader>
                {isLoggedIn ? (
                    <WayhomeLogoNavbar logo={LogoLink} menu={Menu} button={SignOutButton} onClick={signOut} />
                ) : (
                    <LogoWrapper>
                        <WayhomeLogoInNavbar />
                    </LogoWrapper>
                )}
            </StickyHeader>
            <Container>
                <PageContent>{children}</PageContent>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    z-index: 1;
`;

const StickyHeader = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const LogoWrapper = styled.div`
    background-color: ${({ theme }) => theme.neutral700};
    padding: ${({ theme }) => `${theme.spacing24} 0 ${theme.spacing12} ${theme.spacing48}`};
    width: 100%;
`;

const Container = styled.div`
    background: ${({ theme }) => theme.neutral75};
    height: 100%;
    min-height: 100dvh;
    margin: 0;
`;

const PageContent = styled(PageWidth)`
    position: relative;
    z-index: 0;
    padding-top: ${({ theme }) => theme.spacing40};
    height: 100%;
`;
