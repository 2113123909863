import { isEqual, isNull, isPlainObject } from "lodash";

export const diffChanges = (original: object, changed: object): object => {
    const diff: any = {};

    for (const [key, value] of Object.entries(changed)) {
        if (!original.hasOwnProperty(key)) continue;
        if (isEqual(original[key], value)) continue;
        if (isPlainObject(value)) {
            if (isNull(original[key])) {
                diff[key] = value;
            } else {
                diff[key] = diffChanges(original[key], value);
            }
        } else {
            diff[key] = value;
        }
    }

    return diff;
};
