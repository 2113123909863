import React from "react";

import { IBaseComponent } from "@wayhome-uk/types";

export interface IIconCheckedProps extends IBaseComponent {
    title?: string;
    dataTestId?: string;
}

export const IconChecked = ({ className, title = "Icon Checked", dataTestId = "" }: IIconCheckedProps) => {
    return (
        <div className={className}>
            <title>{title}</title>
            <svg
                data-testid={dataTestId}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="2"
                />
                <path
                    d="M13.15 6L8.11 13L4.75 9.5"
                    stroke="#333331"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
