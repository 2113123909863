import React, { FC } from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { isFieldValid } from "@wayhome-uk/utils";
import { useField } from "formik";
import styled from "styled-components";

import { ErrorMessage, ExplanationText, Label, TextAreaInput } from "components/form/form-atoms";

export interface ITextAreaField extends IBaseComponent {
    id: string;
    label: string;
    explanation?: string;
    name: string;
    disabled?: boolean;
    rowNumber?: string;
}

export const Field: FC<ITextAreaField> = ({ className, id, label, explanation, name, disabled = false, rowNumber }) => {
    const [field, meta, { setValue }] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        input === "" ? setValue(null) : setValue(input);
    };

    return (
        <div className={className}>
            <Label htmlFor={id}>{label}</Label>
            {explanation && (
                <>
                    <VerticalSpacing size={8} />
                    <ExplanationText>{explanation}</ExplanationText>
                </>
            )}
            <VerticalSpacing size={8} />
            <TextAreaInput
                as="textarea"
                rows={rowNumber}
                {...field}
                id={id}
                type="text"
                isValid={isFieldValid(meta.error, meta.touched)}
                onChange={handleChange}
                value={field.value ?? ""}
                disabled={disabled}
            />
            {meta.touched && meta.error && (
                <ErrorMessage>
                    <span>{meta.error}</span>
                </ErrorMessage>
            )}
        </div>
    );
};

export const TextAreaField = styled(Field)``;
