import React from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { ShortLeadLabelCss, ShortParagraph, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";
import { object, string } from "yup";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { CurrencyField } from "components/form/currency-field";
import { FormButton, Label } from "components/form/form-atoms";
import { TPropertyTenureType, parseTenureType } from "utils";

import { purchasingCostsFormFields } from "./purchasing-costs-form-fields";

const purchasingCostFormValidationSchema = object().shape({
    propertyPurchasePrice: string().required("Please enter the property purchase price").nullable(),
    customerContribution: string().required("Please enter the customer contribution").nullable(),
    stampDutyLandTax: string().required("Please enter the SDLT").nullable(),
    landRegistrationFee: string().required("Please enter the land registration fee").nullable(),
    conveyancersFee: string().required("Please enter the conveyancers fee").nullable(),
    preCompletionSearchesCost: string().required("Please enter the pre completion searches cost").nullable(),
    propertySearchesCost: string().required("Please enter the property searches cost").nullable(),
    titleInsuranceCost: string().required("Please enter the title insurance cost").nullable(),
    telegraphicTransferFee: string().required("Please enter the telegraphic transfer fee").nullable(),
    propertySurveyCost: string().required("Please enter the property survey cost").nullable(),
    eicrInspectionFee: string().required("Please enter the EICR inspection fee").nullable(),
    landlordComplianceWorkCost: string().required("Please enter the EICR compliance works cost").nullable(),
    gasSafetyInspectionFee: string().required("Please enter the gas safety inspection fee").nullable(),
    landlordAdvisoryWorkCost: string().required("Please enter the landlord compliance work cost").nullable(),
    landlordLicenceCheckFee: string().required("Please enter the landlord licence check fee").nullable(),
    completionDayInspectionFee: string().required("Please enter the completion day inspection fee").nullable(),
    llpCreationCost: string().required("Please enter the LLP creation cost").nullable(),
    wayhomeCustomerDepositContribution: string()
        .required("Please enter the wayhome customer deposit contribution")
        .nullable(),
    wayhomeStampDutyContribution: string().required("Please enter the wayhome stamp duty contribution").nullable(),
    totalDayOneServiceCharge: string()
        .nullable()
        .when("propertyTenureType", {
            is: (val: TPropertyTenureType) => val === "leasehold" || val === "share-of-freehold",
            then: string().required("Please fill in the Apportionment of Service Charge").nullable(),
        }),
    totalDayOneGroundRent: string()
        .nullable()
        .when("propertyTenureType", {
            is: (val: TPropertyTenureType) => val === "leasehold" || val === "share-of-freehold",
            then: string().required("Please fill in the Apportionment of Ground Rent").nullable(),
        }),
});
export interface IPurchasingCostsFormValues {
    propertyPurchasePrice: number | null;
    customerContribution: number | null;
    stampDutyLandTax: number | null;
    landRegistrationFee: number | null;
    conveyancersFee: number | null;
    preCompletionSearchesCost: number | null;
    propertySearchesCost: number | null;
    titleInsuranceCost: number | null;
    telegraphicTransferFee: number | null;
    propertySurveyCost: number | null;
    eicrInspectionFee: number | null;
    landlordComplianceWorkCost: number | null;
    gasSafetyInspectionFee: number | null;
    landlordAdvisoryWorkCost: number | null;
    landlordLicenceCheckFee: number | null;
    completionDayInspectionFee: number | null;
    llpCreationCost: number | null;
    wayhomeCustomerDepositContribution: number | null;
    wayhomeStampDutyContribution: number | null;
    propertyTenureType: TPropertyTenureType;
    totalDayOneServiceCharge: number | null;
    totalDayOneGroundRent: number | null;
}

export interface IPurchasingCostsFormProps extends IBaseComponent {
    initialValues: IPurchasingCostsFormValues;
    onSubmit: (values: IPurchasingCostsFormValues) => Promise<void>;
    setHasUnsavedEdits: (value: boolean) => void;
    disabled?: boolean;
}

export const PurchasingCostsForm: React.FC<IPurchasingCostsFormProps> = ({
    className,
    initialValues,
    onSubmit,
    disabled = false,
    setHasUnsavedEdits,
}) => {
    const { addAutoDismissNotification } = useSystemNotification();

    const handleSubmitForm = async (values: IPurchasingCostsFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
            validate={async (values) => {
                const isValid = await purchasingCostFormValidationSchema.isValid(values);
                if (!isValid) {
                    addAutoDismissNotification({
                        id: "purchase-schedule-validation-error",
                        type: "error",
                        message: "Please fill all the fields",
                    });
                }
            }}
            validationSchema={purchasingCostFormValidationSchema}
        >
            {({ dirty, handleSubmit, values }) => (
                <form
                    className={className}
                    data-testid="financial-details-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                    onChange={() => {
                        setHasUnsavedEdits(dirty);
                    }}
                >
                    <Heading>Purchasing Costs</Heading>
                    <VerticalSpacing size={32} />

                    {purchasingCostsFormFields.map((cost, index) => (
                        <div key={index}>
                            <ConditionalReadOnlyField isReadOnly={disabled}>
                                <CurrencyField id={cost.id} name={cost.name} label={cost.label} disabled={disabled} />
                            </ConditionalReadOnlyField>

                            <VerticalSpacing size={16} />
                        </div>
                    ))}

                    <Label>Tenure type</Label>
                    <VerticalSpacing size={8} />
                    <ShortParagraph>{parseTenureType(values.propertyTenureType)}</ShortParagraph>

                    {values.propertyTenureType !== null &&
                    values.propertyTenureType !== "unknown" &&
                    values.propertyTenureType !== "freehold" ? (
                        <>
                            <VerticalSpacing size={16} />

                            <ConditionalReadOnlyField isReadOnly={disabled}>
                                <CurrencyField
                                    id="total-day-one-service-charge"
                                    name="totalDayOneServiceCharge"
                                    label="Apportionment of Service Charge"
                                    disabled={disabled}
                                />
                            </ConditionalReadOnlyField>

                            <VerticalSpacing size={16} />

                            <ConditionalReadOnlyField isReadOnly={disabled}>
                                <CurrencyField
                                    id="total-day-one-ground-rent"
                                    name="totalDayOneGroundRent"
                                    label="Apportionment of Ground Rent"
                                    disabled={disabled}
                                />
                            </ConditionalReadOnlyField>
                        </>
                    ) : null}

                    <VerticalSpacing size={16} />

                    <FormButton type="submit">Save</FormButton>
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;
