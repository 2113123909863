import styled from "styled-components";

export const ContentWrapper = styled.div`
    background: ${({ theme }) => theme.neutral0};
    padding: ${({ theme }) => theme.spacing24};
    border-radius: ${({ theme }) => theme.radius1};
    box-shadow: ${({ theme }) => theme.depth1};
`;

export const ContentWrapperWithTabNav = styled(ContentWrapper)`
    border-radius: ${({ theme }) => `0 ${theme.radius1} ${theme.radius1}`};
`;
