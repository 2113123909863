import React, { useState } from "react";

import { Header4LabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ContentWrapper } from "components/content-wrapper";
import { PageLayout } from "components/page-layout";
import { getRoutePathWithParamsAndQueryStrings } from "config/route-paths";
import { routes } from "config/routes";
import { NewPartnershipForm } from "pages/new-partnership/new-partnership-form";
import { getMatch } from "utils";

export const NewPartnership: React.FC<IBaseComponent> = ({ className }) => {
    const { token } = useAuth();
    const [matchIDNotFound, setMatchIDNotFound] = useState<boolean>(false);

    const history = useHistory();

    const handleSubmit = async (matchID: string) => {
        try {
            const matchResponse = await getMatch(matchID, token);
            if (matchResponse) {
                setMatchIDNotFound(false);
                history.push(
                    getRoutePathWithParamsAndQueryStrings(routes.createNewPartnership.path, {
                        matchID,
                    }),
                );
            }
        } catch (error) {
            setMatchIDNotFound(true);
        }
    };

    return (
        <StyledPageLayout className={className}>
            <Heading>New Partnership</Heading>
            <VerticalSpacing size={32} />
            <ContentWrapper>
                <NewPartnershipForm
                    initialValues={{ matchID: "" }}
                    onSubmit={handleSubmit}
                    matchIDNotFound={matchIDNotFound}
                />
            </ContentWrapper>
        </StyledPageLayout>
    );
};

const StyledPageLayout = styled(PageLayout)`
    height: 100vh;
`;

const Heading = styled.h1`
    ${Header4LabelCss};
    font-family: ${(props) => props.theme.fontFamilySerif};
    color: ${({ theme }) => theme.neutral900};
    font-weight: 700;
`;
