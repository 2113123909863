import React from "react";

import { Theme } from "@wayhome-uk/design-system";
import styled, { css } from "styled-components";

type Direction = "left" | "right" | "up" | "down";

interface Props {
    className?: string;
    iconColor?: string;
    direction?: Direction;
}

export const Chevron: React.FC<Props> = ({ direction = "right", className, iconColor = Theme.primary500, ...rest }) => {
    return (
        <StyledSVG xmlns="http://www.w3.org/2000/svg" height="48" width="48" direction={direction} {...rest}>
            <path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" fill={iconColor} />
        </StyledSVG>
    );
};

const StyledSVG = styled.svg<{ direction?: Direction }>`
    transform: ${({ direction }) => {
        switch (direction) {
            case "down":
                return css`
                    rotate(90deg)
                `;
            case "up":
                return css`
                    rotate(270deg)
                `;
            case "left":
                return css`
                    rotate(180deg)
                `;
            default:
                return css`
                    rotate(0deg)
                `;
        }
    }};
`;
