import React from "react";

import { IBaseComponent } from "@wayhome-uk/types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getRoutePathWithParamsAndQueryStrings } from "config/route-paths";
import { routes } from "config/routes";

export interface IPartnershipData {
    id: string;
    name: string;
    displayName: string | null;
}

export interface IPartnershipListProps extends IBaseComponent {
    partnershipData: IPartnershipData[];
}

const PartnershipDataRow = ({ id: partnershipID, name: llpName, displayName }: IPartnershipData) => {
    return (
        <ListRowWrapper>
            <StyledLink
                to={getRoutePathWithParamsAndQueryStrings(routes.partnership.path, {
                    partnershipID,
                })}
            >
                <DataSection>{displayName}</DataSection>
                <DataSection>{llpName}</DataSection>
            </StyledLink>
        </ListRowWrapper>
    );
};

export const PartnershipList: React.FC<IPartnershipListProps> = ({ partnershipData }) => {
    return (
        <div>
            <ListHeadingWrapper>
                <ListHeading>Address</ListHeading>
                <ListHeading>LLP Name</ListHeading>
            </ListHeadingWrapper>
            <List data-testid="partnership-list">
                {partnershipData.map((data, index) => (
                    <PartnershipDataRow key={index} id={data.id} name={data.name} displayName={data.displayName} />
                ))}
            </List>
        </div>
    );
};

const ListHeadingWrapper = styled.div`
    display: flex;
`;

const ListHeading = styled.h2`
    font-size: ${({ theme }) => theme.type18};
    font-weight: 700;
    flex: 1;
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing8}`};
    color: ${({ theme }) => theme.neutral800};
`;

const List = styled.ul`
    font-size: ${({ theme }) => theme.type18};
    font-weight: 700;
`;

const ListRowWrapper = styled.li`
    border-top: ${({ theme }) => `1px solid ${theme.neutral200}`};

    :hover {
        background-color: ${({ theme }) => theme.neutral200};
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => theme.primary500};
`;

const DataSection = styled.div`
    flex: 1;
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing8}`};
`;
