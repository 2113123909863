import React, { useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { Button, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { FormWrapper } from "components/form/form-wrapper";
import { BuyerDetailsForm } from "pages/partnership/partnership-tabs/overview/buyer-details-form";
import { DetailsForm } from "pages/partnership/partnership-tabs/overview/details-form";
import { IPartialPartnership, IPartnership, ISolicitor } from "types";

import { getSolicitors } from "../../../../utils";
import { SolicitorsForm } from "./solicitors-form";

export interface IOverviewTabProps extends IBaseComponent {
    partnership: IPartnership;
    savePartnership: (data: IPartialPartnership) => Promise<void>;
}

export const OverviewTab: React.FC<IOverviewTabProps> = ({ className, partnership, savePartnership }) => {
    const { token } = useAuth();
    const [solicitors, setSolicitors] = useState<ISolicitor[] | null>(null);
    const [showCustomerPartnerTwoForm, setShowCustomerPartnerTwoForm] = useState(
        partnership.customerPartnerTwo !== null,
    );

    useEffect(() => {
        (async () => {
            if (token) {
                try {
                    const solicitorsData = await getSolicitors(token);
                    setSolicitors(solicitorsData);
                } catch (error) {
                    captureException(error);
                }
            }
        })();
    }, [token]);

    return (
        <div className={className}>
            <FormWrapper>
                <DetailsForm
                    initialValues={{
                        name: partnership.name,
                        companyRegistrationNumber: partnership.companyRegistrationNumber,
                        displayName: partnership.displayName,
                        partnershipUniqueTaxReference: partnership.partnershipUniqueTaxReference,
                        inArrears: partnership.inArrears,
                        wayhomeManagementFeePercentage: partnership.wayhomeManagementFeePercentage,
                        matchId: partnership.matchId,
                    }}
                    onSubmit={async (values) => await savePartnership(values)}
                    disableLlpFields={Boolean(partnership.modulrCustomerId)}
                />
            </FormWrapper>

            {solicitors && (
                <>
                    <VerticalSpacing size={24} />
                    <Separator />
                    <VerticalSpacing size={24} />

                    <FormWrapper>
                        <SolicitorsForm
                            initialValues={{ solicitorId: partnership.solicitorId }}
                            solicitors={solicitors}
                            onSubmit={async (values) => await savePartnership(values)}
                        />
                    </FormWrapper>
                </>
            )}

            <VerticalSpacing size={24} />
            <Separator />
            <VerticalSpacing size={24} />

            <FormWrapper>
                <BuyerDetailsForm
                    buyerNumber={1}
                    initialValues={{
                        applicantId: partnership.customerPartnerOne?.applicantId || null,
                        firstName: partnership.customerPartnerOne?.firstName || null,
                        lastName: partnership.customerPartnerOne?.lastName || null,
                        dateOfBirth: partnership.customerPartnerOne?.dateOfBirth || null,
                        email: partnership.customerPartnerOne?.email || null,
                        addressLineOne: partnership.customerPartnerOne?.addressLineOne || null,
                        addressLineTwo: partnership.customerPartnerOne?.addressLineTwo || null,
                        postcode: partnership.customerPartnerOne?.postcode || null,
                        town: partnership.customerPartnerOne?.town || null,
                        customerUniqueTaxReference: partnership.customerPartnerOne?.customerUniqueTaxReference || null,
                        submittedUTRApplication: partnership.customerPartnerOne?.submittedUTRApplication || null,
                        submittedUTRApplicationDate:
                            partnership.customerPartnerOne?.submittedUTRApplicationDate || null,
                        phone: partnership.customerPartnerOne?.phone || null,
                        nationalInsuranceNumber: partnership.customerPartnerOne?.nationalInsuranceNumber || null,
                    }}
                    onSubmit={async (values) => await savePartnership({ customerPartnerOne: values })}
                    disabled={Boolean(partnership.modulrCustomerId)}
                />
            </FormWrapper>

            {showCustomerPartnerTwoForm ? (
                <>
                    <VerticalSpacing size={24} />
                    <Separator />
                    <VerticalSpacing size={24} />
                    <FormWrapper>
                        <BuyerDetailsForm
                            buyerNumber={2}
                            initialValues={{
                                applicantId: partnership.customerPartnerTwo?.applicantId || null,
                                firstName: partnership.customerPartnerTwo?.firstName || null,
                                lastName: partnership.customerPartnerTwo?.lastName || null,
                                dateOfBirth: partnership.customerPartnerTwo?.dateOfBirth || null,
                                email: partnership.customerPartnerTwo?.email || null,
                                addressLineOne: partnership.customerPartnerTwo?.addressLineOne || null,
                                addressLineTwo: partnership.customerPartnerTwo?.addressLineTwo || null,
                                postcode: partnership.customerPartnerTwo?.postcode || null,
                                town: partnership.customerPartnerTwo?.town || null,
                                customerUniqueTaxReference:
                                    partnership.customerPartnerTwo?.customerUniqueTaxReference || null,
                                submittedUTRApplication:
                                    partnership.customerPartnerTwo?.submittedUTRApplication || null,
                                submittedUTRApplicationDate:
                                    partnership.customerPartnerTwo?.submittedUTRApplicationDate || null,
                                phone: partnership.customerPartnerTwo?.phone || null,
                                nationalInsuranceNumber:
                                    partnership.customerPartnerTwo?.nationalInsuranceNumber || null,
                            }}
                            onSubmit={async (values) => await savePartnership({ customerPartnerTwo: values })}
                            disabled={Boolean(partnership.modulrCustomerId)}
                        />
                    </FormWrapper>
                </>
            ) : partnership.modulrCustomerId ? null : (
                <>
                    <VerticalSpacing size={16} />
                    <Button colorScheme="secondary" onClick={() => setShowCustomerPartnerTwoForm(true)}>
                        Add Another Buyer
                    </Button>
                </>
            )}
        </div>
    );
};

const Separator = styled.div`
    background: ${({ theme }) => theme.neutral75};
    height: 1px;
`;
