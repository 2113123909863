import React, { FC } from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { isFieldValid } from "@wayhome-uk/utils";
import { useField } from "formik";
import styled from "styled-components";

import { ErrorMessage, ExplanationText, Input, Label } from "components/form/form-atoms";

export interface ITextField extends IBaseComponent {
    id: string;
    label: string;
    explanation?: string;
    name: string;
    disabled?: boolean;
    inputType?: string;
}

export const Field: FC<ITextField> = ({ className, id, label, explanation, name, disabled, inputType = false }) => {
    const [field, meta, { setValue }] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        input === "" ? setValue(null) : setValue(input);
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = (event.target.value || "").trim();
        setValue(val.trim());
    };

    return (
        <div className={className}>
            <Label htmlFor={id}>{label}</Label>
            {explanation && (
                <>
                    <VerticalSpacing size={8} />
                    <ExplanationText>{explanation}</ExplanationText>
                </>
            )}
            <VerticalSpacing size={8} />
            <Input
                {...field}
                id={id}
                inputSize="medium"
                type={inputType ? inputType : "text"}
                isValid={isFieldValid(meta.error, meta.touched)}
                onChange={handleChange}
                value={field.value ?? ""}
                disabled={disabled}
                onBlur={handleBlur}
            />
            {meta.touched && meta.error && (
                <ErrorMessage>
                    <span>{meta.error}</span>
                </ErrorMessage>
            )}
        </div>
    );
};

export const TextField = styled(Field)``;
