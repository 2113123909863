import {
    decimalToPercentage,
    get,
    patch,
    penceToPounds,
    percentageToDecimal,
    post,
    poundsToPence,
} from "@wayhome-uk/utils";

import {
    ICustomerPartner,
    IPartialPartnership,
    IPartialTask,
    IPartnership,
    ITask,
    TTaskStatus,
    TTaskType,
} from "types";
import { diffChanges } from "utils/shared-functions/diff-changes";

export interface ICustomerPartnerFromApi {
    applicant_id: string | null;
    date_of_birth: string | null;
    first_name: string | null;
    last_name: string | null;
    address_line_1: string | null;
    address_line_2: string | null;
    post_town: string | null;
    post_code: string | null;
    email: string | null;
    customer_unique_tax_reference: string | null;
    submitted_utr_application: boolean | null;
    submitted_utr_application_date: string | null;
    phone: string | null;
    national_insurance_number: string | null;
}

export interface ICustomerPartnerForApi {
    applicant_id?: string | null;
    date_of_birth?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    address_line_1?: string | null;
    address_line_2?: string | null;
    post_town?: string | null;
    post_code?: string | null;
    email?: string | null;
    customer_unique_tax_reference?: string | null;
    submitted_utr_application?: boolean | null;
    submitted_utr_application_date?: string | null;
    phone?: string | null;
    national_insurance_number?: string | null;
}

export interface IPartnershipFromApi {
    id: string;
    status: string | null;
    name: string | null;
    display_name: string | null;
    match_id: string | null;
    solicitor_id: string | null;
    company_registration_number: string | null;
    partnership_unique_tax_reference: string | null;
    completion_date: string | null;
    exchange_date: string | null;
    initial_rent_pcm: number | null;
    home_insurance_costs: number | null;
    reinstatement_value: number | null;
    rent_obligation: number | null;
    customer_partner_1: ICustomerPartnerFromApi | null;
    customer_partner_2: ICustomerPartnerFromApi | null;
    in_arrears: boolean | null;
    rent_direct_debit_mandate_id: string | null;
    modulr_customer_id: string | null;
    modulr_account_id: string | null;
    wayhome_management_fee_percentage: number | null;
    date_of_incorporation: string | null;
    purchase_property_address: string | null;
    early_buyout_year: number | null;
    occupants: string | null;
    attachment3_content: string | null;
    allow_direct_payments?: boolean | null;
}

export interface IPartnershipForApi {
    id?: string | null;
    status?: string | null;
    name?: string | null;
    display_name?: string | null;
    company_registration_number?: string | null;
    partnership_unique_tax_reference?: string | null;
    completion_date?: string | null;
    exchange_date?: string | null;
    initial_rent_pcm?: number | null;
    home_insurance_costs?: number | null;
    reinstatement_value?: number | null;
    customer_partner_1?: ICustomerPartnerForApi | null;
    customer_partner_2?: ICustomerPartnerForApi | null;
    in_arrears?: boolean | null;
    rent_direct_debit_mandate_id?: string | null;
    modulr_customer_id?: string | null;
    modulr_account_id?: string | null;
    wayhome_management_fee_percentage?: number | null;
    date_of_incorporation?: string | null;
    purchase_property_address?: string | null;
    early_buyout_year?: number | null;
    occupants?: string | null;
    attachment3_content?: string | null;
    match_id?: string | null;
    solicitor_id?: string | null;
    allow_direct_payments?: boolean | null;
}

export const mapCustomerPartnerFromApi = (fromApi: ICustomerPartnerFromApi): ICustomerPartner => ({
    applicantId: fromApi.applicant_id,
    dateOfBirth: fromApi.date_of_birth,
    firstName: fromApi.first_name,
    lastName: fromApi.last_name,
    addressLineOne: fromApi.address_line_1,
    addressLineTwo: fromApi.address_line_2 ? fromApi.address_line_2 : null,
    town: fromApi.post_town,
    postcode: fromApi.post_code,
    email: fromApi.email,
    customerUniqueTaxReference: fromApi.customer_unique_tax_reference,
    submittedUTRApplication: fromApi.submitted_utr_application,
    submittedUTRApplicationDate: fromApi.submitted_utr_application_date,
    phone: fromApi.phone,
    nationalInsuranceNumber: fromApi.national_insurance_number,
});

export interface ITaskForAPI {
    name?: string | null;
    display_name?: string | null;
    status?: TTaskStatus | null;
    task_type?: TTaskType | null;
}

export interface ITaskFromAPI {
    id: string;
    partnership_id: string;
    created_at: string;
    updated_at: string;
    completed_at: string | null;
    name: string;
    display_name: string;
    status: TTaskStatus;
    task_type: TTaskType;
}

export const mapPartnershipFromApi = (fromApi: IPartnershipFromApi): IPartnership => ({
    id: fromApi.id,
    status: fromApi.status,
    name: fromApi.name,
    displayName: fromApi.display_name,
    matchId: fromApi.match_id,
    solicitorId: fromApi.solicitor_id,
    companyRegistrationNumber: fromApi.company_registration_number,
    partnershipUniqueTaxReference: fromApi.partnership_unique_tax_reference,
    completionDate: fromApi.completion_date ? new Date(fromApi.completion_date) : null,
    exchangeDate: fromApi.exchange_date ? new Date(fromApi.exchange_date) : null,
    initialRentPcm: fromApi.initial_rent_pcm ? penceToPounds(fromApi.initial_rent_pcm) : null,
    homeInsuranceCosts: fromApi.home_insurance_costs ? penceToPounds(fromApi.home_insurance_costs) : null,
    reinstatementValue: fromApi.reinstatement_value ? penceToPounds(fromApi.reinstatement_value) : null,
    rentObligation: fromApi.rent_obligation ? penceToPounds(fromApi.rent_obligation) : null,
    inArrears: fromApi.in_arrears,
    rentDirectDebitMandateId: fromApi.rent_direct_debit_mandate_id,
    modulrCustomerId: fromApi.modulr_customer_id,
    modulrAccountId: fromApi.modulr_account_id,
    customerPartnerOne: fromApi.customer_partner_1 ? mapCustomerPartnerFromApi(fromApi.customer_partner_1) : null,
    customerPartnerTwo: fromApi.customer_partner_2 ? mapCustomerPartnerFromApi(fromApi.customer_partner_2) : null,
    wayhomeManagementFeePercentage: fromApi.wayhome_management_fee_percentage
        ? decimalToPercentage(fromApi.wayhome_management_fee_percentage, 3)
        : null,
    dateOfIncorporation: fromApi.date_of_incorporation ? new Date(fromApi.date_of_incorporation) : null,
    purchasePropertyAddress: fromApi.purchase_property_address,
    earlyBuyoutYear: fromApi.early_buyout_year,
    occupants: fromApi.occupants,
    attachment3Content: fromApi.attachment3_content,
    allowDirectPayments: fromApi.allow_direct_payments,
});

export const mapCustomerPartnerForApi = (customerPartner: Partial<ICustomerPartner>): ICustomerPartnerForApi => {
    const forApi: ICustomerPartnerForApi = {};

    forApi.applicant_id = customerPartner.applicantId;
    forApi.date_of_birth = customerPartner.dateOfBirth;
    forApi.first_name = customerPartner.firstName;
    forApi.last_name = customerPartner.lastName;
    forApi.address_line_1 = customerPartner.addressLineOne;
    forApi.address_line_2 = customerPartner.addressLineTwo;
    forApi.post_town = customerPartner.town;
    forApi.post_code = customerPartner.postcode;
    forApi.email = customerPartner.email;
    forApi.customer_unique_tax_reference = customerPartner.customerUniqueTaxReference;
    forApi.submitted_utr_application = customerPartner.submittedUTRApplication;
    forApi.submitted_utr_application_date = customerPartner.submittedUTRApplicationDate;
    forApi.phone = customerPartner.phone;
    forApi.national_insurance_number = customerPartner.nationalInsuranceNumber;

    return Object.keys(forApi).reduce((accumulator, key) => {
        if (forApi[key] !== undefined) accumulator[key] = forApi[key];
        return accumulator;
    }, {});
};

export const mapPartnershipForApi = (partnership: IPartialPartnership): IPartnershipForApi => {
    const forApi: IPartnershipForApi = {};
    forApi.allow_direct_payments = partnership.allowDirectPayments;
    forApi.id = partnership.id;
    forApi.status = partnership.status;
    forApi.name = partnership.name;
    forApi.display_name = partnership.displayName;
    forApi.company_registration_number = partnership.companyRegistrationNumber;
    forApi.partnership_unique_tax_reference = partnership.partnershipUniqueTaxReference;
    forApi.completion_date =
        partnership.completionDate === undefined || partnership.completionDate == null
            ? partnership.completionDate
            : partnership.completionDate.toISOString().split("T")[0];
    forApi.exchange_date =
        partnership.exchangeDate === undefined || partnership.exchangeDate == null
            ? partnership.exchangeDate
            : partnership.exchangeDate.toISOString().split("T")[0];
    forApi.initial_rent_pcm =
        partnership.initialRentPcm === undefined || partnership.initialRentPcm == null
            ? partnership.initialRentPcm
            : poundsToPence(partnership.initialRentPcm);
    forApi.home_insurance_costs =
        partnership.homeInsuranceCosts === undefined || partnership.homeInsuranceCosts == null
            ? partnership.homeInsuranceCosts
            : poundsToPence(partnership.homeInsuranceCosts);
    forApi.reinstatement_value =
        partnership.reinstatementValue === undefined || partnership.reinstatementValue == null
            ? partnership.reinstatementValue
            : poundsToPence(partnership.reinstatementValue);
    forApi.wayhome_management_fee_percentage =
        partnership.wayhomeManagementFeePercentage === undefined || partnership.wayhomeManagementFeePercentage == null
            ? partnership.wayhomeManagementFeePercentage
            : percentageToDecimal(partnership.wayhomeManagementFeePercentage);

    if (partnership.hasOwnProperty("customerPartnerOne")) {
        forApi.customer_partner_1 = partnership.customerPartnerOne
            ? mapCustomerPartnerForApi(partnership.customerPartnerOne)
            : null;
    }

    if (partnership.hasOwnProperty("customerPartnerTwo")) {
        forApi.customer_partner_2 = partnership.customerPartnerTwo
            ? mapCustomerPartnerForApi(partnership.customerPartnerTwo)
            : null;
    }

    forApi.in_arrears = partnership.inArrears;
    forApi.rent_direct_debit_mandate_id = partnership.rentDirectDebitMandateId;
    forApi.modulr_customer_id = partnership.modulrCustomerId;
    forApi.modulr_account_id = partnership.modulrAccountId;

    forApi.date_of_incorporation =
        partnership.dateOfIncorporation === undefined || partnership.dateOfIncorporation == null
            ? partnership.dateOfIncorporation
            : partnership.dateOfIncorporation.toISOString().split("T")[0];
    forApi.purchase_property_address = partnership.purchasePropertyAddress;
    forApi.early_buyout_year = partnership.earlyBuyoutYear;

    forApi.occupants = partnership.occupants;
    forApi.attachment3_content = partnership.attachment3Content;

    forApi.match_id = partnership.matchId;
    forApi.solicitor_id = partnership.solicitorId;

    return Object.keys(forApi).reduce((accumulator, key) => {
        if (forApi[key] !== undefined) accumulator[key] = forApi[key];
        return accumulator;
    }, {});
};

export const mapTaskForApi = (task: IPartialTask): ITaskForAPI => ({
    name: task.name,
    display_name: task.displayName,
    status: task.status,
    task_type: task.taskType,
});

export const mapTasksFromApi = (fromApi: ITaskFromAPI[]): ITask[] =>
    fromApi.map((task) => ({
        id: task.id,
        partnershipId: task.partnership_id,
        createdAt: task.created_at,
        updatedAt: task.updated_at,
        completedAt: task.completed_at,
        name: task.name,
        displayName: task.display_name,
        status: task.status,
        taskType: task.task_type,
    }));

export const diffPartnershipsForUpdate = (
    original: IPartnership,
    updated: IPartialPartnership,
): IPartialPartnership => {
    return diffChanges(original, updated);
};

export const getPartnership = async (id: string, authToken?: string | null): Promise<IPartnership> => {
    const { body, ok, status } = await get(`${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}`, authToken);
    if (ok) {
        return mapPartnershipFromApi(body as IPartnershipFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get partnership", status: status }));
    }
};

export const getAllPartnerships = async (
    authToken?: string | null,
): Promise<{ id: string; name: string; displayName: string | null }[]> => {
    const { body, ok, status } = await get(`${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships`, authToken);

    if (ok) {
        const partnerships = body.results || [];
        return partnerships.map((partnership: IPartnershipFromApi) => ({
            id: partnership.id,
            name: partnership.name,
            displayName: partnership.display_name,
        }));
    } else {
        const errorMessage = JSON.stringify({ error: "failed to get all partnerships", status: status });
        throw new Error(errorMessage);
    }
};

export const patchPartnership = async (
    id: string,
    data: IPartialPartnership,
    authToken?: string | null,
): Promise<IPartnership> => {
    const forApi = mapPartnershipForApi(data);

    const { body, ok, status } = await patch(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}`,
        forApi,
        authToken,
    );

    if (ok) {
        return mapPartnershipFromApi(body as IPartnershipFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to patch partnership", status: status }));
    }
};

export const postPartnership = async (data: IPartialPartnership, authToken?: string | null): Promise<IPartnership> => {
    const forApi = mapPartnershipForApi(data);

    const { ok, body, status } = await post(`${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships`, forApi, authToken);

    if (ok) {
        return mapPartnershipFromApi(body as IPartnershipFromApi);
    } else {
        const errorMessage = JSON.stringify({ error: "failed to post new partnership", status: status });
        throw new Error(errorMessage);
    }
};

export const postLivingAccess = async (id: string, authToken?: string | null): Promise<boolean> => {
    const { ok, status } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/customer-access`,
        {},
        authToken,
    );
    if (ok) {
        return ok;
    } else {
        const errorMessage = JSON.stringify({
            error: "failed to post partnership to get living access",
            status: status,
        });
        throw new Error(errorMessage);
    }
};

export const getLivingAccess = async (id: string, authToken?: string | null): Promise<boolean> => {
    const { ok, status, body } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/customer-access`,
        authToken,
    );
    if (ok) {
        return body.has_access;
    } else {
        throw new Error(JSON.stringify({ error: "failed to get living access", status: status }));
    }
};

export const getTasksForPartnership = async (id: string, authToken?: string | null): Promise<ITask[] | null> => {
    const { ok, status, body } = await get(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/tasks`,
        authToken,
    );

    if (ok && body.length === 0) {
        return null;
    } else if (ok) {
        return mapTasksFromApi(body as ITaskFromAPI[]);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get tasks", status: status }));
    }
};

export const postTaskForPartnership = async (
    id: string,
    data: IPartialTask,
    authToken?: string | null,
): Promise<ITask[]> => {
    const forApi = mapTaskForApi(data);
    const { ok, body, status } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/tasks`,
        forApi,
        authToken,
    );
    if (ok) {
        return mapTasksFromApi([body] as ITaskFromAPI[]);
    } else {
        const errorMessage = JSON.stringify({ error: "failed to post new task", status: status });
        throw new Error(errorMessage);
    }
};

export const updateTaskForPartnership = async (
    id: string,
    taskId: string,
    data: IPartialTask,
    authToken?: string | null,
): Promise<ITask[]> => {
    const forApi = mapTaskForApi(data);
    const { ok, body, status } = await patch(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/tasks/${taskId}`,
        forApi,
        authToken,
    );
    if (ok) {
        return mapTasksFromApi([body] as ITaskFromAPI[]);
    } else {
        const errorMessage = JSON.stringify({ error: "failed to update task", status: status });
        throw new Error(errorMessage);
    }
};
