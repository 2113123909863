import React, { ChangeEvent, useMemo, useRef } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled, { css } from "styled-components";

import { DocumentUploadCard } from "components/document-upload-card";
import { ANNUAL_ACCOUNTS, LLP_TAX_RETURNS } from "constants/documents";
import { THeading, useDocuments } from "hooks";
import { IPartnership } from "types";
import {
    IDocumentUpload,
    IPackDocument,
    TDocumentType,
    getIdForEntityType,
    queryObjectToString,
} from "utils/document-api/document-api";

export interface IProps extends IBaseComponent {
    partnership: IPartnership;
    uploads: IDocumentUpload[] | null;
    onRefresh: () => Promise<void>;
    applicationId: string;
    listingId: string;
}

const deleteNotificationId = `delete-${Date.now().toString()}`;
const uploadNotificationId = `upload-${Date.now().toString()}`;

export const DocumentsTab = ({ partnership, uploads, onRefresh, listingId, applicationId }: IProps) => {
    const { token } = useAuth();
    const { addAutoDismissNotification, removeNotification } = useSystemNotification();
    const { getDocumentTypeList, getDocSubHeading } = useDocuments();

    const documentTypeParentList = useRef(getDocumentTypeList()).current;

    const handleUpload = async (
        { target: { files } }: ChangeEvent<HTMLInputElement>,
        doc: IPackDocument,
        setLoading: (val: boolean) => void,
    ) => {
        try {
            if (!files) return;
            setLoading(true);

            let nextIndex;

            for (const file of Array.from(files)) {
                const formData = new FormData();
                formData.append("file", file);

                let fileName = `${doc.name}`;

                const canUploadMore = [ANNUAL_ACCOUNTS, LLP_TAX_RETURNS].some((docName) => doc.name.includes(docName));

                if (canUploadMore) {
                    const yearExtract = file.name.replace(/[^0-9]/gim, "");
                    if (!yearExtract) {
                        if (nextIndex) {
                            nextIndex += 1;
                        } else {
                            nextIndex = uploads
                                ?.filter((d) => d.documentType.includes(doc.name))
                                .reduce((acc, cv) => {
                                    const num = +cv.documentType.replace(`${doc.name}_`, "");
                                    return acc > num ? acc : num;
                                }, 0);

                            nextIndex = nextIndex ? nextIndex + 1 : 1;
                        }
                    } else {
                        nextIndex = +yearExtract;
                    }

                    fileName = `${doc.name}_${nextIndex}`;
                }

                const matchId = partnership.matchId as string;

                const { entityType } = doc;

                const entityId = getIdForEntityType({
                    entityType,
                    matchId,
                    applicationId,
                    listingId,
                });

                const query = queryObjectToString({
                    match_id: matchId,
                    entity_id: entityId,
                    entity_type: entityType,
                    document_type: fileName,
                });

                const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/v1/documents-collection/documents?${query}`;

                const response = await fetch(requestUrl, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                    method: "POST",
                    body: formData,
                });

                removeNotification(uploadNotificationId);

                if (!response.ok) {
                    addAutoDismissNotification({
                        id: uploadNotificationId,
                        type: "error",
                        message: `Failed to upload the "${fileName}" file`,
                    });
                } else {
                    await onRefresh();

                    addAutoDismissNotification({
                        id: uploadNotificationId,
                        type: "success",
                        message: `Uploaded the "${fileName}" file successfully`,
                    });
                }
            }
        } catch (error) {
            captureException(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (uploadedDoc: IDocumentUpload, setDeleting: (v: boolean) => void) => {
        try {
            removeNotification(deleteNotificationId);
            setDeleting(true);
            const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/v1/documents-collection/documents/${uploadedDoc.id}`;
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Token ${token}`,
                },
                method: "DELETE",
            });

            if (!response.ok) {
                addAutoDismissNotification({
                    id: deleteNotificationId,
                    type: "error",
                    message: `Failed to delete ${uploadedDoc.documentType}`,
                });
                return;
            }

            await onRefresh();

            addAutoDismissNotification({
                id: deleteNotificationId,
                type: "success",
                message: `Deleted "${uploadedDoc.documentType}" successfully`,
            });
        } catch (error) {
            captureException(error);
        } finally {
            setDeleting(false);
        }
    };

    const uploadDictionary = useMemo(() => {
        return uploads?.reduce((acc, cv) => {
            let docName = cv.documentType;
            docName = docName.includes(ANNUAL_ACCOUNTS) ? ANNUAL_ACCOUNTS : docName;
            docName = docName.includes(LLP_TAX_RETURNS) ? LLP_TAX_RETURNS : docName;
            return { ...acc, [docName]: acc[docName] ? [...acc[docName], cv] : [cv] };
        }, {});
    }, [uploads]) as Record<TDocumentType, IDocumentUpload[]>;

    return (
        <>
            {documentTypeParentList.map(([key, documentList]) => {
                const heading = getDocSubHeading(key as THeading);

                return (
                    <React.Fragment key={key}>
                        {heading && <SubHeading>{heading}</SubHeading>}
                        <DocumentGrid>
                            {documentList.map((doc) => (
                                <DocumentUploadCard
                                    key={doc.displayName}
                                    document={doc}
                                    uploadedDocs={uploadDictionary?.[doc.name]}
                                    onChangeUpload={handleUpload}
                                    onClickRemove={handleDelete}
                                />
                            ))}
                        </DocumentGrid>
                    </React.Fragment>
                );
            })}
        </>
    );
};

const SubHeading = styled.h5`
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0;
    margin: ${({ theme }) =>
            css`
                ${theme.spacing16}
            `}
        0px;
    padding: ${({ theme }) =>
        css`
            ${theme.spacing8} 0 ${theme.spacing8} ${theme.spacing16}
        `};
    background-color: ${({ theme }) => theme.neutral50};
    color: ${({ theme }) => theme.neutral600};
`;

const DocumentGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    border-top: 2px solid ${({ theme }) => theme.neutral25};
    border-bottom: 2px solid ${({ theme }) => theme.neutral25};

    > div {
        border-bottom: 2px solid ${({ theme }) => theme.neutral25};
        border-right: 2px solid ${({ theme }) => theme.neutral25};
    }

    > div:nth-child(odd) {
        border-right: none;
    }

    > div:nth-child(even) {
        border-right: none;
    }

    > div:last-child {
        border-bottom: none;
    }

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        grid-template-columns: 1fr 1fr;

        > div:nth-child(odd) {
            border-right: 2px solid ${({ theme }) => theme.neutral25};
        }

        > div:nth-last-of-type(-n + 2):not(:nth-child(even)) {
            border-bottom: none;
        }
    }
`;
