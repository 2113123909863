import React, { FC } from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { isFieldValid } from "@wayhome-uk/utils";
import { useField } from "formik";
import styled from "styled-components";

import { ErrorMessage, Input, Label } from "components/form/form-atoms";

export interface IPasswordField extends IBaseComponent {
    id: string;
    label: string;
    name: string;
}

export const Field: FC<IPasswordField> = ({ className, id, label, name }) => {
    const [field, meta, { setValue }] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        if (input === "") {
            setValue(null);
        } else {
            setValue(input);
        }
    };

    return (
        <div className={className}>
            <Label htmlFor={id}>{label}</Label>
            <VerticalSpacing size={8} />
            <Input
                {...field}
                id={id}
                inputSize="medium"
                type="password"
                isValid={isFieldValid(meta.error, meta.touched)}
                value={field.value ?? ""}
                onChange={handleChange}
            />
            {meta.touched && meta.error && (
                <ErrorMessage>
                    <span>{meta.error}</span>
                </ErrorMessage>
            )}
        </div>
    );
};

export const PasswordField = styled(Field)``;
