import React from "react";

import { Grid, GridItem, LongParagraphCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components";

export interface IBlockedInfoProps extends IBaseComponent {
    warning?: string;
    explanation?: string;
}

export const BlockedInfo: React.FC<IBlockedInfoProps> = ({ className, warning, explanation }) => (
    <div className={className}>
        <Grid>
            <GridItem item={true} sm={12} md={8} lg={6}>
                <WarningText>{warning}</WarningText>
                <VerticalSpacing size={16} />
                <ExplanationText>{explanation}</ExplanationText>
            </GridItem>
        </Grid>
    </div>
);

const Text = styled.p`
    ${LongParagraphCss};
    max-width: unset;
    font-weight: 700;
`;

const WarningText = styled(Text)`
    color: ${({ theme }) => theme.red800};
`;

const ExplanationText = styled(Text)`
    color: ${({ theme }) => theme.neutral800};
`;
