import React from "react";

import { ProtectedRoute } from "@wayhome-uk/components";
import { LoadingContainer } from "@wayhome-uk/design-system";
import { useAuth } from "@wayhome-uk/utils";
import { Route, Switch } from "react-router-dom";

import { routes } from "config/routes";
import { AllPartnershipsPage } from "pages/all-partnerships";
import { CreateNewPartnership } from "pages/create-new-partnership";
import { Login } from "pages/login";
import { NewPartnership } from "pages/new-partnership";
import { PartnershipPage } from "pages/partnership";

export const Routes = () => {
    const { authStatus, isLoggedIn } = useAuth();

    return (
        <LoadingContainer isLoading={authStatus !== "ready"}>
            <Switch>
                <ProtectedRoute
                    path={routes.newPartnership.path}
                    isLoggedIn={isLoggedIn}
                    redirectRoute={routes.login.path}
                    exact={true}
                    component={NewPartnership}
                />

                <ProtectedRoute
                    path={routes.createNewPartnership.path}
                    isLoggedIn={isLoggedIn}
                    redirectRoute={routes.login.path}
                    component={CreateNewPartnership}
                />

                <ProtectedRoute
                    path={routes.partnership.path}
                    isLoggedIn={isLoggedIn}
                    redirectRoute={routes.login.path}
                    component={PartnershipPage}
                />

                <ProtectedRoute
                    path={routes.allPartnerships.path}
                    isLoggedIn={isLoggedIn}
                    redirectRoute={routes.login.path}
                    exact={true}
                    component={AllPartnershipsPage}
                />

                <ProtectedRoute
                    path={routes.livingAdmin.path}
                    isLoggedIn={isLoggedIn}
                    redirectRoute={routes.login.path}
                    exact={true}
                    component={AllPartnershipsPage}
                />

                <Route path={routes.login.path} exact={true} component={Login} />
            </Switch>
        </LoadingContainer>
    );
};
