import React, { useState } from "react";

import styled, { css } from "styled-components";

import { formatDate, truncateText } from "utils";
import { IDocumentUpload } from "utils/document-api/document-api";

type RemoveFunc = (uploadedDoc: IDocumentUpload, cb: (loading: boolean) => void) => void;

interface ICardItem {
    uploadedDoc: IDocumentUpload;
    onClickRemove: RemoveFunc;
}

export const CardItem = ({ uploadedDoc, onClickRemove }: ICardItem) => {
    const [deleting, setDeleting] = useState(false);

    const handleDeleting = (loading: boolean) => {
        setDeleting(loading);
    };

    return (
        <DocumentContainer>
            <DocumentNameAndStatus>
                {!!uploadedDoc.bucketUrl && (
                    <Link href={uploadedDoc.bucketUrl} target="_blank">
                        {truncateText(uploadedDoc.documentType, "center")}.pdf
                    </Link>
                )}

                {!!uploadedDoc.bucketUrl && (
                    <RemoveLink
                        as="button"
                        className={deleting ? "deleting" : ""}
                        onClick={deleting ? () => {} : () => onClickRemove(uploadedDoc, handleDeleting)}
                    >
                        {deleting ? "Removing..." : "Remove"}
                    </RemoveLink>
                )}
            </DocumentNameAndStatus>

            {!!uploadedDoc?.uploaded && <SmallText>Uploaded on {formatDate(new Date(uploadedDoc.uploaded))}</SmallText>}
        </DocumentContainer>
    );
};

const SmallText = styled.p`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0;
    margin: 0;
`;

const Link = styled.a`
    color: ${({ theme }) => theme.primary500};
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0;
    margin: 0;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    cursor: pointer;

    :hover {
        color: ${({ theme }) => theme.success500};
    }

    :focus {
        color: ${({ theme }) => theme.primary500};
    }

    :active {
        color: ${({ theme }) => theme.neutral0};
    }
`;

const DocumentContainer = styled.div`
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing16};
    padding: ${({ theme }) => theme.spacing8} 0;
`;

const DocumentNameAndStatus = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing4};
`;

const RemoveLink = styled(Link)<{ className: string }>`
    ${({ className }) =>
        className === "deleting"
            ? css`
                  cursor: not-allowed;
              `
            : ""};
    color: ${({ theme }) => theme.danger500};
    border: none;
    font-size: 1.4rem;
    background: none;
    padding: 0;
    text-decoration: none;
`;
