import { TPropertyTenureType } from "utils/listing-api";

const tenureTypeLabelMap = {
    leasehold: "Leasehold",
    freehold: "Freehold",
    "freehold-with-estate-fee": "Freehold with estate fee",
    "share-of-freehold": "Share of Freehold",
    unknown: "Unknown",
};

export const parseTenureType = (tenureType: TPropertyTenureType) => {
    if (!tenureType) {
        return "Undefined";
    }

    return tenureTypeLabelMap[tenureType];
};
