import { object, string } from "yup";

export const buyerDetailsFormValidationSchema = object().shape({
    applicantId: string().nullable().required("Please fill in the field"),
    firstName: string().nullable().required("Please fill in the field"),
    lastName: string().nullable().required("Please fill in the field"),
    dateOfBirth: string().nullable().required("Please fill in the field"),
    email: string().nullable().required("Please fill in the field"),
    addressLineOne: string().nullable().required("Please fill in the field"),
    postcode: string().nullable().required("Please fill in the field"),
    town: string().nullable().required("Please fill in the field"),
    phone: string().nullable().required("Please fill in the field"),
});
