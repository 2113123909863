export const truncateText = (text: string, type?: "center" | "end") => {
    const isBigScreen = window?.innerWidth >= 1600;

    if (text.length > 26 && type === "center" && !isBigScreen) {
        return `${text.slice(0, 10)}...${text.slice(23)}`;
    }

    if (text.length > 25 && type === "end" && !isBigScreen) {
        return `${text.slice(0, 21)}...`;
    }

    return text;
};
