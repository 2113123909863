import React from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import { object, string } from "yup";

import { ErrorMessage, FormButton } from "components/form/form-atoms";
import { Field } from "components/form/text-field";

export const llpNameValidationSchema = object().shape({
    llpName: string().required("Please enter a LLP name"),
});

export interface ICreateNewPartnershipFormProps extends IBaseComponent {
    initialValues: IFormProps;
    onSubmit?: (name: string) => void;
    apiError?: boolean;
}

export interface IFormProps {
    llpName: string;
}

export const CreateNewPartnershipForm: React.FC<ICreateNewPartnershipFormProps> = ({
    className,
    initialValues,
    onSubmit,
    apiError,
}) => {
    const handleSubmitForm = (values: IFormProps) => {
        if (onSubmit) {
            onSubmit(values.llpName);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleSubmitForm}
            validationSchema={llpNameValidationSchema}
        >
            {({ handleSubmit }) => {
                return (
                    <form
                        className={className}
                        data-testid="create-new-partnership-form"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate={true}
                    >
                        <Field id="llpName" label="LLP Name" name="llpName" />
                        {apiError ? <ErrorMessage>Creation failed: API error</ErrorMessage> : null}
                        <VerticalSpacing size={32} />
                        <FormButton data-testid="new-partnership-form-submit-button" type="submit">
                            Create LLP
                        </FormButton>
                    </form>
                );
            }}
        </Formik>
    );
};
