import { IPartnership, IPurchaseSchedule } from "types";
import { TPropertyTenureType } from "utils";
import { TDocumentType, mapPurchaseScheduleForDocumentApi } from "utils/document-api/document-api";

export const hasAllDocGenFields = (
    partnership: IPartnership,
    purchaseSchedule: IPurchaseSchedule,
    propertyTenureType: TPropertyTenureType,
) => {
    const {
        initialRentPcm,
        earlyBuyoutYear,
        dateOfIncorporation,
        purchasePropertyAddress,
        companyRegistrationNumber,
        customerPartnerOne,
    } = partnership;

    const partnershipHasNullFields = [
        initialRentPcm,
        earlyBuyoutYear,
        dateOfIncorporation,
        purchasePropertyAddress,
        companyRegistrationNumber,
        customerPartnerOne,
    ].some((val) => val === null);

    if (partnershipHasNullFields) {
        return false;
    }

    const purchaseScheduleForDocGen = mapPurchaseScheduleForDocumentApi(purchaseSchedule, propertyTenureType);
    if (Object.values(purchaseScheduleForDocGen).some((val) => val === null)) {
        return false;
    }

    return true;
};

export const documentTypeMapper = (documentType: TDocumentType) => {
    switch (documentType) {
        case "assured_shorthold_tenancy_agreement":
            return "AST";
        case "partnership_agreement":
            return "PA";
        case "power_of_attorney":
            return "PoA";
        case "digital_property_pack":
            return "DPP";
        case "magenta_building_insurance":
            return "MBI";
        case "arag_emergency_home_insurance":
            return "AEHI";
        default:
            return null;
    }
};
