import React, { useState } from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";
import { object, string } from "yup";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { FormButton } from "components/form/form-atoms";
import { TextField } from "components/form/text-field";
import { IconChecked } from "components/media/icons/icon-checked";

const detailsFormValidationSchema = object().shape({
    name: string().required("Please enter an LLP name"),
    companyRegistrationNumber: string().nullable(),
    displayName: string().nullable(),
    inArrears: string().nullable(),
    matchId: string().nullable(),
    wayhomeManagementFeePercentage: string().required("Please enter wayhome management fee percentage"),
});

export interface IDetailsFormValues {
    name: string | null;
    companyRegistrationNumber: string | null;
    partnershipUniqueTaxReference: string | null;
    displayName: string | null;
    inArrears: boolean | null;
    wayhomeManagementFeePercentage: number | null;
    matchId: string | null;
}

export interface IDetailsFormProps extends IBaseComponent {
    initialValues: IDetailsFormValues;
    onSubmit: (values: IDetailsFormValues) => Promise<void>;
    disableLlpFields?: boolean;
}

export const DetailsForm: React.FC<IDetailsFormProps> = ({
    className,
    initialValues,
    onSubmit,
    disableLlpFields = false,
}) => {
    const [inArrears, setInArrears] = useState<boolean>(Boolean(initialValues.inArrears));

    const handleSubmitForm = async (values: IDetailsFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={detailsFormValidationSchema}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit, setFieldValue }) => (
                <form
                    className={className}
                    data-testid="details-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                >
                    <Heading>LLP Details</Heading>
                    <VerticalSpacing size={32} />

                    <ConditionalReadOnlyField isReadOnly={disableLlpFields}>
                        <TextField id="llp-name" name="name" label="LLP Name (required)" disabled={disableLlpFields} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disableLlpFields}>
                        <TextField id="match-id" name="matchId" label="Match ID" disabled={disableLlpFields} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={disableLlpFields}>
                        <TextField
                            id="company-registration-number"
                            name="companyRegistrationNumber"
                            label="Company Registration Number"
                            disabled={disableLlpFields}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <TextField
                        id="partnership-unique-tax-reference"
                        name="partnershipUniqueTaxReference"
                        label="Partnership Unique Tax Reference"
                    />
                    <VerticalSpacing size={16} />

                    <TextField id="display-name" name="displayName" label="Display Name" />
                    <VerticalSpacing size={24} />

                    <TextField
                        id="wayhome-management-fee-percentage"
                        name="wayhomeManagementFeePercentage"
                        label="Wayhome Management Fee Percentage"
                        explanation="e.g. enter 0.15 for a 0.15% fee"
                    />
                    <VerticalSpacing size={24} />

                    <InArrearsContainer inArrears={inArrears}>
                        <CheckboxWrapper>
                            <StyledIconChecked inArrears={inArrears} />
                            <HiddenCheckbox
                                id="in-arrears"
                                type="checkbox"
                                name="inArrears"
                                data-testid="is-in-arrears"
                                onChange={() => {
                                    setFieldValue("inArrears", !inArrears);
                                    setInArrears(!inArrears);
                                }}
                                checked={inArrears}
                            />
                        </CheckboxWrapper>
                        <InArrearsText htmlFor="in-arrears">In arrears</InArrearsText>
                    </InArrearsContainer>

                    <VerticalSpacing size={32} />
                    <FormButton type="submit">Save</FormButton>
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const InArrearsContainer = styled.div<{ inArrears?: boolean }>`
    display: inline-block;
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    padding-left: 0;
    position: relative;
`;

const StyledIconChecked = styled(IconChecked)<{ inArrears?: boolean }>`
    cursor: pointer;
    visibility: ${({ inArrears }) => (inArrears ? "visible" : "hidden")};
    position: absolute;
    left: -1px;
    top: -5px;

    svg {
        height: 25px;
        width: 25px;
    }
`;

const CheckboxWrapper = styled.span`
    position: relative;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: ${({ theme }) => theme.radius1};
    border: ${({ theme }) => `2px solid ${theme.primary200}`};
    left: -5px;
    top: -8px;
    cursor: pointer;
`;

const InArrearsText = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-left: 32px;
    cursor: pointer;
`;
