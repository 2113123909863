import React, { useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { Button, ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { Label } from "components/form/form-atoms";
import { FormWrapper } from "components/form/form-wrapper";
import { IActivationPlan, IPartnership } from "types";
import { getActivationPlan, postActivate, postSendForApproval } from "utils/activation-api/activation-api";
import { formatNumberAsCurrency } from "utils/formatters/currency";
import { isPartnershipIsReadyForActivation } from "utils/shared-functions/partnership-helpers";

import { ApprovalForm } from "./approval-form";

const DisplayField = ({ label, value }: { label: string; value: string }) => (
    <>
        <Label as="div">{label}</Label>
        <VerticalSpacing size={8} />
        <DisplayFieldData>{value}</DisplayFieldData>
    </>
);

const DisplayFieldData = styled.div`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: 700;
`;

const CurrencyDisplayField = ({ label, value }: { label: string; value: number }) => (
    <DisplayField label={label} value={`£${formatNumberAsCurrency(value)}`} />
);

const DateDisplayField = ({ label, value }: { label: string; value: Date }) => (
    <DisplayField label={label} value={value.toLocaleDateString()} />
);

export interface ILedgerTabProps extends IBaseComponent {
    partnership: IPartnership;
    updatePartnership: () => void;
}

export const LedgerTab: React.FC<ILedgerTabProps> = ({ partnership, updatePartnership }) => {
    const { token } = useAuth();

    const { addAutoDismissNotification } = useSystemNotification();
    const [approvalFormError, setApprovalFormError] = useState<string | null>(null);
    const [activationPlan, setActivationPlan] = useState<IActivationPlan>();

    useEffect(() => {
        if (partnership.status !== "active" && isPartnershipIsReadyForActivation(partnership) && token) {
            (async () => {
                try {
                    const activationPlan = await getActivationPlan(partnership.id, token);
                    setActivationPlan(activationPlan);
                } catch (error) {
                    captureException(error);
                }
            })();
        }
    }, [partnership, token]);

    const handleSendForApprovalClick = async () => {
        try {
            await postSendForApproval(partnership.id, token);
            addAutoDismissNotification({
                id: "send-for-approval",
                type: "success",
                message: "Ledger and DD collection sent for approval",
            });
            updatePartnership();
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: "send-for-approval",
                type: "error",
                message: "Action failed: API error",
            });
        }
    };

    const handleApprovalFormSubmit = async (password: string) => {
        let shouldUpdatePartnership = false;

        try {
            setApprovalFormError(null);
            await postActivate(partnership.id, password, token);
            shouldUpdatePartnership = true;
        } catch (error) {
            captureException(error);
            setApprovalFormError("Invalid password");
        }

        if (shouldUpdatePartnership) {
            addAutoDismissNotification({
                id: "ledger-initiated",
                type: "success",
                message: "Ledger and DD collection initiated",
            });
            updatePartnership();
        } else {
            addAutoDismissNotification({
                id: "ledger-initiated",
                type: "error",
                message: "Action failed: API error",
            });
        }
    };

    if (partnership.status === "active") {
        return (
            <>
                <Heading>Partnership Ledger &amp; Mandate</Heading>
                <VerticalSpacing size={32} />
                <NotActionableText>Partnership is active.</NotActionableText>
            </>
        );
    }

    if (!isPartnershipIsReadyForActivation(partnership)) {
        return (
            <>
                <Heading>Partnership Ledger &amp; Mandate</Heading>
                <VerticalSpacing size={32} />
                <NotActionableText>
                    Rent PCM, Modulr customer and Modulr account are required before the ledger and mandate can be
                    activated.
                </NotActionableText>
            </>
        );
    }

    if (activationPlan) {
        const action =
            partnership.status === "in-setup" ? (
                <Button colorScheme="primary" onClick={handleSendForApprovalClick}>
                    Send For Approval
                </Button>
            ) : (
                <>
                    <Separator />
                    <VerticalSpacing size={24} />
                    <FormWrapper>
                        <ApprovalForm onSubmit={handleApprovalFormSubmit} error={approvalFormError} />
                    </FormWrapper>
                </>
            );

        return (
            <>
                <Heading>Partnership Ledger &amp; Mandate</Heading>
                <VerticalSpacing size={32} />
                <CurrencyDisplayField label="Rent" value={activationPlan.initialRentPcm} />
                <VerticalSpacing size={16} />
                <DisplayField label="Ownership" value={`${activationPlan.customerOwnershipPercentage}%`} />
                <VerticalSpacing size={16} />
                <CurrencyDisplayField label="Fees" value={activationPlan.customerFees} />

                <VerticalSpacing size={24} />
                <Separator />
                <VerticalSpacing size={24} />

                <Heading>First Direct Debit Collection Details</Heading>
                <VerticalSpacing size={32} />

                <VerticalSpacing size={16} />

                <CurrencyDisplayField label="Payment Amount" value={activationPlan.firstRentPaymentAmount} />

                <VerticalSpacing size={16} />

                <DateDisplayField label="Earliest Collection Date" value={activationPlan.firstRentPaymentDate} />

                <VerticalSpacing size={32} />
                {action}
            </>
        );
    }

    return null;
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const Separator = styled.div`
    background: ${({ theme }) => theme.neutral75};
    height: 1px;
`;

const NotActionableText = styled.p`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.red800};
    font-weight: 700;
`;
