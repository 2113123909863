import React, { FC, useState } from "react";

import { CurrencyWrapper, VerticalSpacing } from "@wayhome-uk/design-system";
import { isFieldValid } from "@wayhome-uk/utils";
import { useField } from "formik";
import styled from "styled-components";

import { ErrorMessage, Input, Label } from "components/form/form-atoms";
import { formatNumberAsCurrency } from "utils/formatters/currency";

const parseCurrency = (value: string): number => {
    const cleaned = value.replace(/(?<!^)-|[^\d.-]/g, "");
    const matched = cleaned.match(/(?<pounds>-?\d*)?(?<pennies>.\d{1,2})?/);

    if (!matched || matched[0] === "") throw new Error("Value can't be parsed to a number");

    const pounds = parseInt(matched.groups?.pounds || "0");
    const pennies = parseFloat(matched.groups?.pennies || "0");

    return pounds + pennies;
};

export interface ICurrencyField {
    id: string;
    label: string;
    name: string;
    disabled?: boolean;
}

export const CurrencyField: FC<ICurrencyField> = ({ id, label, name, disabled = false }) => {
    const [field, meta, { setValue, setTouched }] = useField(name);
    const [displayValue, setDisplayValue] = useState(
        typeof field.value === "number" ? formatNumberAsCurrency(field.value) : "",
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        if (input === "" || input === "-") {
            setValue(null);
        } else {
            try {
                const numericValue = parseCurrency(input);
                setValue(numericValue);
            } catch (e) {}
        }

        setDisplayValue(input);
    };

    const handleBlur = () => {
        try {
            parseCurrency(displayValue);
            setDisplayValue(formatNumberAsCurrency(field.value));
        } catch (e) {
            setValue(null);
            setDisplayValue("");
        }
        setTouched(true);
    };

    return (
        <>
            <Label htmlFor={id}>{label}</Label>
            <VerticalSpacing size={8} />
            <StyledCurrencyWrapper>
                <Input
                    {...field}
                    id={id}
                    inputSize="medium"
                    type="text"
                    isValid={isFieldValid(meta.error, meta.touched)}
                    value={displayValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                />
            </StyledCurrencyWrapper>
            {meta.touched && meta.error && (
                <ErrorMessage>
                    <span>{meta.error}</span>
                </ErrorMessage>
            )}
        </>
    );
};

const StyledCurrencyWrapper = styled(CurrencyWrapper)`
    display: flex;

    ::before {
        font-size: 1rem;
    }
`;

export const StyledCurrencyField = styled(CurrencyField)``;
