import React from "react";

import { Button, LoadingIndicator, LongLead, VerticalSpacing } from "@wayhome-uk/design-system";
import styled from "styled-components";

export type TLivingStatus = "in progress" | "created" | "error" | "not created" | null;

export interface ILivingBanner {
    handleOnClick: () => void;
    status: TLivingStatus;
    displayName?: string | null;
}

interface IAccountBanner {
    created?: boolean;
}

const Tick = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6666 5.5L7.49992 14.6667L3.33325 10.5"
            stroke="#025A65"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const LivingBanner = ({ handleOnClick, status, displayName }: ILivingBanner) => {
    if (status === "created") {
        return (
            <LivingAccountBanner created>
                <CreatedContainer>
                    <Tick /> <BannerText>A living account has been created</BannerText>
                </CreatedContainer>
            </LivingAccountBanner>
        );
    }
    return (
        <LivingAccountBanner>
            <BannerText>Great news, {displayName} are ready for a Living account! 🎉</BannerText>
            <VerticalSpacing size={16} />
            {status === "error" ? (
                <BannerTextError>
                    There was a problem with creating the Living account. Please try again.
                </BannerTextError>
            ) : null}

            {status === "in progress" ? (
                <LoadingContainer>
                    <StyledLoadingIndicator />
                    <BannerText>Creating...</BannerText>
                </LoadingContainer>
            ) : (
                <BannerButton onClick={() => handleOnClick()}>Set up a Living account</BannerButton>
            )}
        </LivingAccountBanner>
    );
};

const LivingAccountBanner = styled.aside<IAccountBanner>`
    background-color: ${({ created }) => (created ? "#DDF1E7" : ({ theme }) => theme.action50)};
    width: 100%;
    padding: ${({ theme }) => theme.spacing16};
    margin-bottom: ${({ theme }) => theme.spacing24};
`;

const CreatedContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${({ theme }) => theme.spacing8};
    svg {
        transform: translateY(-1px);
    }
`;

const BannerText = styled(LongLead)`
    font-size: ${({ theme }) => theme.type18};
    font-weight: 700;
`;

const BannerTextError = styled(BannerText)`
    font-weight: 500;
    border-left: 4px solid ${({ theme }) => theme.red800};
    padding-left: ${({ theme }) => theme.spacing12};
    margin-bottom: ${({ theme }) => theme.spacing16};
`;

const BannerButton = styled(Button)`
    font-size: ${({ theme }) => theme.type18};
    line-height: 1.6;
    padding: ${({ theme }) => `${theme.spacing8} ${theme.spacing16}`};
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
    padding: 0;
    margin: ${({ theme }) => `0 ${theme.spacing12} 0 4.75rem`};
    svg {
        margin: 0;
    }
`;
