import { boolean, object, string } from "yup";

export const directDebitValidationSchema = () => {
    return object().shape({
        sortCode: string()
            .nullable()
            .length(6, "Sort code must have 6 numbers")
            .matches(/^[0-9]+$/, "Sort code must contain only numbers")
            .required("This field is required"),
        name: string().nullable().required("This field is required"),
        accountNumber: string()
            .nullable()
            .length(8, "Account number must have 8 numbers")
            .matches(/^[0-9]+$/i, "Account number must contain only numbers")
            .required("This field is required"),
        phoneNumber: string()
            .nullable()
            .matches(/^[0-9]+$/i, "Phone number must contain only numbers")
            .required("This field is required"),
        addressLineOne: string().nullable().required("This field is required"),
        addressLineTwo: string().nullable(),
        postcode: string().nullable().required("This field is required"),
        town: string().nullable().required("This field is required"),
        termsAndConditions: boolean()
            .nullable()
            .oneOf([true], "The customer must accept our terms and conditions")
            .required("This field is required"),
    });
};
