import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { LoadingIndicator } from "@wayhome-uk/design-system";
import { useAuth } from "@wayhome-uk/utils";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { ContentWrapperWithTabNav } from "components/content-wrapper";
import { Chevron } from "components/icons";
import { PageLayout } from "components/page-layout";
import { CarouselButton, CarouselWrapper, Tab, TabsWrapper } from "components/tab";
import { useThrottle } from "hooks";
import { LedgerTab } from "pages/partnership/partnership-tabs/ledger";
import { OneOffPaymentTab } from "pages/partnership/partnership-tabs/one-off-payment";
import { IPartialPartnership, IPartialTask, IPartnership, ITask } from "types";
import { getMatch } from "utils";
import { IDocumentUpload, getDocumentsForMatch, queryObjectToString } from "utils/document-api/document-api";
import {
    diffPartnershipsForUpdate,
    getLivingAccess,
    getPartnership,
    getTasksForPartnership,
    patchPartnership,
    postLivingAccess,
    postTaskForPartnership,
    updateTaskForPartnership,
} from "utils/partnership-api/partnerships-api";

import { LivingBanner, TLivingStatus } from "./living-banner";
import { CustomerView } from "./partnership-tabs/customer-view";
import { DirectDebitTab } from "./partnership-tabs/direct-debit";
import { DocumentsTab } from "./partnership-tabs/documents";
import { FinancialsTab } from "./partnership-tabs/financials";
import { ModulrTab } from "./partnership-tabs/modulr";
import { OverviewTab } from "./partnership-tabs/overview";
import { TasksTab } from "./partnership-tabs/tasks";
import { TaskDetailPage } from "./partnership-tabs/tasks/task-detail";

export const PartnershipPage: React.FC = () => {
    const { token } = useAuth();
    const { partnershipID } = useParams<{ partnershipID: string }>();
    const { url, path } = useRouteMatch();
    const { addAutoDismissNotification } = useSystemNotification();
    const { throttle } = useThrottle({ delay: 200 });

    const [partnershipData, setPartnership] = useState<IPartnership>();
    const [livingStatus, setLivingStatus] = useState<TLivingStatus>(null);
    const [tasks, setTasks] = useState<ITask[] | null>(null);
    const [scrollPosition, setScrollPosition] = useState<{ left: number; right: number }>({ left: 0, right: 0 });
    const [documentsFromApi, setDocumentsFromApi] = useState<IDocumentUpload[] | null>(null);
    const [loadingDocs, setLoadingDocs] = useState<boolean>(true);
    const [relevantIds, setRelevantIds] = useState<{ applicationId: string; listingId: string } | null>(null);

    const tabRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<HTMLDivElement>(null);

    const partnership = useMemo(() => partnershipData, [partnershipData]);

    const getAndSetPartnership = useCallback(async () => {
        if (token && partnershipID) {
            try {
                const partnershipData = await getPartnership(partnershipID, token);
                setPartnership(partnershipData);
            } catch (error) {
                captureException(error);
            }
        }
    }, [partnershipID, token]);

    const savePartnership = async (data: IPartialPartnership) => {
        if (partnership) {
            try {
                const diff = diffPartnershipsForUpdate(partnership, data);
                const result = await patchPartnership(partnershipID, diff, token);
                setPartnership(result);
                addAutoDismissNotification({
                    id: "save-partnership",
                    type: "success",
                    message: "Partnership changes have been saved",
                });
            } catch (error) {
                captureException(error);
                addAutoDismissNotification({
                    id: "save-partnership",
                    type: "error",
                    message: "Saving failed: API error",
                });
            }
        }
    };

    useEffect(() => {
        if (partnership?.matchId) {
            (async () => {
                try {
                    const { applicationId, listingId } = await getMatch(partnership.matchId as string, token);
                    setRelevantIds({ applicationId, listingId });
                } catch (error) {
                    captureException(error);
                }
            })();
        }
    }, [partnership, token]);

    useEffect(() => {
        (async () => {
            await getAndSetPartnership();
        })();
    }, [getAndSetPartnership]);

    useEffect(() => {
        const isCompletedInPast = () => {
            if (partnership?.completionDate) {
                const today = new Date();
                return today > partnership.completionDate;
            }
            return false;
        };
        (async () => {
            if (isCompletedInPast() && partnership?.status === "active") {
                const response = await getLivingAccess(partnershipID, token);

                if (response) {
                    setLivingStatus("created");
                } else {
                    setLivingStatus("not created");
                }
            }
        })();
    }, [partnership, partnershipID, token]);

    const fetchUploadedDocuments = React.useCallback(async () => {
        const query = queryObjectToString({
            match_id: partnership?.matchId,
            include_entities: "true",
        });

        try {
            const result = await getDocumentsForMatch(query, token);
            setDocumentsFromApi(result);
        } catch (error) {
            captureException(error);
        } finally {
            setLoadingDocs(false);
        }
    }, [token, partnership]);

    useEffect(() => {
        fetchUploadedDocuments();
    }, [token, partnership, fetchUploadedDocuments]);

    const onClickLivingAccess = () => {
        (async () => {
            if (partnershipID) {
                setLivingStatus("in progress");
                const response = await postLivingAccess(partnershipID, token);
                if (response) {
                    setLivingStatus("created");
                } else {
                    setLivingStatus("error");
                }
            }
        })();
    };

    const getAndSetTasks = useCallback(async () => {
        if (token && partnershipID) {
            try {
                const tasksData = await getTasksForPartnership(partnershipID, token);
                setTasks(tasksData);
            } catch (error) {
                captureException(error);
            }
        }
    }, [partnershipID, token]);

    useEffect(() => {
        (async () => {
            await getAndSetTasks();
        })();
    }, [getAndSetTasks]);

    const saveTask = async (tasks: IPartialTask[]) => {
        let success = false;
        for (const task of tasks) {
            try {
                await postTaskForPartnership(partnershipID, task, token);
                success = true;
            } catch (error) {
                captureException(error);
                success = false;
                break;
            }
        }

        if (success) {
            addAutoDismissNotification({
                id: "save-task",
                type: "success",
                message: "Tasks have been saved",
            });
        } else if (!success) {
            addAutoDismissNotification({
                id: "save-task",
                type: "error",
                message: "Saving failed: API error",
            });
        }
    };

    const updateTask = async (taskId: string, data: IPartialTask, getAndSetTasks?: () => void) => {
        try {
            await updateTaskForPartnership(partnershipID, taskId, data, token);
            addAutoDismissNotification({
                id: "update-task",
                type: "success",
                message: "Task has been updated",
            });
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: "update-task",
                type: "error",
                message: "Saving failed: API error",
            });
        }

        if (getAndSetTasks) {
            getAndSetTasks();
        }
    };

    const tabs = [
        { tabPath: url, tabName: "Overview", exact: true },
        { tabPath: `${url}/financials`, tabName: "Financials", exact: true },
        { tabPath: `${url}/direct-debit`, tabName: "Direct Debit", exact: true },
        { tabPath: `${url}/modulr-account`, tabName: "Modulr", exact: true },
        { tabPath: `${url}/ledger`, tabName: "Ledger", exact: true },
        { tabPath: `${url}/one-off-payment`, tabName: "One off payment", exact: false },
        { tabPath: `${url}/customer-tasks`, tabName: "Customer Tasks", exact: false },
        { tabPath: `${url}/documents`, tabName: "Documents", exact: false },
        { tabPath: `${url}/customer-view`, tabName: "Customer View", exact: false },
    ];

    const carouselScrollWidth = tabRef.current?.scrollWidth as number;
    const tabWidth = tabRef.current?.clientWidth as number;

    const showCarouselArrows = carouselScrollWidth > tabWidth;

    const handleScroll = (direction: "left" | "right") => {
        const TAB_WIDTH = 200;

        tabRef.current?.scrollBy({
            left: direction === "left" ? -TAB_WIDTH : TAB_WIDTH,
            behavior: "smooth",
        });

        setScrollPosition(({ left }) => {
            const leftCalc = direction === "left" ? left - TAB_WIDTH : left + TAB_WIDTH;
            return {
                left: leftCalc,
                right: (carouselRef.current?.scrollWidth as number) + leftCalc,
            };
        });
    };

    return (
        <PageLayout>
            {livingStatus ? (
                <LivingBanner
                    displayName={partnership?.displayName}
                    handleOnClick={onClickLivingAccess}
                    status={livingStatus}
                />
            ) : null}

            <CarouselWrapper ref={carouselRef}>
                {showCarouselArrows && scrollPosition.left !== 0 && (
                    <CarouselButton position="left" onClick={() => throttle(() => handleScroll("left"))}>
                        <Chevron direction="left" />
                    </CarouselButton>
                )}

                <TabsWrapper ref={tabRef}>
                    {tabs.map((tab) => (
                        <Tab key={tab.tabName} {...tab} />
                    ))}
                </TabsWrapper>

                {showCarouselArrows && scrollPosition.right < (tabRef.current?.scrollWidth as number) && (
                    <CarouselButton position="right" onClick={() => throttle(() => handleScroll("right"))}>
                        <Chevron direction="right" />
                    </CarouselButton>
                )}
            </CarouselWrapper>

            {partnership ? (
                <ContentWrapperWithTabNav>
                    <Switch>
                        <Route exact={true} path={`${path}`}>
                            <OverviewTab partnership={partnership} savePartnership={savePartnership} />
                        </Route>
                        <Route path={`${path}/financials`}>
                            <FinancialsTab partnership={partnership} savePartnership={savePartnership} />
                        </Route>
                        <Route path={`${path}/direct-debit`}>
                            <DirectDebitTab partnership={partnership} updatePartnership={getAndSetPartnership} />
                        </Route>
                        <Route path={`${path}/modulr-account`}>
                            <ModulrTab partnership={partnership} updatePartnership={getAndSetPartnership} />
                        </Route>
                        <Route path={`${path}/ledger`}>
                            <LedgerTab partnership={partnership} updatePartnership={getAndSetPartnership} />
                        </Route>
                        <Route path={`${path}/one-off-payment`}>
                            <OneOffPaymentTab partnership={partnership} />
                        </Route>
                        <Route path={`${path}/customer-tasks`}>
                            <TasksTab
                                partnership={partnership}
                                tasks={tasks ? tasks : []}
                                getAndSetTasks={getAndSetTasks}
                                saveTask={saveTask}
                            />
                        </Route>
                        <Route path={`${path}/task/:taskID`}>
                            <TaskDetailPage
                                tasks={tasks ? tasks : []}
                                partnership={partnership}
                                getAndSetTasks={getAndSetTasks}
                                updateTask={updateTask}
                            />
                        </Route>
                        <Route path={`${path}/customer-view`}>
                            <CustomerView partnership={partnership} />
                        </Route>
                        <Route path={`${path}/documents`}>
                            {loadingDocs ? (
                                <LoadingIndicator />
                            ) : (
                                relevantIds && (
                                    <DocumentsTab
                                        partnership={partnership}
                                        uploads={documentsFromApi}
                                        onRefresh={fetchUploadedDocuments}
                                        {...relevantIds}
                                    />
                                )
                            )}
                        </Route>
                    </Switch>
                </ContentWrapperWithTabNav>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
};
