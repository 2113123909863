import React from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import { object, string } from "yup";

import { ErrorMessage, FormButton } from "components/form/form-atoms";
import { Field } from "components/form/text-field";

export const matchIDValidationSchema = object().shape({
    matchID: string()
        .required("Please enter a Match ID")
        .min(36, "Match ID must be exactly 36 characters")
        .max(36, "Match ID must be exactly 36 characters")
        .nullable(),
});

export interface INewPartnershipFormProps extends IBaseComponent {
    initialValues: IFormProps;
    onSubmit?: (name: string) => void;
    matchIDNotFound?: boolean;
}

export interface IFormProps {
    matchID: string;
}

export const NewPartnershipForm: React.FC<INewPartnershipFormProps> = ({
    className,
    initialValues,
    onSubmit,
    matchIDNotFound,
}) => {
    const handleSubmitForm = (values: IFormProps) => {
        if (onSubmit) {
            onSubmit(values.matchID);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleSubmitForm}
            validationSchema={matchIDValidationSchema}
        >
            {({ handleSubmit }) => {
                return (
                    <form
                        className={className}
                        data-testid="new-partnership-form"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate={true}
                    >
                        <Field id="matchID" label="Match ID" name="matchID" />
                        {matchIDNotFound ? <ErrorMessage>Match ID could not be found</ErrorMessage> : null}
                        <VerticalSpacing size={32} />
                        <FormButton data-testid="new-partnership-form-submit-button" type="submit">
                            Continue
                        </FormButton>
                    </form>
                );
            }}
        </Formik>
    );
};
