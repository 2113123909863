import React from "react";

import { CaptionLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export interface ITabProps extends IBaseComponent {
    tabPath: string;
    tabName: string;
    tabStatus?: string;
    exact?: boolean;
}

export const Tab: React.FC<ITabProps> = ({ className, tabPath, tabName, tabStatus, exact }) => (
    <TabLink className={className} activeClassName="active" to={tabPath} exact={exact} $tabStatus={tabStatus}>
        {tabName}
        {tabStatus ? (
            <>
                <VerticalSpacing size={8} />
                <Status tabStatus={tabStatus}>{tabStatus}</Status>
            </>
        ) : null}
    </TabLink>
);

export const TabLink = styled(NavLink)<{ $tabStatus?: string }>`
    ${ShortParagraphLabelCss};
    display: inline-block;
    max-width: unset;
    text-align: center;
    font-weight: 700;
    min-width: max-content;
    padding: ${({ theme, $tabStatus }) => ($tabStatus ? `10px ${theme.spacing24}` : `20px ${theme.spacing24}`)};
    cursor: pointer;
    color: ${({ theme }) => theme.primary500};
    background: ${({ theme }) => theme.primary75};

    &.active {
        cursor: default;
        color: ${({ theme }) => theme.neutral600};
        background: ${({ theme }) => theme.neutral0};
    }
`;

export const TabsWrapper = styled.nav`
    display: flex;
    overflow-x: auto;

    /* Hide scrollbar IE and Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar Firefox */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        /** Hide scrollbar in Chrome */
        display: none;
    }

    ${TabLink} {
        border-left: 1px solid ${({ theme }) => theme.primary100};
    }

    ${TabLink}:first-child {
        border-top-left-radius: ${({ theme }) => theme.radius1};
        border-left: none;
    }

    ${TabLink}:last-child {
        border-top-right-radius: ${({ theme }) => theme.radius1};
    }

    ${TabLink}.active, ${TabLink}.active + ${TabLink} {
        border: none;
    }

    @media (min-width: ${({ theme }) => theme.mediumMaxWidth}) {
        overflow-x: hidden;
    }
`;

export const CarouselWrapper = styled.div`
    position: relative;
`;

export const CarouselButton = styled.button<{ position: "left" | "right" }>`
    border: none;
    background: none;
    outline: none;
    position: absolute;
    cursor: pointer;
    ${({ position }) =>
        position === "left"
            ? css`
                  inset: 0px auto 0px -50px;
              `
            : css`
                  inset: 0px -50px 0px auto;
              `};
`;

const Status = styled.p<{ tabStatus?: string }>`
    ${CaptionLabelCss};
    text-align: left;
    text-transform: capitalize;

    ${({ tabStatus, theme }) => {
        switch (tabStatus) {
            case "blocked":
                return css`
                    color: ${theme.red800};
                `;
            case "ready":
                return css`
                    color: ${theme.green500};
                `;
            case "done":
                return css`
                    color: ${theme.neutral800};
                `;
            default:
                return css`
                    color: ${theme.neutral600};
                `;
        }
    }};
`;
