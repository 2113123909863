import React, { useCallback, useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { IPartnership, IPaymentHistoryData } from "types";
import { getPaymentHistoryData, postPaymentRequest } from "utils/ad-hoc-payments-api/ad-hoc-payments-api";

import { PaymentRequestForm } from "./payment-request-form";
import { PaymentRequestReview } from "./payment-request-review";
import { PaymentsTable } from "./payments-table";

export interface IOneOffPaymentTabProps extends IBaseComponent {
    partnership: IPartnership;
}

export const OneOffPaymentTab: React.FC<IOneOffPaymentTabProps> = ({ className, partnership }) => {
    const { token } = useAuth();
    const { url, path } = useRouteMatch();
    const history = useHistory();

    const { addAutoDismissNotification } = useSystemNotification();

    const [paymentHistoryData, setPaymentHistoryData] = useState<IPaymentHistoryData[]>([]);

    const getAndSetPaymentHistoryData = useCallback(async () => {
        if (token && partnership.id) {
            try {
                const paymentHistoryDataResponse = await getPaymentHistoryData(partnership.id, token);
                setPaymentHistoryData(paymentHistoryDataResponse);
            } catch (error) {
                captureException(error);
            }
        }
    }, [token, partnership.id]);

    const handleSendForApproval = async (paymentData: IPaymentHistoryData) => {
        try {
            await postPaymentRequest(paymentData, token);
            await getAndSetPaymentHistoryData();

            addAutoDismissNotification({
                id: "save-for-approval",
                type: "success",
                message: "Payment request has been sent for approval",
            });

            history.push(url);
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: "save-for-approval",
                type: "error",
                message: "Action failed: API error",
            });
        }
    };

    useEffect(() => {
        (async () => {
            await getAndSetPaymentHistoryData();
        })();
    }, [getAndSetPaymentHistoryData]);

    return (
        <div className={className}>
            <Switch>
                <Route exact path={`${path}`}>
                    <PaymentsTable partnership={partnership} paymentHistoryData={paymentHistoryData} />
                </Route>
                <Route exact path={`${path}/new-payment`}>
                    <PaymentRequestForm
                        initialValues={{
                            partnershipId: partnership.id,
                            amount: undefined,
                            paymentReference: "",
                            name: "",
                            description: "",
                        }}
                        onSubmit={handleSendForApproval}
                    />
                </Route>
                <Route path={`${path}/:paymentID`}>
                    <PaymentRequestReview updatePaymentHistoryData={getAndSetPaymentHistoryData} />
                </Route>
            </Switch>
        </div>
    );
};
