import { get } from "@wayhome-uk/utils";

import { IMatch, ISolicitor, TJokerCategory } from "types";

export interface IMatchFromApi {
    agreed_rent: number;
    application_id: string;
    created_at: string;
    customer_id: string;
    fee_reduction: number;
    id: string;
    joker_category: string;
    listing_id: string;
    offer_status: string;
    updated_at: string;
}

export const getMatch = async (matchID: string, authToken: string | null): Promise<IMatch> => {
    const { body, ok, status } = await get(
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/matches?id=${matchID}`,
        authToken,
    );

    if (ok) {
        return mapMatchFromApi(body[0] as IMatchFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get match", status: status }));
    }
};

export const mapMatchFromApi = (fromApi: IMatchFromApi): IMatch => ({
    id: fromApi.id,
    agreedRent: fromApi.agreed_rent,
    applicationId: fromApi.application_id,
    createdAt: fromApi.created_at,
    customerId: fromApi.customer_id,
    feeReduction: fromApi.fee_reduction,
    jokerCategory: fromApi.joker_category as TJokerCategory,
    listingId: fromApi.listing_id,
    offerStatus: fromApi.offer_status,
    updatedAt: fromApi.updated_at,
});

export interface ISolicitorFromApi {
    id: string;
    created_at: string;
    updated_at: string;
    company_name: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_email: string | null;
    contact_phone: string | null;
    generic_email: string | null;
    generic_phone: string | null;
}

export const mapSolicitorsFromApi = (fromApi: ISolicitorFromApi[]) =>
    fromApi.map((solicitor) => ({
        id: solicitor.id,
        createdAt: solicitor.created_at,
        updatedAt: solicitor.updated_at,
        companyName: solicitor.company_name,
        contactFirstName: solicitor.contact_first_name,
        contactLastName: solicitor.contact_last_name,
        contactEmail: solicitor.contact_email,
        contactPhone: solicitor.contact_phone,
        genericEmail: solicitor.generic_email,
        genericPhone: solicitor.generic_phone,
    }));

export const getSolicitors = async (authToken: string | null): Promise<ISolicitor[] | null> => {
    const { body, ok, status } = await get(`${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/solicitors`, authToken);
    if (ok && body.length === 0) {
        return null;
    } else if (ok) {
        return mapSolicitorsFromApi(body as ISolicitorFromApi[]);
    } else {
        throw new Error(JSON.stringify({ error: "failed to get solicitors", status: status }));
    }
};
