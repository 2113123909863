import { get, post } from "@wayhome-uk/utils";

import { IModulrDetails, IPartnership } from "types";

import { IPartnershipFromApi, mapPartnershipFromApi } from "../partnership-api";

export interface IModulrDetailsApi {
    modulr_customer_id: string | null;
    modulr_customer_status: string | null;
    modulr_customer_verification_status: string | null;
    modulr_account_id: string | null;
    modulr_account_status: string | null;
    modulr_account_number: string | null;
    modulr_account_sort_code: string | null;
}

export const mapBankingFromApi = (fromApi: IModulrDetailsApi): IModulrDetails => ({
    modulrCustomerId: fromApi.modulr_customer_id,
    modulrCustomerStatus: fromApi.modulr_customer_status,
    modulrCustomerVerificationStatus: fromApi.modulr_customer_verification_status,
    modulrAccountId: fromApi.modulr_account_id,
    modulrAccountStatus: fromApi.modulr_account_status,
    modulrAccountNumber: fromApi.modulr_account_number,
    modulrAccountSortCode: fromApi.modulr_account_sort_code,
});

export const postSetUpBankCustomer = async (id: string, authToken?: string | null): Promise<IPartnership> => {
    const { ok, body, status } = await post(
        `${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/set-up-bank-customer`,
        {},
        authToken,
    );

    if (ok) {
        return mapPartnershipFromApi(body as IPartnershipFromApi);
    } else {
        throw new Error(JSON.stringify({ error: "failed to post set up bank customer", status: status }));
    }
};

export const getBanking = async (id: string, authToken?: string | null): Promise<IModulrDetails> => {
    const { body } = await get(`${process.env.REACT_APP_API_ENDPOINT}/v0/partnerships/${id}/banking`, authToken);

    return mapBankingFromApi(body as IModulrDetailsApi);
};
