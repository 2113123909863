export const routes = {
    livingAdmin: {
        path: "/",
        title: "Living Admin",
        sitemap: false,
    },
    allPartnerships: {
        path: "/all-partnerships",
        title: "All Partnerships",
        sitemap: false,
    },
    newPartnership: {
        path: "/new-partnership",
        title: "New Partnership",
        sitemap: false,
    },
    createNewPartnership: {
        path: "/create-new-partnership/:matchID",
        title: "Create New Partnership",
        sitemap: false,
    },
    partnership: {
        path: "/partnerships/:partnershipID",
        title: "Partnership",
        sitemap: false,
    },
    taskDetail: {
        path: "/partnerships/:partnershipID/task/:taskID",
        title: "Task",
        sitemap: false,
    },
    login: {
        path: "/login",
        title: "Login",
        sitemap: false,
    },
};
