import React from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import styled from "styled-components";

import { IPurchaseSchedule } from "types";
import { TPropertyTenureType } from "utils";

import {
    ApportionmentTable,
    CompletionStatementBreakdown,
    LlpPurchaseCostBreakdown,
    ScheduleOne,
    ScheduleTwo,
} from "./components";

export interface ISchedulesProps extends IBaseComponent {
    purchaseSchedule?: IPurchaseSchedule;
    hasUnsavedEdits?: boolean;
    propertyTenureType: TPropertyTenureType;
}

export const Schedules: React.FC<ISchedulesProps> = ({
    className,
    purchaseSchedule,
    hasUnsavedEdits,
    propertyTenureType,
}) => {
    return (
        <div className={className}>
            <Heading>Schedules</Heading>
            {hasUnsavedEdits && (
                <InfoText>
                    You’ve edited some fields. Save them to make sure your changes are included in the schedules.
                </InfoText>
            )}
            <VerticalSpacing size={32} />
            {purchaseSchedule ? (
                <>
                    <ScheduleOne {...purchaseSchedule} />
                    <VerticalSpacing size={32} />
                    <ScheduleTwo {...purchaseSchedule} />
                    <VerticalSpacing size={32} />
                    <CompletionStatementBreakdown {...purchaseSchedule} />
                    <VerticalSpacing size={32} />
                    <LlpPurchaseCostBreakdown {...purchaseSchedule} />
                    {propertyTenureType !== null &&
                    propertyTenureType !== "unknown" &&
                    propertyTenureType !== "freehold" ? (
                        <>
                            <VerticalSpacing size={32} />
                            <ApportionmentTable {...purchaseSchedule} />
                        </>
                    ) : null}
                </>
            ) : (
                <NotActionableText>
                    You need to fill in all the above fields to be able to view the schedules.
                </NotActionableText>
            )}
        </div>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const InfoText = styled.p`
    ${ShortParagraphLabelCss};
    font-weight: 700;
    margin-top: ${({ theme }) => theme.spacing32};
`;

const NotActionableText = styled.p`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.red800};
    font-weight: 700;
`;
