import React from "react";

import {
    Button,
    ShortLeadLabelCss,
    ShortParagraphCss,
    ShortParagraphLabelCss,
    VerticalSpacing,
} from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";
import { object, string } from "yup";

import { PasswordField } from "components/form/password-field";

interface IApprovalFormProps extends IBaseComponent {
    error?: string | null;
    onSubmit: (password: string) => void;
}

export const ApprovalForm: React.FC<IApprovalFormProps> = ({ className, onSubmit, error = null }) => (
    <Formik
        initialValues={{ password: null }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={object().shape({
            password: string().nullable().required("Please fill in the field"),
        })}
        onSubmit={({ password }) => onSubmit(password!)}
    >
        {({ handleSubmit }) => (
            <form
                className={className}
                data-testid="approval-form"
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate={true}
            >
                <Heading>Approval</Heading>
                <VerticalSpacing size={32} />

                <PreambleText>Please enter the finance password in order to proceed.</PreambleText>
                <VerticalSpacing size={16} />

                <PasswordField label="Password" id="password" name="password" />
                <VerticalSpacing size={24} />

                <ErrorText>{error}</ErrorText>
                <VerticalSpacing size={24} />

                <DangerWrapper>
                    <p>Warning: You are authorising the collection of monies by continuing!</p>
                    <VerticalSpacing size={8} />
                    <Button onClick={() => {}}>Initiate Ledger &amp; DD Collection</Button>
                </DangerWrapper>
            </form>
        )}
    </Formik>
);

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const DangerWrapper = styled.div`
    background: ${({ theme }) => theme.red800};
    padding: ${({ theme }) => theme.spacing24};
    border-radius: ${({ theme }) => theme.radius1};

    p {
        ${ShortParagraphCss}
        color: ${({ theme }) => theme.neutral0};
        font-weight: 700;
    }

    button {
        background: ${({ theme }) => theme.neutral0};
        color: ${({ theme }) => theme.red800};
    }
`;

const PreambleText = styled.p`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const ErrorText = styled.p`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.red800};
    font-weight: 700;
`;
