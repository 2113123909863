import { useCallback } from "react";

import { IPackDocument } from "utils/document-api/document-api";

export type THeading = "insurance" | "others";

const documentTypeDictionary: Record<THeading, IPackDocument[]> = {
    others: [
        {
            name: "digital_property_pack",
            displayName: "Digital Property Pack",
            entityType: "match",
        },
        {
            name: "partnership_agreement",
            displayName: "Partnership Agreement",
            entityType: "match",
        },
        {
            name: "assured_shorthold_tenancy_agreement",
            displayName: "Assured Shorthold Tenancy Agreement",
            entityType: "match",
        },
        {
            name: "annual_accounts",
            displayName: "Annual Accounts",
            entityType: "match",
        },
        {
            name: "llp_tax_returns",
            displayName: "LLP Tax Returns",
            entityType: "match",
        },
    ],
    insurance: [
        {
            name: "magenta_building_insurance",
            displayName: "Magenta Building's Insurance",
            entityType: "match",
        },
        {
            name: "arag_emergency_home_insurance",
            displayName: "ARAG Emergency Home Insurance",
            entityType: "match",
        },
    ],
};

export const useDocuments = () => {
    const getDocSubHeading = useCallback((key: THeading): string | undefined => {
        const headings: Record<THeading, string> = {
            others: "",
            insurance: "Insurance documents",
        };

        return headings[key];
    }, []);

    const getDocumentTypeList = useCallback(() => Object.entries(documentTypeDictionary), []);

    return {
        getDocSubHeading,
        getDocumentTypeList,
    };
};
