import React from "react";

import { ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import styled from "styled-components";

import { IPurchaseSchedule } from "types";
import { formatNumberAsCurrencyWithSymbol } from "utils/formatters/currency";

import { SubHeading, TableContainer, TableData, TableHeading } from "./table";

export const ScheduleTwo: React.FC<IPurchaseSchedule> = ({
    customerContribution,
    customerCompletionStatementSubtotal,
    customerLlpPurchaseCostsSubtotal,
    fundContribution,
    fundCompletionStatementSubtotal,
    fundLlpPurchaseCostsSubtotal,
    nomineeContribution,
    nomineeCompletionStatementSubtotal,
    nomineeLlpPurchaseCostsSubtotal,
    completionStatementSubtotal,
    llpPurchaseCostsSubtotal,
    totalFormationCosts,
    wayhomeCustomerDepositContribution,
    wayhomeStampDutyContribution,
    customerFinancialIncentive,
    fundFinancialIncentive,
    nomineeFinancialIncentive,
    totalFinancialIncentive,
}) => {
    const showFinancialIncentivesColumn = wayhomeCustomerDepositContribution || wayhomeStampDutyContribution;
    const percentWidth = showFinancialIncentivesColumn ? 20 : 27;
    return (
        <>
            <SubHeading>Schedule 2</SubHeading>
            <TableContainer>
                <thead>
                    <tr>
                        <TableHeading percentWidth={21}></TableHeading>
                        <TableHeading percentWidth={percentWidth} alignRight>
                            Completion statement subtotal
                        </TableHeading>
                        {showFinancialIncentivesColumn ? (
                            <TableHeading percentWidth={percentWidth} alignRight>
                                Financial incentive*
                            </TableHeading>
                        ) : null}
                        <TableHeading percentWidth={percentWidth} alignRight>
                            LLP Purchase costs subtotal
                        </TableHeading>
                        <TableHeading alignRight>Total formation costs</TableHeading>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <TableData>Residential member</TableData>
                        <TableData alignRight>
                            {typeof customerCompletionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerCompletionStatementSubtotal)
                                : ""}
                        </TableData>
                        {showFinancialIncentivesColumn ? (
                            <TableData alignRight>
                                {typeof customerFinancialIncentive === "number"
                                    ? formatNumberAsCurrencyWithSymbol(customerFinancialIncentive)
                                    : ""}
                            </TableData>
                        ) : null}
                        <TableData alignRight>
                            {typeof customerLlpPurchaseCostsSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerLlpPurchaseCostsSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof customerContribution === "number"
                                ? formatNumberAsCurrencyWithSymbol(customerContribution)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Funding member</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundCompletionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundCompletionStatementSubtotal)
                                : ""}
                        </TableData>
                        {showFinancialIncentivesColumn ? (
                            <TableData alignRight isEvenRow>
                                {typeof fundFinancialIncentive === "number"
                                    ? formatNumberAsCurrencyWithSymbol(fundFinancialIncentive)
                                    : ""}
                            </TableData>
                        ) : null}
                        <TableData alignRight isEvenRow>
                            {typeof fundLlpPurchaseCostsSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundLlpPurchaseCostsSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight isEvenRow>
                            {typeof fundContribution === "number"
                                ? formatNumberAsCurrencyWithSymbol(fundContribution)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Wayhome member</TableData>
                        <TableData alignRight>
                            {typeof nomineeCompletionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeCompletionStatementSubtotal)
                                : ""}
                        </TableData>
                        {showFinancialIncentivesColumn ? (
                            <TableData alignRight>
                                {typeof nomineeFinancialIncentive === "number"
                                    ? formatNumberAsCurrencyWithSymbol(nomineeFinancialIncentive)
                                    : ""}
                            </TableData>
                        ) : null}
                        <TableData alignRight>
                            {typeof nomineeLlpPurchaseCostsSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeLlpPurchaseCostsSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight>
                            {typeof nomineeContribution === "number"
                                ? formatNumberAsCurrencyWithSymbol(nomineeContribution)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isTotalRow>Total</TableData>
                        <TableData alignRight isTotalRow>
                            {typeof completionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(completionStatementSubtotal)
                                : ""}
                        </TableData>
                        {showFinancialIncentivesColumn ? (
                            <TableData alignRight isTotalRow>
                                {typeof totalFinancialIncentive === "number"
                                    ? formatNumberAsCurrencyWithSymbol(totalFinancialIncentive)
                                    : ""}
                            </TableData>
                        ) : null}
                        <TableData alignRight isTotalRow>
                            {typeof llpPurchaseCostsSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(llpPurchaseCostsSubtotal)
                                : ""}
                        </TableData>
                        <TableData alignRight isTotalRow>
                            {typeof totalFormationCosts === "number"
                                ? formatNumberAsCurrencyWithSymbol(totalFormationCosts)
                                : ""}
                        </TableData>
                    </tr>
                </tbody>
            </TableContainer>

            {showFinancialIncentivesColumn ? (
                <>
                    <VerticalSpacing size={8} />
                    <StyledParagraph>*Wayhome Stamp Duty Holiday or Wayhome Deposit Boost</StyledParagraph>
                </>
            ) : null}
        </>
    );
};

const StyledParagraph = styled.p`
    ${ShortParagraphLabelCss};
    font-weight: 400;
`;
