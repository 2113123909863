import {
    Button as DsButton,
    ErrorMessage as DsErrorMessage,
    Input as DsInput,
    Label as DsLabel,
    LongParagraphCss,
    ShortParagraphCss,
    ShortParagraphLabelCss,
} from "@wayhome-uk/design-system";
import styled from "styled-components";

export const Label = styled(DsLabel)`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral600};
`;

export const Input = styled(DsInput)`
    max-width: unset;
    width: 100%;

    ::placeholder {
        color: ${({ theme }) => theme.primary500};
    }

    :focus::placeholder {
        color: transparent;
    }

    ${({ isValid, theme }) =>
        isValid === false &&
        `
        color: ${theme.red800};
        border-color: ${theme.red800};
    `};
`;

export const TextAreaInput = styled(Input)`
    ${LongParagraphCss};
    display: block;
    max-width: unset;
    width: 100%;
`;

export const ErrorMessage = styled(DsErrorMessage)`
    ${ShortParagraphCss};
    color: ${({ theme }) => theme.red800};
    margin: ${({ theme }) => `${theme.spacing8} 0`};

    svg {
        display: none;
    }
`;

export const FormButton = styled(DsButton)`
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing40}`};

    :disabled {
        cursor: not-allowed;
    }
`;

export const ExplanationText = styled.p`
    font-weight: 400;
    color: ${({ theme }) => theme.neutral500};
`;
