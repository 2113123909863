import React, { Fragment } from "react";

import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import { includes } from "lodash";
import styled from "styled-components";
import { array, object } from "yup";

import { ErrorMessage, FormButton } from "components/form/form-atoms";
import { IconChecked } from "components/media/icons/icon-checked";

import { IPartialTask, ITask } from "../../../../../types";

export interface IDetailsFormValues {
    selectedTasks: IPartialTask[] | ITask[];
}

export interface IDetailsFormProps extends IBaseComponent {
    initialValues: IDetailsFormValues;
    onSubmit: (values: IDetailsFormValues) => Promise<void>;
    configuredTasks: IPartialTask[];
}

const tasksFormValidationSchema = object().shape({
    selectedTasks: array().min(1, "Please select a Task").required("Please select a Task"),
});

export const TasksForm: React.FC<IDetailsFormProps> = ({ className, initialValues, onSubmit, configuredTasks }) => {
    const handleSubmitForm = async (values: IDetailsFormValues) => {
        await onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmitForm}
            enableReinitialize={true}
            validationSchema={tasksFormValidationSchema}
        >
            {({ handleSubmit, setFieldValue, values, touched, errors }) => (
                <form
                    className={className}
                    data-testid="tasks-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={true}
                >
                    <Heading>Request a New Task</Heading>
                    <VerticalSpacing size={32} />
                    <TasksContainer>
                        {configuredTasks.map((configuredTask) => (
                            <Fragment key={configuredTask.name}>
                                <CheckboxWrapper data-testid={configuredTask.name}>
                                    <StyledIconChecked
                                        configuredTasks={configuredTask}
                                        selectedTasks={values.selectedTasks}
                                        dataTestId={`${configuredTask.name}-icon-checked`}
                                    />
                                    <HiddenCheckbox
                                        id={configuredTask.name ? configuredTask.name : ""}
                                        type="checkbox"
                                        name={configuredTask.name ? configuredTask.name : ""}
                                        onClick={() => {
                                            if (values.selectedTasks.length === 0) {
                                                setFieldValue("selectedTasks", [configuredTask]);
                                            }

                                            const names = [];
                                            for (const selectedTask of values.selectedTasks) {
                                                names.push(selectedTask.name);
                                            }

                                            if (includes(names, configuredTask.name)) {
                                                const selectedTasks = values.selectedTasks.filter(
                                                    (selectedTask) => selectedTask.name !== configuredTask.name,
                                                );
                                                setFieldValue("selectedTasks", selectedTasks);
                                            } else {
                                                setFieldValue("selectedTasks", [
                                                    ...values.selectedTasks,
                                                    configuredTask,
                                                ]);
                                            }
                                        }}
                                    />
                                </CheckboxWrapper>
                                <TaskDisplayText htmlFor={configuredTask.name ? configuredTask.name : ""}>
                                    {configuredTask.displayName}
                                </TaskDisplayText>
                                <VerticalSpacing size={32} />
                            </Fragment>
                        ))}
                    </TasksContainer>

                    <VerticalSpacing size={32} />
                    <FormButton data-testid="task-submit" type="submit">
                        Request Selected Tasks
                    </FormButton>

                    {touched.selectedTasks && errors.selectedTasks && (
                        <ErrorMessage>
                            <span>{errors.selectedTasks}</span>
                        </ErrorMessage>
                    )}
                </form>
            )}
        </Formik>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const TasksContainer = styled.div`
    display: inline-block;
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    padding-left: 0;
    position: relative;
`;

const StyledIconChecked = styled(IconChecked)<{ configuredTasks: IPartialTask; selectedTasks: IPartialTask[] }>`
    visibility: ${({ configuredTasks, selectedTasks }) => {
        let visibility = "hidden";
        for (const selectedTask of selectedTasks) {
            if (selectedTask.name === configuredTasks.name) {
                visibility = "visible";
            }
        }
        return visibility;
    }};

    cursor: pointer;
    position: absolute;
    left: -1px;
    top: -5px;

    svg {
        height: 25px;
        width: 25px;
    }
`;

const CheckboxWrapper = styled.span`
    position: relative;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: ${({ theme }) => theme.radius1};
    border: ${({ theme }) => `2px solid ${theme.primary200}`};
    left: -5px;
    top: -8px;
    cursor: pointer;
`;

const TaskDisplayText = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-left: 32px;
    cursor: pointer;
`;
