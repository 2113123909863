import React, { useCallback, useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import {
    LoadingIndicator,
    LongParagraphCss,
    ShortLeadLabelCss,
    ShortParagraphLabelCss,
    VerticalSpacing,
} from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { BlockedInfo } from "components/blocked-info";
import { FormButton as Button, Label } from "components/form/form-atoms";
import { IconChecked } from "components/media/icons/icon-checked";
import { IModulrDetails, IPartnership } from "types";
import { patchPartnership } from "utils";
import { getBanking, postSetUpBankCustomer } from "utils/modulr-api/modulr-api";

export interface IModulrTabProps extends IBaseComponent {
    partnership: IPartnership;
    updatePartnership: () => void;
}

export const ModulrTab: React.FC<IModulrTabProps> = ({ className, partnership, updatePartnership }) => {
    const { token } = useAuth();
    const { addAutoDismissNotification, removeNotification } = useSystemNotification();

    const [bankingDetails, setBankingDetails] = useState<IModulrDetails>();
    const [allowDirectPayments, setAllowDirectPayments] = useState(!!partnership.allowDirectPayments);
    const [loadingBankDetails, setLoadingBankDetails] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const getAndSetBanking = useCallback(async () => {
        if (token && partnership.id) {
            const bankingDetails = await getBanking(partnership.id, token);
            setBankingDetails(bankingDetails);
        }
    }, [partnership.id, token]);

    useEffect(() => {
        (async () => {
            await getAndSetBanking();
            setLoadingBankDetails(false);
        })();
    }, [getAndSetBanking]);

    const handleInitiateCustomerAndBankAccountCreation = async () => {
        try {
            await postSetUpBankCustomer(partnership.id, token);
            await getAndSetBanking();

            addAutoDismissNotification({
                id: "initiate-modulr",
                type: "success",
                message: "Modulr customer & bank account creation is now in progress",
            });
            await updatePartnership();
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: "initiate-modulr",
                type: "error",
                message: "Creation failed: API error",
            });
        }
    };

    const handleSave = async () => {
        const notificationId = "allow-direct-payment-notification";
        try {
            removeNotification(notificationId);
            setIsSaving(true);

            const response = await patchPartnership(
                partnership.id,
                {
                    allowDirectPayments,
                },
                token,
            );

            setAllowDirectPayments(!!response.allowDirectPayments);
            await updatePartnership();

            addAutoDismissNotification({
                id: notificationId,
                type: "success",
                message: "Direct payments updated successfully",
            });
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: notificationId,
                type: "error",
                message: "Failed to updated direct payments",
            });
        } finally {
            setIsSaving(false);
        }
    };

    const llpDetails = [
        { label: "LLP Name", text: partnership.name },
        { label: "Company Registration Number", text: partnership.companyRegistrationNumber },
    ];

    const customerDetails = [
        { label: "First Name", text: partnership.customerPartnerOne?.firstName },
        { label: "Last Name", text: partnership.customerPartnerOne?.lastName },
        { label: "Date of Birth", text: partnership.customerPartnerOne?.dateOfBirth },
        { label: "Current Address", text: partnership.customerPartnerOne?.addressLineOne },
    ];

    const bankDetails = [
        { label: "Sort Code", text: bankingDetails?.modulrAccountSortCode },
        { label: "Account Number", text: bankingDetails?.modulrAccountNumber },
        { label: "Account ID", text: bankingDetails?.modulrAccountId },
    ];

    if (loadingBankDetails) {
        return <StyledLoadingIndicator>Fetching bank details...</StyledLoadingIndicator>;
    }

    return (
        <div className={className}>
            <Heading>Modulr Customer</Heading>
            <VerticalSpacing size={32} />

            {(!partnership.name || !partnership.customerPartnerOne) && !bankingDetails?.modulrCustomerId ? (
                <BlockedInfo
                    warning="More data is required to create a customer on Modulr."
                    explanation="Please add LLP and buyer(s) details before proceeding."
                />
            ) : partnership.name && partnership.customerPartnerOne && !bankingDetails?.modulrCustomerId ? (
                <>
                    {llpDetails.map((detail, index) => (
                        <div key={index}>
                            <Label>{detail.label}</Label>
                            <VerticalSpacing size={8} />
                            <Text>{detail.text}</Text>
                            <VerticalSpacing size={16} />
                        </div>
                    ))}

                    <VerticalSpacing size={16} />
                    <SubHeading>Buyer 1</SubHeading>
                    <VerticalSpacing size={16} />

                    {customerDetails.map((detail, index) => (
                        <div key={index}>
                            <Label>{detail.label}</Label>
                            <VerticalSpacing size={8} />
                            <Text>{detail.text}</Text>
                            <VerticalSpacing size={16} />
                        </div>
                    ))}
                </>
            ) : (
                partnership.name &&
                partnership.customerPartnerOne &&
                bankingDetails?.modulrCustomerId && (
                    <>
                        <SubHeading>Customer Details</SubHeading>
                        <VerticalSpacing size={16} />

                        <Label>Customer Number</Label>
                        <VerticalSpacing size={8} />
                        <Text>{bankingDetails.modulrCustomerId}</Text>
                        <VerticalSpacing size={16} />
                    </>
                )
            )}

            <VerticalSpacing size={24} />
            <Separator />
            <VerticalSpacing size={24} />

            <Heading>Modulr Bank Account</Heading>
            <VerticalSpacing size={32} />

            {(!partnership.name || !partnership.customerPartnerOne) && !bankingDetails?.modulrCustomerId && (
                <BlockedInfo warning="The Modulr customer account must be completed first before Account Setup." />
            )}

            {partnership.name && partnership.customerPartnerOne && !bankingDetails?.modulrCustomerId ? (
                <Button onClick={handleInitiateCustomerAndBankAccountCreation}>
                    Initiate Customer & Bank Account Creation
                </Button>
            ) : (
                partnership.name &&
                partnership.customerPartnerOne &&
                bankingDetails?.modulrAccountId && (
                    <>
                        <SubHeading>Bank Account Details</SubHeading>
                        <VerticalSpacing size={16} />

                        {bankDetails.map((detail, index) => (
                            <div key={index}>
                                <Label>{detail.label}</Label>
                                <VerticalSpacing size={8} />
                                <Text>{detail.text}</Text>
                                <VerticalSpacing size={16} />
                            </div>
                        ))}

                        <CheckboxWrapper>
                            <StyledTickIcon allowDirectPayments={allowDirectPayments} />
                            <Checkbox
                                type="checkbox"
                                name="allowDebitPayments"
                                id="allowDebitPayments"
                                onChange={() => {
                                    setAllowDirectPayments((prevValue) => !prevValue);
                                }}
                                checked={allowDirectPayments}
                            />
                        </CheckboxWrapper>
                        <StyledLabel htmlFor="allowDebitPayments">Allow direct payments</StyledLabel>
                        <VerticalSpacing size={32} />

                        <Button onClick={handleSave} disabled={isSaving}>
                            {isSaving ? "Saving..." : "Save"}
                        </Button>
                    </>
                )
            )}
        </div>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const SubHeading = styled.h6`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;

const Separator = styled.div`
    background: ${({ theme }) => theme.neutral75};
    height: 1px;
`;

const Text = styled.p`
    ${LongParagraphCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: 700;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
    padding-block: 100px;
`;

const StyledLabel = styled.label`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-left: 32px;
    cursor: pointer;
`;

const StyledTickIcon = styled(IconChecked)<{ allowDirectPayments?: boolean }>`
    cursor: pointer;
    visibility: ${({ allowDirectPayments }) => (allowDirectPayments ? "visible" : "hidden")};
    position: absolute;
    left: -1px;
    top: -6px;

    svg {
        height: 25px;
        width: 25px;
    }
`;

const CheckboxWrapper = styled.span`
    position: relative;
`;

const Checkbox = styled.input`
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: ${({ theme }) => theme.radius1};
    border: ${({ theme }) => `2px solid ${theme.primary200}`};
    left: -5px;
    top: -8px;
    cursor: pointer;
`;
