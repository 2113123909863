import React, { Component, ReactNode } from "react";

import { captureException } from "@sentry/browser";
import { ErrorMessage, ShortParagraphCss } from "@wayhome-uk/design-system";
import styled from "styled-components";

interface IErrorBoundaryProps {
    className?: string;
    forceErrorState?: boolean;
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, State> {
    state = {
        hasError: false,
    };

    componentDidMount() {
        const { forceErrorState } = this.props;

        if (forceErrorState) {
            this.setState({ hasError: true });
        }
    }

    componentDidCatch(error: Error) {
        captureException(error);
        this.setState({ hasError: true });
    }

    render() {
        const { className, children } = this.props;
        const { hasError } = this.state;

        return hasError ? (
            <div className={className}>
                <StyledErrorMessage>
                    An error occurred here. This has been noted and we will look into this as soon as possible.
                </StyledErrorMessage>
            </div>
        ) : (
            children
        );
    }
}

export { ErrorBoundary };

const StyledErrorMessage = styled(ErrorMessage)`
    ${ShortParagraphCss};
    margin: ${(props) => props.theme.spacing12};
    padding-top: ${(props) => props.theme.spacing8};
`;

StyledErrorMessage.displayName = "ErrorMessage";
