export const generateRandomString = () => Math.random().toString(36).slice(2);

export const formatDate = (date: Date) => {
    return date.toLocaleString("en-GB", {
        hour12: true,
        day: "numeric",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
    });
};
