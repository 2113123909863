import React from "react";

import { Theme } from "@wayhome-uk/design-system";

interface Props {
    className?: string;
    iconColor?: string;
}

export const DownloadIcon: React.FC<Props> = ({ iconColor = Theme.primary500, ...rest }) => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/SVGAnimateMotionElement"
            {...rest}
        >
            <path
                d="M14 11.5V14.5H2V11.5H0V14.5C0 15.6 0.9 16.5 2 16.5H14C15.1 16.5 16 15.6 16 14.5V11.5H14ZM13 7.5L11.59 6.09L9 8.67V0.5H7V8.67L4.41 6.09L3 7.5L8 12.5L13 7.5Z"
                fill={iconColor}
            />
        </svg>
    );
};
