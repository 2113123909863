import React, { useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { Header4LabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { ContentWrapper } from "components/content-wrapper";
import { Label } from "components/form/form-atoms";
import { PageLayout } from "components/page-layout";
import { getRoutePathWithParamsAndQueryStrings } from "config/route-paths";
import { routes } from "config/routes";
import { getListing, getMatch } from "utils";
import { postPartnership } from "utils/partnership-api/partnerships-api";

import { CreateNewPartnershipForm } from "./create-new-partnership-form";

export const CreateNewPartnership: React.FC<IBaseComponent> = ({ className }) => {
    const [listingID, setListingID] = useState<string | null>(null);
    const [listingAddress, setListingAddress] = useState<string | null>(null);

    const { token } = useAuth();
    const { matchID } = useParams<{ matchID: string }>();
    const history = useHistory();
    const { addAutoDismissNotification } = useSystemNotification();

    useEffect(() => {
        (async () => {
            if (token && matchID) {
                try {
                    const matchResponse = await getMatch(matchID, token);

                    setListingID(matchResponse.listingId);
                } catch (error) {
                    addAutoDismissNotification({
                        id: "new-partnership-creation",
                        type: "error",
                        message: "Couldn't retrieve match info: API error",
                    });
                }
            }
        })();
    }, [token, matchID, addAutoDismissNotification]);

    useEffect(() => {
        (async () => {
            if (token && listingID) {
                try {
                    const { originalAddress: listingAddress } = await getListing(listingID, token);
                    if (listingAddress) {
                        setListingAddress(listingAddress);
                    }
                } catch (error) {
                    addAutoDismissNotification({
                        id: "new-partnership-creation",
                        type: "error",
                        message: "Couldn't retrieve listing info: API error",
                    });
                }
            }
        })();
    }, [token, listingID, addAutoDismissNotification]);

    const handleSubmit = async (partnershipName: string) => {
        try {
            const response = await postPartnership({ name: partnershipName, matchId: matchID }, token);

            const partnershipIDFromAPI = response.id;
            addAutoDismissNotification({
                id: "new-partnership-creation",
                type: "success",
                message: "New partnership has been created",
            });
            history.push(
                getRoutePathWithParamsAndQueryStrings(routes.partnership.path, {
                    partnershipID: partnershipIDFromAPI,
                }),
            );
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: "new-partnership-creation",
                type: "error",
                message: "Creation failed: API error",
            });
        }
    };

    return (
        <StyledPageLayout className={className}>
            <Heading>New Partnership</Heading>
            <VerticalSpacing size={32} />
            {matchID && listingAddress ? (
                <ContentWrapper>
                    <StyledLink to={routes.newPartnership.path}>Re-enter Match ID</StyledLink>
                    <VerticalSpacing size={32} />
                    <StyledLabel as="p">Match ID</StyledLabel>
                    <VerticalSpacing size={4} />
                    <StyledParagraph>{matchID}</StyledParagraph>
                    <VerticalSpacing size={24} />
                    <StyledLabel as="p">Property Address</StyledLabel>
                    <VerticalSpacing size={4} />
                    <StyledParagraph>{listingAddress}</StyledParagraph>
                    <VerticalSpacing size={24} />
                    <CreateNewPartnershipForm initialValues={{ llpName: "" }} onSubmit={handleSubmit} />
                </ContentWrapper>
            ) : null}
        </StyledPageLayout>
    );
};

const StyledPageLayout = styled(PageLayout)`
    height: 100vh;
`;

const Heading = styled.h1`
    ${Header4LabelCss};
    font-family: ${(props) => props.theme.fontFamilySerif};
    color: ${({ theme }) => theme.neutral900};
    font-weight: 700;
`;

const StyledLink = styled(Link)`
    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.primary500};
    line-height: 1.6;
    font-weight: 700;
    cursor: pointer;
`;

const StyledLabel = styled(Label)`
    ${ShortParagraphLabelCss};
    line-height: 1.6;
    font-weight: 700;
`;
const StyledParagraph = styled.p`
    ${ShortParagraphLabelCss};
    line-height: 1.6;
    font-weight: 500;
`;
