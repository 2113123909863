import React from "react";

import { IPurchaseSchedule } from "types";
import { formatNumberAsCurrencyWithSymbol } from "utils/formatters/currency";

import { SubHeading, TableContainer, TableData, TableHeading } from "./table";

export const LlpPurchaseCostBreakdown: React.FC<IPurchaseSchedule> = ({
    propertySurveyCost,
    eicrInspectionFee,
    landlordComplianceWorkCost,
    gasSafetyInspectionFee,
    landlordAdvisoryWorkCost,
    landlordLicenceCheckFee,
    completionDayInspectionFee,
    llpCreationCost,
    llpPurchaseCostsSubtotal,
}) => {
    return (
        <>
            <SubHeading>LLP purchase costs breakdown (Inc. VAT)</SubHeading>
            <TableContainer>
                <thead>
                    <tr>
                        <TableHeading percentWidth={60}></TableHeading>
                        <TableHeading alignRight>Cost</TableHeading>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <TableData>Surveyors fees</TableData>
                        <TableData alignRight>
                            {typeof propertySurveyCost === "number"
                                ? formatNumberAsCurrencyWithSymbol(propertySurveyCost)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>EICR inspection</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof eicrInspectionFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(eicrInspectionFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Gas safety inspection</TableData>
                        <TableData alignRight>
                            {typeof gasSafetyInspectionFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(gasSafetyInspectionFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Landlord compliance work</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof landlordComplianceWorkCost === "number"
                                ? formatNumberAsCurrencyWithSymbol(landlordComplianceWorkCost)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Landlord advisory work</TableData>
                        <TableData alignRight>
                            {typeof landlordAdvisoryWorkCost === "number"
                                ? formatNumberAsCurrencyWithSymbol(landlordAdvisoryWorkCost)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Landlord licence check</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof landlordLicenceCheckFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(landlordLicenceCheckFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Completion day inpection</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof completionDayInspectionFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(completionDayInspectionFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isTotalRow>Total</TableData>
                        <TableData alignRight isTotalRow>
                            {typeof llpPurchaseCostsSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(llpPurchaseCostsSubtotal)
                                : ""}
                        </TableData>
                    </tr>
                </tbody>
            </TableContainer>
        </>
    );
};
