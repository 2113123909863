import React, { useState } from "react";

import { ShortParagraphCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { Formik } from "formik";
import styled from "styled-components";

import { ConditionalReadOnlyField } from "components/form/conditional-read-only-field";
import { ErrorMessage, FormButton, Label } from "components/form/form-atoms";
import { TextField } from "components/form/text-field";
import { IconChecked } from "components/media/icons/icon-checked";
import { IDirectDebit } from "types";

import { directDebitValidationSchema } from "./direct-debit-validation-schema";

export interface IDirectDebitProps extends IDirectDebit {
    termsAndConditions?: boolean;
}

export interface IDirectDebitFormProps extends IBaseComponent {
    initialValues: IDirectDebitProps;
    onSubmit?: (values: IDirectDebit) => void;
    isRentDirectDebitMandateIDNull?: boolean;
}

export const DirectDebitForm: React.FC<IDirectDebitFormProps> = ({
    className,
    initialValues,
    onSubmit,
    isRentDirectDebitMandateIDNull,
}) => {
    const [areTermsAndConditionsAccepted, setAreTermsAndConditionsAccepted] = useState<boolean>(false);

    const handleTermsAndConditions = () => {
        setAreTermsAndConditionsAccepted(!areTermsAndConditionsAccepted);
    };

    const handleSubmitForm = (values: IDirectDebit) => {
        if (areTermsAndConditionsAccepted && onSubmit) {
            onSubmit(values);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmitForm}
            validationSchema={directDebitValidationSchema}
            enableReinitialize={true}
        >
            {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                <form
                    className={className}
                    data-testid="direct-debit-form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    noValidate={false}
                >
                    {values.status ? (
                        <>
                            <Label as="h3">Status</Label>
                            <VerticalSpacing size={8} />
                            <Badge status={values.status}>{values.status}</Badge>
                            <VerticalSpacing size={16} />
                        </>
                    ) : null}

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField
                            id="sort-code"
                            label="Sort Code"
                            name="sortCode"
                            disabled={!isRentDirectDebitMandateIDNull}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField id="name" label="Name" name="name" disabled={!isRentDirectDebitMandateIDNull} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField
                            id="account-number"
                            label="Account Number"
                            name="accountNumber"
                            disabled={!isRentDirectDebitMandateIDNull}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField
                            id="phone-number"
                            label="Phone Number"
                            name="phoneNumber"
                            disabled={!isRentDirectDebitMandateIDNull}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField
                            id="address-line-one"
                            label="Address Line 1"
                            name="addressLineOne"
                            disabled={!isRentDirectDebitMandateIDNull}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField
                            id="address-line-two"
                            label="Address Line 2"
                            name="addressLineTwo"
                            disabled={!isRentDirectDebitMandateIDNull}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField
                            id="postcode"
                            label="Postcode"
                            name="postcode"
                            disabled={!isRentDirectDebitMandateIDNull}
                        />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={16} />

                    <ConditionalReadOnlyField isReadOnly={!isRentDirectDebitMandateIDNull}>
                        <TextField id="town" label="Town" name="town" disabled={!isRentDirectDebitMandateIDNull} />
                    </ConditionalReadOnlyField>
                    <VerticalSpacing size={32} />

                    <Question>
                        Has the customer accepted our
                        <StyledLink href="https://www.wayhome.co.uk/terms-conditions" target="_blank">
                            {" "}
                            terms and conditions
                        </StyledLink>
                        ?
                    </Question>
                    <VerticalSpacing size={12} />
                    {isRentDirectDebitMandateIDNull ? (
                        <>
                            <TermsAndConditionsContainer areTermsAndConditionsAccepted={areTermsAndConditionsAccepted}>
                                <CheckboxWrapper>
                                    <StyledIconChecked areTermsAndConditionsAccepted={areTermsAndConditionsAccepted} />
                                    <HiddenCheckbox
                                        id="terms-and-conditions"
                                        type="checkbox"
                                        name="termsAndConditions"
                                        data-testid="terms-and-conditions"
                                        onChange={() => {
                                            setFieldValue("termsAndConditions", !areTermsAndConditionsAccepted);
                                            handleTermsAndConditions();
                                        }}
                                        checked={areTermsAndConditionsAccepted}
                                        areTermsAndConditionsAccepted={areTermsAndConditionsAccepted}
                                    />
                                </CheckboxWrapper>
                                <TermsAndConditionsText
                                    htmlFor="terms-and-conditions"
                                    areTermsAndConditionsAccepted={areTermsAndConditionsAccepted}
                                >
                                    Yes, they have accepted
                                </TermsAndConditionsText>
                            </TermsAndConditionsContainer>
                            <ErrorWrapper>
                                {!areTermsAndConditionsAccepted &&
                                    touched.termsAndConditions &&
                                    errors.termsAndConditions && (
                                        <ErrorMessage>
                                            <span data-testid="direct-debit-terms-and-conditions-error">
                                                {errors.termsAndConditions}
                                            </span>
                                        </ErrorMessage>
                                    )}
                            </ErrorWrapper>
                        </>
                    ) : (
                        <TermsAndConditionsContainer areTermsAndConditionsAccepted={true}>
                            <CheckboxWrapper>
                                <StyledIconChecked areTermsAndConditionsAccepted={true} />
                                <HiddenCheckbox
                                    id="terms-and-conditions"
                                    type="checkbox"
                                    name="termsAndConditions"
                                    data-testid="terms-and-conditions"
                                    onChange={() => {}}
                                    checked={true}
                                    areTermsAndConditionsAccepted={true}
                                />
                            </CheckboxWrapper>
                            <TermsAndConditionsText htmlFor="terms-and-conditions" areTermsAndConditionsAccepted={true}>
                                Yes, they have accepted
                            </TermsAndConditionsText>
                        </TermsAndConditionsContainer>
                    )}
                    <VerticalSpacing size={32} />

                    {isRentDirectDebitMandateIDNull ? (
                        <FormButton data-testid="direct-debit-form-submit-button" type="submit">
                            Set up direct debit
                        </FormButton>
                    ) : null}
                </form>
            )}
        </Formik>
    );
};

const Question = styled.p`
    ${ShortParagraphCss};
    max-width: unset;
    color: ${({ theme }) => theme.neutral800};
    font-weight: 500;
`;

const TermsAndConditionsContainer = styled.div<{ areTermsAndConditionsAccepted?: boolean }>`
    cursor: pointer;
    display: inline-block;
    background: ${({ areTermsAndConditionsAccepted, theme }) =>
        areTermsAndConditionsAccepted ? theme.neutral800 : theme.primary25};
    padding: ${({ theme }) => `${theme.spacing12} ${theme.spacing16}`};
    border-radius: ${({ theme }) => theme.radius1};
    position: relative;
`;

const StyledIconChecked = styled(IconChecked)<{ areTermsAndConditionsAccepted?: boolean }>`
    cursor: pointer;
    visibility: ${({ areTermsAndConditionsAccepted }) => (areTermsAndConditionsAccepted ? "visible" : "hidden")};
    position: absolute;
`;

const CheckboxWrapper = styled.span`
    position: relative;
`;

const HiddenCheckbox = styled.input<{ areTermsAndConditionsAccepted?: boolean }>`
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: ${({ areTermsAndConditionsAccepted, theme }) =>
        areTermsAndConditionsAccepted ? "none" : `2px solid ${theme.primary200}`};
    left: -5px;
    top: -3px;
    cursor: pointer;
`;

const TermsAndConditionsText = styled.label<{ areTermsAndConditionsAccepted?: boolean }>`
    ${ShortParagraphLabelCss};
    color: ${({ areTermsAndConditionsAccepted, theme }) =>
        areTermsAndConditionsAccepted ? theme.neutral0 : theme.primary500};
    font-weight: 700;
    margin-left: 28px;
    cursor: pointer;
`;

const StyledLink = styled.a`
    color: ${({ theme }) => theme.primary500};
    font-weight: 700;
`;

const ErrorWrapper = styled.div`
    margin-left: -14px;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        margin-left: 0;
    }
`;

const Badge = styled.p<{ status: string }>`
    ${ShortParagraphCss};
    font-weight: 700;
    background-color: ${({ theme, status }) => (status === "ACTIVE" ? theme.success500 : theme.danger500)};
    color: ${({ theme }) => theme.neutral0};
    padding: 0 8px;
    width: fit-content;
    border-radius: 20px;
    text-transform: lowercase;

    ::first-letter {
        text-transform: uppercase;
    }
`;
