import { useCallback, useRef } from "react";

interface Throttle {
    /** Throttle delay time in milliseconds */
    delay: number;
}

/**
 * @method useThrottle
 * @description Hook to help throttle fast clicks
 */
export const useThrottle = ({ delay }: Throttle) => {
    const throttleRef = useRef<boolean>(false);

    const throttle = useCallback(
        (callback: () => void) => {
            if (throttleRef.current) return;

            throttleRef.current = true;

            setTimeout(() => {
                throttleRef.current = false;
            }, delay);

            callback();
        },
        [delay],
    );

    return {
        throttle,
        isActive: throttleRef.current,
    };
};
