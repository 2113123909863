import React from "react";

import { IPurchaseSchedule } from "types";
import { formatNumberAsCurrencyWithSymbol } from "utils/formatters/currency";

import { SubHeading, TableContainer, TableData, TableHeading } from "./table";

export const CompletionStatementBreakdown: React.FC<IPurchaseSchedule> = ({
    propertyPurchasePrice,
    stampDutyLandTax,
    landRegistrationFee,
    conveyancersFee,
    preCompletionSearchesCost,
    propertySearchesCost,
    titleInsuranceCost,
    telegraphicTransferFee,
    completionStatementSubtotal,
}) => {
    return (
        <>
            <SubHeading>Completion statement breakdown</SubHeading>
            <TableContainer>
                <thead>
                    <tr>
                        <TableHeading percentWidth={60}></TableHeading>
                        <TableHeading alignRight>Cost</TableHeading>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <TableData>Property purchase price</TableData>
                        <TableData alignRight>
                            {typeof propertyPurchasePrice === "number"
                                ? formatNumberAsCurrencyWithSymbol(propertyPurchasePrice)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Stamp duty land tax</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof stampDutyLandTax === "number"
                                ? formatNumberAsCurrencyWithSymbol(stampDutyLandTax)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Land registeration fee</TableData>
                        <TableData alignRight>
                            {typeof landRegistrationFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(landRegistrationFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Solicitor charges (inc VAT)</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof conveyancersFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(conveyancersFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Pre completion searches / transfer fee (inc VAT)</TableData>
                        <TableData alignRight>
                            {typeof preCompletionSearchesCost === "number"
                                ? formatNumberAsCurrencyWithSymbol(preCompletionSearchesCost)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>Property searches (inc VAT)</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof propertySearchesCost === "number"
                                ? formatNumberAsCurrencyWithSymbol(propertySearchesCost)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData>Title insurance</TableData>
                        <TableData alignRight>
                            {typeof titleInsuranceCost === "number"
                                ? formatNumberAsCurrencyWithSymbol(titleInsuranceCost)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isEvenRow>TT fees</TableData>
                        <TableData alignRight isEvenRow>
                            {typeof telegraphicTransferFee === "number"
                                ? formatNumberAsCurrencyWithSymbol(telegraphicTransferFee)
                                : ""}
                        </TableData>
                    </tr>
                    <tr>
                        <TableData isTotalRow>Total</TableData>
                        <TableData alignRight isTotalRow>
                            {typeof completionStatementSubtotal === "number"
                                ? formatNumberAsCurrencyWithSymbol(completionStatementSubtotal)
                                : ""}
                        </TableData>
                    </tr>
                </tbody>
            </TableContainer>
        </>
    );
};
