import React, { useEffect, useState } from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { ShortLeadLabelCss, ShortParagraphLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { DownloadIcon } from "components/icons";
import { IPartnership, IPurchaseSchedule } from "types";
import { TPropertyTenureType } from "utils";
import { TDocumentType, formatPayload } from "utils/document-api/document-api";

import { documentTypeMapper, hasAllDocGenFields } from "./helpers";

interface IDocumentDownloadProps extends IBaseComponent {
    partnership: IPartnership;
    purchaseSchedule: IPurchaseSchedule;
    propertyTenureType: TPropertyTenureType;
}

export const DocumentDownload: React.FC<IDocumentDownloadProps> = ({
    partnership,
    purchaseSchedule,
    propertyTenureType,
}) => {
    const { token } = useAuth();
    const [showDocumentLinks, setShowDocumentLinks] = useState(false);

    const { addNotification, removeNotification, addAutoDismissNotification } = useSystemNotification();

    useEffect(() => {
        if (hasAllDocGenFields(partnership, purchaseSchedule, propertyTenureType)) {
            setShowDocumentLinks(true);
        }
    }, [purchaseSchedule, partnership, propertyTenureType]);

    const handleClick = (documentType: TDocumentType, redline: boolean) => {
        if (!purchaseSchedule) {
            return;
        }

        const version = redline ? "redline-version" : "clean-version";
        const docTypeShorthand = documentTypeMapper(documentType);
        const message = `Downloading ${docTypeShorthand} ${version}`.replace(/\s|_/g, " ");

        addNotification({
            id: message,
            type: "warning",
            message: message,
        });

        const documentData = formatPayload(documentType, redline, partnership, purchaseSchedule, propertyTenureType);
        const displayNameFormatted = partnership.displayName?.replace(/,/g, "");

        const fileName = `${partnership.name}-${displayNameFormatted}-${docTypeShorthand}-${version}`.replace(
            /\s|_|" "/g,
            "-",
        );

        const supportedDocTypeDownloads = [
            "power_of_attorney",
            "partnership_agreement",
            "assured_shorthold_tenancy_agreement",
        ];

        const isSupportedDocumentType = supportedDocTypeDownloads.some((value) => value === documentType);

        // Using the new doc gen frontend template api
        if (isSupportedDocumentType) {
            const generateHtmlUrl = "https://gho-fe-document-generation.netlify.app/api/generate-doc-html";

            const docHtml = fetch(generateHtmlUrl, {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify(documentData),
            }).then((response) => response.json());

            const generatePdfUrl = `${process.env.REACT_APP_API_ENDPOINT}/v1/document-generation`;
            docHtml
                .then((htmldata) => {
                    return fetch(generatePdfUrl, {
                        headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
                        method: "POST",
                        body: JSON.stringify(htmldata),
                    });
                })
                .then((response) => {
                    if (response.status >= 400) {
                        response.json().then(console.error);
                        addAutoDismissNotification({
                            id: "Download error",
                            type: "error",
                            message: "There was a problem downloading the file",
                        });

                        removeNotification(message);
                        return;
                    }

                    response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = `${fileName}.pdf`;
                        a.click();
                        window.open(url);
                        removeNotification(message);
                    });
                })
                .catch((caughtError) => {
                    addAutoDismissNotification({
                        id: "Download error",
                        type: "error",
                        message: "There was a problem downloading the file",
                    });
                    removeNotification(message);
                    return { ok: false, status: 0, body: caughtError };
                });
        } else {
            addAutoDismissNotification({
                id: "Download error",
                type: "error",
                message: "Unsupported document download type, please create a template for this document.",
            });
        }
    };

    return (
        <>
            <Heading>Partnership agreement</Heading>
            {showDocumentLinks ? (
                <>
                    <StyledButton
                        aria-label="Download partnership agreement clean version"
                        onClick={() => handleClick("partnership_agreement", false)}
                    >
                        <DownloadIcon />
                        Clean version
                    </StyledButton>
                    <StyledButton
                        aria-label="Download partnership agreement red line version"
                        onClick={() => handleClick("partnership_agreement", true)}
                    >
                        <DownloadIcon />
                        Red line version
                    </StyledButton>
                </>
            ) : (
                <CompleteFieldsText>
                    You must complete all the financial fields in order to create and download the PA document
                </CompleteFieldsText>
            )}

            <VerticalSpacing size={48} />

            <Heading>Power of attorney</Heading>
            {showDocumentLinks ? (
                <>
                    <StyledButton
                        aria-label="Download power of attorney clean version"
                        onClick={() => handleClick("power_of_attorney", false)}
                    >
                        <DownloadIcon />
                        Clean version
                    </StyledButton>
                    <StyledButton
                        aria-label="Download power of attorney red line version"
                        onClick={() => handleClick("power_of_attorney", true)}
                    >
                        <DownloadIcon />
                        Red line version
                    </StyledButton>
                </>
            ) : (
                <CompleteFieldsText>
                    You must complete all the financial fields in order to create and download the PoA document
                </CompleteFieldsText>
            )}

            <VerticalSpacing size={48} />

            <Heading>Assured shorthold tenancy agreement</Heading>
            {showDocumentLinks ? (
                <>
                    <StyledButton
                        aria-label="Download Assured shorthold tenancy agreement clean version"
                        onClick={() => handleClick("assured_shorthold_tenancy_agreement", false)}
                    >
                        <DownloadIcon />
                        Clean version
                    </StyledButton>
                    <StyledButton
                        aria-label="Download Assured shorthold tenancy agreement red line version"
                        onClick={() => handleClick("assured_shorthold_tenancy_agreement", true)}
                    >
                        <DownloadIcon />
                        Red line version
                    </StyledButton>
                </>
            ) : (
                <CompleteFieldsText>
                    You must complete all the financial fields in order to create and download the ASTA document
                </CompleteFieldsText>
            )}
        </>
    );
};

const Heading = styled.h3`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
    margin-bottom: ${({ theme }) => theme.spacing24};
`;

const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: none;

    ${ShortParagraphLabelCss};
    color: ${({ theme }) => theme.primary500};
    font-weight: 700;
    font-size: ${({ theme }) => theme.type18};
    text-decoration-line: underline;
    line-height: 1.6;
    margin-right: ${({ theme }) => theme.spacing32};

    svg {
        margin-right: ${({ theme }) => theme.spacing8};
    }
`;

const CompleteFieldsText = styled.p`
    ${ShortParagraphLabelCss};
    font-size: ${({ theme }) => theme.type18};
`;
