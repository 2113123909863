import React, { useEffect, useState } from "react";

import { captureException } from "@sentry/browser";
import { useSystemNotification } from "@wayhome-uk/components";
import { ShortLeadLabelCss, VerticalSpacing } from "@wayhome-uk/design-system";
import { IBaseComponent } from "@wayhome-uk/types";
import { useAuth } from "@wayhome-uk/utils";
import styled from "styled-components";

import { FormWrapper } from "components/form/form-wrapper";
import { DirectDebitForm } from "pages/partnership/partnership-tabs/direct-debit/direct-debit-form";
import { IDirectDebit, IPartnership } from "types";
import { getDirectDebitMandate, postDirectDebitMandate } from "utils/direct-debit-mandate-api/direct-debit-mandate-api";

export interface IDirectDebitTabProps extends IBaseComponent {
    partnership: IPartnership;
    updatePartnership: () => void;
}

export const DirectDebitTab: React.FC<IDirectDebitTabProps> = ({ className, partnership, updatePartnership }) => {
    const { token } = useAuth();
    const { addAutoDismissNotification } = useSystemNotification();

    const [directDebitMandateData, setDirectDebitMandateData] = useState<IDirectDebit>();

    useEffect(() => {
        if (partnership.rentDirectDebitMandateId !== null) {
            (async () => {
                try {
                    const directDebitMandateData = await getDirectDebitMandate(partnership.id, token);
                    setDirectDebitMandateData(directDebitMandateData);
                } catch (error) {
                    captureException(error);
                }
            })();
        }
    }, [token, partnership]);

    const handleSubmit = async (directDebitData: IDirectDebit) => {
        try {
            const response = await postDirectDebitMandate(partnership.id, directDebitData, token);
            setDirectDebitMandateData(response);
            addAutoDismissNotification({
                id: "save-direct-debit",
                type: "success",
                message: "Direct debit successfully setup",
            });
            updatePartnership();
        } catch (error) {
            captureException(error);
            addAutoDismissNotification({
                id: "save-direct-debit",
                type: "error",
                message: "Creation failed: API error",
            });
        }
    };

    return (
        <div className={className}>
            <Heading>Direct Debit</Heading>
            <VerticalSpacing size={32} />
            <FormWrapper>
                <DirectDebitForm
                    initialValues={{
                        status: directDebitMandateData?.status || null,
                        sortCode: directDebitMandateData?.sortCode || null,
                        name: directDebitMandateData?.name || null,
                        accountNumber: directDebitMandateData?.accountNumber || null,
                        phoneNumber: directDebitMandateData?.phoneNumber || null,
                        addressLineOne: directDebitMandateData?.addressLineOne || null,
                        addressLineTwo: directDebitMandateData?.addressLineTwo || null,
                        postcode: directDebitMandateData?.postcode || null,
                        town: directDebitMandateData?.town || null,
                        termsAndConditions: !!directDebitMandateData,
                    }}
                    onSubmit={handleSubmit}
                    isRentDirectDebitMandateIDNull={partnership.rentDirectDebitMandateId === null}
                />
            </FormWrapper>
        </div>
    );
};

const Heading = styled.h2`
    ${ShortLeadLabelCss};
    color: ${({ theme }) => theme.neutral800};
    font-weight: 700;
`;
